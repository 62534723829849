import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropertyLabel from './PropertyLabel'
import Box from './Box'


class MatrixConstructorSignal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      matrixModalOpen: false,
      matrixMatricula: '',
      firstIndex: 1,
      lastIndex: 70
    }

    this.openMatrixDialog = this.openMatrixDialog.bind(this)
    this.closeMatrixDialog = this.closeMatrixDialog.bind(this)
  }

  openMatrixDialog(matricula, e) {
    this.setState({ matrixModalOpen: true, matrixMatricula: matricula })
  }

  closeMatrixDialog() {
    this.setState({ matrixModalOpen: false })
  }

  getErrorCode(error) {

    switch (error) {
      case '0x00': return 'OK'
      case '0xFF': return 'INITIALIZED'
      case '0x01': return 'CONNECTED'
      case '0x02': return 'ESP32 PROCESS SUCC'
      case '0x03': return 'USER PIN ASSIGNED'
      case '0x04': return 'CONN FAULT'
      case '0x05': return 'SCAN TIMEOUT'
      case '0x06': return 'GEN ERROR 1'
      case '0x07': return 'ESP32 PROCESS UNSUCC'
      case '0x08': return 'GEN ERROR 2'
      case '0x09': return 'GEN ERROR 3'
      case '0x0A': return 'GEN ERROR 4'
      case '0x0B': return 'GEN ERROR 5'
      case '0x0C': return 'USER PIN UNASSIGNED'
      default: return error
    }
  }

  infoMatrix() {

    let checkMatricula = "";
    let checkBLEName = "";
    let checkHobbit = false;
    let checkBateria = "";
    let checkPin = "";
    let checkPhone = "";
    let checkModule = "";
    let checkPass = "";
    let checkAdmin = "";
    let checkType = "";
    let checkPosition = "";
    let checkStatus = "";
    let checkWide = "";
    let checkMode = "";
    let checkConfigured = false;
    let checkLastNightscan = "";
    let checkRssi = 0;
    let checkGender = '';
    let checkFaultCode = '';
    let checkErrorCode = '';
    let kerongVersion = '';

    {
      this.props.matrixs.map(matrix => {
        {
          (matrix.Serial === this.props.machine.Serial) && matrix.Locker.map((lock, index) => {

            if (index + 1 == this.state.matrixMatricula) {
              checkMatricula = lock.Matricula || checkMatricula;
              checkType = lock.Type || checkType;
              checkBLEName = lock.BLEName || checkBLEName;
              checkBateria = lock.Bateria || checkBateria;
              checkPin = lock.Pin || checkPin;
              checkPhone = lock.Phone || checkPhone;
              checkModule = lock.ModuleSN || checkModule;
              checkPass = lock.Pass || checkPass;
              checkAdmin = lock.PasswordCash || checkAdmin;
              checkPosition = lock.Position || checkPosition;
              checkStatus = lock.Status || checkStatus;
              checkWide = lock.wide || checkWide;
              checkMode = lock.Mode || checkMode;
              checkConfigured = lock.Configured || checkConfigured;
              checkLastNightscan = lock.LastNightScanCompleted || checkLastNightscan;
              checkRssi = lock.Rssi || checkRssi;
              checkGender = lock.Gender || checkGender;
              checkFaultCode = lock.FaultCode || checkFaultCode;
              checkErrorCode = lock.ErrorBle || checkErrorCode;
              kerongVersion = lock.KerongVersion || kerongVersion;
            }
          })
        }
      })
    }

    const year = parseInt(checkLastNightscan.slice(0, 4));
    const month = parseInt(checkLastNightscan.slice(4, 6)) - 1; // month is zero-indexed
    const day = parseInt(checkLastNightscan.slice(6, 8));
    const hour = parseInt(checkLastNightscan.slice(8, 10));
    const minute = parseInt(checkLastNightscan.slice(10, 12));
    const second = parseInt(checkLastNightscan.slice(12, 14));

    const nightscanDate = new Date(year, month, day, hour, minute, second);

    nightscanDate.setHours(nightscanDate.getHours());
    const formattedDate = `${nightscanDate.getDate()}/${nightscanDate.getMonth() + 1}/${nightscanDate.getFullYear()}`;
    const formattedTime = `${nightscanDate.getHours().toString().padStart(2, '0')}:${nightscanDate.getMinutes().toString().padStart(2, '0')}`;

    return (

      <p className="text-break text-monospace">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <Box title="Cliente" icon="cubes">
              <PropertyLabel name="Teléfono"
                value={checkPhone} />
              <PropertyLabel name="Pin Cliente"
                value={checkPass} />
              <PropertyLabel name="Pin Universal"
                value={checkAdmin} />
            </Box>
            <Box title="Taquilla" icon="door-closed">
              <PropertyLabel name="Matrícula"
                value={checkMatricula} />
              <PropertyLabel name="Tipo"
                value={checkType} />
              <PropertyLabel name="BLEName"
                value={checkBLEName} />
              <PropertyLabel name="Potencia"
                value={checkRssi} />
              <PropertyLabel name="Estado"
                value={checkStatus} />
              <PropertyLabel name="Bateria"
                value={checkBateria} />
              <PropertyLabel name="Pin de Pairing"
                value={checkPin} />
              <PropertyLabel name="Módulo"
                value={checkModule} />
              <PropertyLabel name="Género"
                value={checkGender} />
              <PropertyLabel name="Versión Kerong"
                value={kerongVersion} />
            </Box>
            <Box title="Errores" icon="cubes">
              <PropertyLabel name="Avería"
                value={checkFaultCode} />
              <PropertyLabel name="Código Error"
                value={this.getErrorCode(checkErrorCode)} />
            </Box>
            <Box title="Nightscan" icon="cubes">
              <PropertyLabel name="Last Night Scan Date"
                value={formattedDate} />
              <PropertyLabel name="Last Night Scan Hour"
                value={formattedTime} />
            </Box>
          </div>
        </div>
      </p>
    )
  }

  render() {

    const matrixRender = [];
    let lockerStatus = "";
    let matrixIndex = 0;
    let checkKiosc = 0;

    let columnHeight = 0;
    let matrixWidth = 0;

    {
      this.props.matrixs.map(matrix => {
        {
          (matrix.Serial === this.props.machine.Serial) && matrix.Locker.map((lock, index) => {

            if (matrixIndex < this.props.machine.LastIndex) {

              let lockerSelected = lock.Mode + lock.Wide + lock.Type

              if (lock.Rssi >= -60 && lock.Rssi != 0) {
                lockerStatus = "HIGHTOP"
              } else if (lock.Rssi < -60 && lock.Rssi >= -65) {
                lockerStatus = "HIGH"
              } else if (lock.Rssi < -65 && lock.Rssi >= -70) {
                lockerStatus = "HIGHLOW"
              } else if (lock.Rssi < -70 && lock.Rssi >= -75) {
                lockerStatus = "MIDHIGH"
              } else if (lock.Rssi < -75 && lock.Rssi >= -80) {
                lockerStatus = "MID"
              } else if (lock.Rssi < -80 && lock.Rssi >= -85) {
                lockerStatus = "MIDLOW"
              } else if (lock.Rssi < -85 && lock.Rssi >= -90) {
                lockerStatus = "LOWHIGH"
              } else if (lock.Rssi < -90 && lock.Rssi >= -95) {
                lockerStatus = "LOW"
              } else if (lock.Rssi < -95 && lock.Rssi >= -100) {
                lockerStatus = "LOWTOP"
              } else {
                lockerStatus = "Null"
              }

              switch (lock.Type) {

                case "S":
                  columnHeight += 45
                  break;
                case "M":
                  columnHeight += 90
                  break;
                case "L":
                  columnHeight += 135
                  break;
                case "XL":
                  columnHeight += 180
                  break;
                case "2XL":
                  columnHeight += 225
                  break;
                case "3XL":
                  columnHeight += 270
                  break;

              }

              if (columnHeight >= 270) {

                columnHeight = 0;

                if (lock.Wide === 'N') {
                  matrixWidth += 45;
                } else {
                  matrixWidth += 60;
                }
              }

              matrixRender.push(

                <div key={index} id={`locker${lockerSelected}`} onClick={e => this.openMatrixDialog(index + 1, e)}>
                  <p>{index + this.props.machine.FirstIndex}</p>
                  <div id={lockerStatus}><p>{lock.Espressif}</p></div>
                </div>

              );

              matrixIndex++

              if (lock.Position === "C" && checkKiosc === 0) {

                columnHeight += 135
      
                matrixRender.push(
      
                  <div key={matrixIndex} id="lockerKioskLeft">
                  </div>
      
                );
      
                checkKiosc = 1
              }else if (lock.Position === "E" && checkKiosc === 0) {
      
                columnHeight += 90
      
                matrixRender.push(
      
                  <div key={matrixIndex} id="lockerEcomKioskLeft">
                  </div>
      
                );
      
                checkKiosc = 1
              } else if (lock.Position === "C" && checkKiosc === 1) {
      
                columnHeight += 135
      
                matrixRender.push(
      
                  <div key={matrixIndex} id="lockerKioskRight">
                  </div>
      
                );
      
                checkKiosc = 0
              } else if (lock.Position === "E" && checkKiosc === 1) {
      
                columnHeight += 90
      
                matrixRender.push(
      
                  <div key={matrixIndex} id="lockerEcomKioskRight">
                  </div>
      
                );
      
                checkKiosc = 0
              }
            }
          })
        }
      })
    }

    return (
      <div id="matrixContainer" style={{ width: matrixWidth + 40 }}>
        <div class="row" id="lockerMatrix">
          {matrixRender}
        </div>
        <div>
          <Modal isOpen={this.state.matrixModalOpen}>
            <ModalHeader toggle={this.terminateConfirmToggle}>
              Información de taquilla
            </ModalHeader>
            <ModalBody>
              {this.infoMatrix()}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary"
                onClick={(event) => this.closeMatrixDialog()}>
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );

  }
}


export default MatrixConstructorSignal

