import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropertyLabel from '../components/PropertyLabel'
import { connect } from 'react-redux'
import Box from '../components/Box'
import RemoteActionSingleAddForm from '../forms/RemoteActionSingleAddForm'


class MatrixGenerator extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      matrixModalOpen: false,
      matrixMatricula: '',
      lastIndex: 70
    }

    this.openMatrixDialog = this.openMatrixDialog.bind(this)
    this.closeMatrixDialog = this.closeMatrixDialog.bind(this)
  }

  openMatrixDialog(matricula, e) {
    this.setState({ matrixModalOpen: true, matrixMatricula: matricula })
  }

  closeMatrixDialog() {
    this.setState({ matrixModalOpen: false })
  }

  getErrorCode(error) {

    switch (error) {
      case '0x00': return 'OK'
      case '0xFF': return 'INITIALIZED'
      case '0x01': return 'CONNECTED'
      case '0x02': return 'ESP32 PROCESS SUCC'
      case '0x03': return 'USER PIN ASSIGNED'
      case '0x04': return 'CONN FAULT'
      case '0x05': return 'SCAN TIMEOUT'
      case '0x06': return 'GEN ERROR 1'
      case '0x07': return 'ESP32 PROCESS UNSUCC'
      case '0x08': return 'GEN ERROR 2'
      case '0x09': return 'GEN ERROR 3'
      case '0x0A': return 'GEN ERROR 4'
      case '0x0B': return 'GEN ERROR 5'
      case '0x0C': return 'USER PIN UNASSIGNED'
      default: return error
    }
  }

  getColorClass(rssi) {
    if (rssi >= -60 && rssi !== 0) {
      return 'HIGHTOP';
    } else if (rssi < -60 && rssi >= -65) {
      return 'HIGH';
    } else if (rssi < -65 && rssi >= -70) {
      return 'HIGHLOW';
    } else if (rssi < -70 && rssi >= -75) {
      return 'MIDHIGH';
    } else if (rssi < -75 && rssi >= -80) {
      return 'MID';
    } else if (rssi < -80 && rssi >= -85) {
      return 'MIDLOW';
    } else if (rssi < -85 && rssi >= -90) {
      return 'LOWHIGH';
    } else if (rssi < -90 && rssi >= -95) {
      return 'LOW';
    } else if (rssi < -95 && rssi >= -100) {
      return 'LOWTOP';
    } else {
      return 'Null';
    }
  }

  infoMatrix() {
    const { role, type, matrix, machine } = this.props

    let checkMatricula = "";
    let checkBLEName = "";
    let checkHobbit = false;
    let checkBateria = "";
    let checkPin = "";
    let checkPhone = "";
    let checkModule = "";
    let checkPass = "";
    let checkAdmin = "";
    let checkType = "";
    let checkPosition = "";
    let checkStatus = "";
    let checkWide = "";
    let checkMode = "";
    let checkConfigured = false;
    let checkLastNightscan = "";
    let checkRssi = 0;
    let checkGender = '';
    let checkFaultCode = '';
    let checkErrorCode = '';
    let kerongVersion = '';
    let passOpens = 0;
    let blockType = 0;

    const Lockers = matrix.Locker

    checkMatricula = Lockers[this.state.matrixMatricula + 1].Matricula || checkMatricula;
    checkType = Lockers[this.state.matrixMatricula + 1].Type || checkType;
    checkBLEName = Lockers[this.state.matrixMatricula + 1].BLEName || checkBLEName;
    checkBateria = Lockers[this.state.matrixMatricula + 1].Bateria || checkBateria;
    checkPin = Lockers[this.state.matrixMatricula + 1].Pin || checkPin;
    checkPhone = Lockers[this.state.matrixMatricula + 1].Phone || checkPhone;
    checkModule = Lockers[this.state.matrixMatricula + 1].ModuleSN || checkModule;
    checkPass = Lockers[this.state.matrixMatricula + 1].Pass || checkPass;
    checkAdmin = Lockers[this.state.matrixMatricula + 1].PasswordCash || checkAdmin;
    checkPosition = Lockers[this.state.matrixMatricula + 1].Position || checkPosition;
    checkStatus = Lockers[this.state.matrixMatricula + 1].Status || checkStatus;
    checkWide = Lockers[this.state.matrixMatricula + 1].wide || checkWide;
    checkMode = Lockers[this.state.matrixMatricula + 1].Mode || checkMode;
    checkConfigured = Lockers[this.state.matrixMatricula + 1].Configured || checkConfigured;
    checkLastNightscan = Lockers[this.state.matrixMatricula + 1].LastNightScanCompleted || checkLastNightscan;
    checkRssi = Lockers[this.state.matrixMatricula + 1].Rssi || checkRssi;
    checkGender = Lockers[this.state.matrixMatricula + 1].Gender || checkGender;
    checkFaultCode = Lockers[this.state.matrixMatricula + 1].FaultCode || checkFaultCode;
    checkErrorCode = Lockers[this.state.matrixMatricula + 1].ErrorBle || checkErrorCode;
    kerongVersion = Lockers[this.state.matrixMatricula + 1].KerongVersion || kerongVersion;
    passOpens = Lockers[this.state.matrixMatricula + 1].PassOpens || passOpens;
    blockType = Lockers[this.state.matrixMatricula + 1].BlockType || blockType;

    const year = parseInt(checkLastNightscan.slice(0, 4));
    const month = parseInt(checkLastNightscan.slice(4, 6)) - 1; // month is zero-indexed
    const day = parseInt(checkLastNightscan.slice(6, 8));
    const hour = parseInt(checkLastNightscan.slice(8, 10));
    const minute = parseInt(checkLastNightscan.slice(10, 12));
    const second = parseInt(checkLastNightscan.slice(12, 14));

    const nightscanDate = new Date(year, month, day, hour, minute, second);

    nightscanDate.setHours(nightscanDate.getHours());
    const formattedDate = `${nightscanDate.getDate()}/${nightscanDate.getMonth() + 1}/${nightscanDate.getFullYear()}`;
    const formattedTime = `${nightscanDate.getHours().toString().padStart(2, '0')}:${nightscanDate.getMinutes().toString().padStart(2, '0')}`;

    const totalBattery = 6500; // Change this to the maximum battery value
    const minBattery = 4400; // Change this to the maximum battery value

    // Calculate the battery percentage
    const batteryPercentage = ((checkBateria - minBattery) / (totalBattery - minBattery)) * 100;

    // Determine the number of squares based on battery level
    let squaresCount;
    let batteryColor;

    if (batteryPercentage >= 83.3) {
      squaresCount = 5;
      batteryColor = 'green';
    } else if (batteryPercentage >= 66.7) {
      squaresCount = 4;
      batteryColor = 'green';
    } else if (batteryPercentage >= 50) {
      squaresCount = 3;
      batteryColor = 'orange';
    } else if (batteryPercentage >= 33.3) {
      squaresCount = 2;
      batteryColor = 'orange';
    } else if (batteryPercentage >= 16.7) {
      squaresCount = 1;
      batteryColor = 'red';
    } else {
      squaresCount = 0;
      batteryColor = 'red';
    }

    return (

      <p className="text-break text-monospace">
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Cliente" icon="user" bgcolor="info" textcolor="white">
              <PropertyLabel name="Teléfono"
                value={checkPhone} textcolor="white" />
              <PropertyLabel name="Pin Cliente"
                value={checkPass} textcolor="white" />
              <PropertyLabel name="Pin Universal"
                value={checkAdmin} textcolor="white" />
            </Box>
            <Box title="Taquilla" icon="door-closed">
              <PropertyLabel name="Matrícula"
                value={checkMatricula} />
              <PropertyLabel name="BLEName"
                value={checkBLEName} />
              <PropertyLabel name="Pin de Pairing"
                value={checkPin} />
              <PropertyLabel name="Estado"
                value={checkStatus} />
              <PropertyLabel name="Tipo"
                value={checkType} />
              <PropertyLabel name="Módulo"
                value={checkModule} />
              <PropertyLabel name="Género"
                value={checkGender} />
              <PropertyLabel name="Versión Kerong"
                value={kerongVersion} />
              <PropertyLabel name="Whitelist"
                value={blockType} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Potencia (Mejor a Peor)" icon="wifi">
              <div className="rssi-bar">
                {['HIGHTOP', 'HIGH', 'HIGHLOW', 'MIDHIGH', 'MID', 'MIDLOW', 'LOWHIGH', 'LOW', 'LOWTOP'].map((color) => (
                  <div key={color} id={`${color}`} className={`rssi-square ${color === this.getColorClass(checkRssi) ? 'highlighted' : ''}`}>
                    {color === this.getColorClass(checkRssi) && <h5>{checkRssi}</h5>}
                  </div>
                ))}
              </div>
            </Box>
            <Box title="Bateria" icon="battery-full">
              <div className="battery-indicator">
                <div className="battery-info">
                  <div className="battery-percentage">{batteryPercentage.toFixed(1)} %</div>
                </div>
                <div className={`battery-shape`}>
                  {Array.from({ length: squaresCount }, (_, index) => (
                    <div key={index} className={`battery-square ${batteryColor}`}></div>
                  ))}
                </div>
                <div className="battery-info">
                  <div className="battery-number">{(checkBateria / 1000).toFixed(1)} V</div>
                </div>
              </div>
            </Box>
            <Box title="Nightscan" icon="cubes">
              <PropertyLabel name="Last Night Scan Date"
                value={formattedDate} />
              <PropertyLabel name="Last Night Scan Hour"
                value={formattedTime} />
            </Box>
            <Box title="Errores" icon="exclamation-triangle" bgcolor="danger" textcolor="white">
              <PropertyLabel name="Avería"
                value={checkFaultCode} textcolor="white" />
              <PropertyLabel name="Código Error"
                value={this.getErrorCode(checkErrorCode)} textcolor="white" />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">

          </div>
        </div>
      </p>
    )
  }

  render() {
    const { matrix, machine, lastIndex, firstIndex } = this.props;

    const matrixRender = [];
    let lockerStatus = "";
    let matrixIndex = 0;
    let checkKiosc = 0;

    let columnHeight = 0;
    let matrixWidth = 0;

    const Lockers = matrix.Locker

    Lockers.forEach((lock, index) => {

      if (matrixIndex < lastIndex) {

        let lockerSelected = lock.Mode + lock.Wide + lock.Type

        lock.Configured ? lockerStatus = lock.Status : lockerStatus = "Null"

        switch (lock.Type) {

          case "S":
            columnHeight += 45
            break;
          case "M":
            columnHeight += 90
            break;
          case "L":
            columnHeight += 135
            break;
          case "XL":
            columnHeight += 180
            break;
          case "2XL":
            columnHeight += 225
            break;
          case "3XL":
            columnHeight += 270
            break;

        }

        if (columnHeight >= 270) {

          columnHeight = 0;

          if (lock.Wide === 'N') {
            matrixWidth += 45;
          } else {
            matrixWidth += 60;
          }
        }

        matrixRender.push(

          <div key={index} id={`locker${lockerSelected}`} onClick={e => this.openMatrixDialog(index + 1, e)}>
            <p>{index + firstIndex}</p>
            <div id={lockerStatus}><p>{lockerStatus}</p></div>
          </div>
        );

        matrixIndex++

        if (lock.Position === "C" && checkKiosc === 0) {

          columnHeight += 135

          matrixRender.push(

            <div key={matrixIndex} id="lockerKioskLeft">
            </div>

          );

          checkKiosc = 1
        }else if (lock.Position === "E" && checkKiosc === 0) {

          columnHeight += 90

          matrixRender.push(

            <div key={matrixIndex} id="lockerEcomKioskLeft">
            </div>

          );

          checkKiosc = 1
        } else if (lock.Position === "C" && checkKiosc === 1) {

          columnHeight += 135

          matrixRender.push(

            <div key={matrixIndex} id="lockerKioskRight">
            </div>

          );

          checkKiosc = 0
        } else if (lock.Position === "E" && checkKiosc === 1) {

          columnHeight += 90

          matrixRender.push(

            <div key={matrixIndex} id="lockerEcomKioskRight">
            </div>

          );

          checkKiosc = 0
        }
      }
    });

    return (
      <div id="matrixContainer" style={{ width: matrixWidth + 40 }}>
        <div class="row" id="lockerMatrix">
          {matrixRender}
        </div>
        <div>
          <Modal isOpen={this.state.matrixModalOpen} className="locker-modal-admin">
            <ModalHeader toggle={this.terminateConfirmToggle}>
              <div class="text-center">
                <p>Información de taquilla {Number(this.state.matrixMatricula) + firstIndex - 1}</p>
                <Button color="secondary"
                  onClick={(event) => this.closeMatrixDialog()}>
                  Cerrar
                </Button>
              </div>
            </ModalHeader>
            <ModalBody>
              {this.infoMatrix()}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary"
                onClick={(event) => this.closeMatrixDialog()}
                className="button-center">
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );

  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(MatrixGenerator)


