import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'
import { fetchRentals, addRental, fetchRental } from '../actions/rentals'
import { fetchCalendarByOperator } from '../actions/calendars'
import { renderInputField, renderSelectField } from '../utils/forms'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';  // Import Spanish locale from date-fns
import moment from 'moment'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'flag-icons/css/flag-icons.min.css'

registerLocale("es", es); // register it with the name you want

class RemoteActionBaqueiraRentDaysForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,           // Used to show a loading indicator during operations
      actionCompleted: false,   // To indicate if the reservation action was successful
      errorMessage: '',         // To hold any error messages for display
      rentals: [],              // Array to store the fetched rental data
      unavailableDates: [],     // Dates that are not available for selection
      phone: '',
      initPrice: 0,            // Holds the phone number input value
      totalPrice: 0,            // Total price calculated for the selected dates
      priceDetails: [],         // Array to store grouped price details for the selected date ranges
      discountApplied: false,   // Boolean to indicate if a discount was applied
      discountInfo: [],         // Array to store descriptions of the applied discount rules
      cancelled: false,          // To track whether the user has canceled the operation
      language: 'es', // Default language set to Spanish
      phonePrefix: '',
      phoneNumber: '',
      showDropdown: false
    }

    this.submit = this.submit.bind(this)
    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleFinishDateChange = this.handleFinishDateChange.bind(this)
    this.calculateTotalPrice = this.calculateTotalPrice.bind(this)
  }

  componentDidMount() {
    this.fetchRentalData()
    this.props.fetchCalendarByOperator('66bc5611f9ae01de8277bbc6', this.props.lockerType, this.props.machine.Contract.Zone)
      .then(response => {
        console.log('Calendar response:', response);
      })
      .catch(error => {
        console.error('Error fetching calendar:', error);
      });
  }

  async fetchRentalData() {
    try {
      const response = await this.props.fetchRentals(this.props.machine._id, this.props.lockerMatricula, '', '');
      const rentals = response.response;

      // Filter out rentals that have Type 'COUNTERINVOICE' or Status 'CANCELED'
      const validRentals = rentals.filter(
        rental => rental.Type !== 'COUNTERINVOICE' && rental.Status !== 'CANCELED'
      );

      // Create an array of all unavailable dates from the filtered rentals
      let unavailableDates = [];
      validRentals.forEach(rental => {
        const start = moment(rental.StartDate);
        const finish = moment(rental.FinishDate);

        while (start.isSameOrBefore(finish)) {
          unavailableDates.push(start.clone().toDate());
          start.add(1, 'days');
        }
      });

      this.setState({ rentals: validRentals, unavailableDates });
    } catch (error) {
      this.setState({ errorMessage: 'Error fetching rentals' });
    }
  }

  waitForRentalCompletion(actionId) {
    return new Promise((resolve, reject) => {
      const checkStatus = async () => {

        if (this.state.cancelled) {
          reject(new Error('Action cancelled by user'));
          return;
        }

        try {
          const response = await this.props.fetchRental(actionId);
          const action = response.response;

          if (action && (action.Status === 'PENDING' || action.Status === 'ERROR')) {
            resolve(action);
          } else {
            setTimeout(checkStatus, 1000); // Check status again after 1 second
          }
        } catch (error) {
          reject(error);
        }
      };

      checkStatus();
    });
  }

  validate(values) {
    const errors = {};
    const { StartDate, FinishDate } = values;
    const unavailableDates = this.state.unavailableDates || []; // Access this.state

    if (!StartDate) {
      errors.StartDate = 'La fecha de inicio es obligatoria';
    }

    if (!FinishDate) {
      errors.FinishDate = 'La fecha de fin es obligatoria';
    }

    if (StartDate && FinishDate) {
      const start = moment(StartDate);
      const finish = moment(FinishDate);

      // Ensure start date is before or same as finish date
      if (start.isAfter(finish)) {
        errors.FinishDate = 'La fecha de fin debe ser posterior a la fecha de inicio';
      }

      // Validate that no date in the range conflicts with unavailableDates
      const dateToCheck = start.clone();
      while (dateToCheck.isSameOrBefore(finish)) {
        if (unavailableDates.some(unavailableDate => moment(unavailableDate).isSame(dateToCheck, 'day'))) {
          errors.FinishDate = 'El rango de fechas incluye una fecha no disponible';
          break;
        }
        dateToCheck.add(1, 'days');
      }
    }

    return errors;
  }

  submit = async (props) => {
    const errors = this.validate(props);

    // Check if totalPrice is 0 before allowing submission
    if (this.state.totalPrice === 0) {
      this.setState({ errorMessage: 'Las fechas seleccionadas no tienen entrada de precio en el calendario para esta zona y tipo de taquilla.' });
      throw new SubmissionError({ _error: 'Las fechas seleccionadas no tienen entrada de precio en el calendario para esta zona y tipo de taquilla.' });
    }

    if (Object.keys(errors).length > 0) {
      throw new SubmissionError(errors);
    }

    this.setState({ loading: true, actionCompleted: false, errorMessage: '' })

    // Format StartDate and FinishDate as "YYYY-MM-DD"
    props.StartDate = moment(props.StartDate).format("YYYY-MM-DD");
    props.FinishDate = moment(props.FinishDate).format("YYYY-MM-DD");

    //GENERAL

    props.Machine = this.props.machine._id
    props.LockerMatricula = this.props.lockerMatricula
    props.LockerSN = this.props.lockerSN
    props.Type = 'RENTAL'
    props.Operator = '66bc5611f9ae01de8277bbc6'

    //PRICE

    props.InitAmount = this.state.initPrice
    props.BaseAmount = this.state.totalPrice / 1.21
    props.TaxAmount = this.state.totalPrice - (this.state.totalPrice / 1.21)
    props.TotalAmount = this.state.totalPrice
    props.Tax = 21

    //DISCOUNTS

    if (this.state.discountInfo && this.state.discountInfo.length > 0) {
      // Map discount information to the required format
      props.Discounts = this.state.discountInfo.map(discount => ({
        Name: discount.Name,
        DiscountPercentage: discount.DiscountPercentage,
        RuleId: discount.RuleId
      }));
    } else {
      // If no discounts, either omit or set an empty array
      props.Discounts = [];
    }

    //DETAILS

    if (this.state.priceDetails && this.state.priceDetails.length > 0) {
      // Map discount information to the required format
      props.Details = this.state.priceDetails.map(details => ({
        StartDate: moment(details.startDate).format("YYYY-MM-DD"),
        EndDate: moment(details.endDate).format("YYYY-MM-DD"),
        Price: details.price
      }));
    } else {
      // If no discounts, either omit or set an empty array
      props.Details = [];
    }

    try {
      const response = await this.props.addRental(props)

      if (response.error) {
        throw new SubmissionError({
          _error: response.error.message,
          ...response.error.fields
        })
      }

      await this.waitForRentalCompletion(response.response._id)

      this.setState({ loading: false, actionCompleted: true })
      return response
    } catch (error) {
      this.setState({ loading: false, errorMessage: error.message || 'Error processing action' })
      throw error
    }
  }

  handlePhoneChange = (value) => {
    // Directly set the combined value in the form field
    this.setState({ phone: value });
    this.props.dispatch(change('RemoteActionBaqueiraRentDaysForm', 'Phone', value));
  };

  handleLanguageChange = (lang) => {

    switch (lang) {
      case 'en': lang = 'gb'; break;
      case 'es': lang = 'es'; break;
      case 'ct': lang = 'es-ct'; break;
    }

    this.setState({ language: lang, showDropdown: false })
    this.props.dispatch(change('RemoteActionBaqueiraRentDaysForm', 'Language', lang))
  }

  toggleDropdown = () => {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }))
  }

  handleStartDateChange(date) {
    console.log("Start date changed:", date);
    this.props.dispatch(change('RemoteActionBaqueiraRentDaysForm', 'StartDate', date));

    // Wait for redux-form to update the value and then trigger the calculation
    setTimeout(() => {
      this.calculateTotalPrice(date, this.props.formValues?.FinishDate);
    }, 0); // Allow redux-form to update before calculating the price
  }

  handleFinishDateChange(date) {
    console.log("Finish date changed:", date);
    this.props.dispatch(change('RemoteActionBaqueiraRentDaysForm', 'FinishDate', date));

    // Wait for redux-form to update the value and then trigger the calculation
    setTimeout(() => {
      this.calculateTotalPrice(this.props.formValues?.StartDate, date);
    }, 0); // Allow redux-form to update before calculating the price
  }

  getDayClass(date) {
    const { unavailableDates } = this.state
    return unavailableDates.some(unavailableDate => moment(date).isSame(unavailableDate, 'day')) ? 'bg-danger' : ''
  }

  handleCancel() {
    this.setState({ loading: false, cancelled: true });
  }

  calculateTotalPrice(startDate, finishDate) {
    const { calendar } = this.props;
    const { BusinessRules } = calendar;

    if (!startDate || !finishDate || !calendar) return;

    // Convert selected start and finish dates to moment objects
    const start = moment(startDate);
    const end = moment(finishDate);
    const totalDays = end.diff(start, 'days') + 1; // Calculate total days selected

    let totalPrice = 0;
    let initPrice = 0;
    let priceDetails = []; // To store grouped ranges of dates with their respective prices

    let currentGroup = null;

    // Iterate through PriceCalendar to find matching prices for each day in the selected range
    for (let current = start.clone(); current.isSameOrBefore(end); current.add(1, 'days')) {
      const matchingEntry = calendar.PriceCalendar.find(entry => moment(entry.date).isSame(current, 'day'));

      if (matchingEntry && matchingEntry.prices.length > 0) {
        // Assume there's only one price for that day (based on your scenario)
        const price = matchingEntry.prices[0].price;

        // If no current group or price is different from the current group price, create a new group
        if (!currentGroup || currentGroup.price !== price) {
          if (currentGroup) {
            // Push the previous group before starting a new one
            priceDetails.push(currentGroup);
          }

          // Start a new group
          currentGroup = {
            startDate: current.clone(),
            endDate: current.clone(),
            price,
          };
        } else {
          // Extend the current group's end date
          currentGroup.endDate = current.clone();
        }

        totalPrice += price;
        initPrice += price;
      }
    }

    // Push the last group if it exists
    if (currentGroup) {
      priceDetails.push(currentGroup);
    }

    // Check for business rules and apply discounts
    let discountApplied = false;
    let discountInfo = []; // Store information about the applied discount
    let alwaysDiscount = 0;
    let maxDayDiscount = 0;

    if (BusinessRules && BusinessRules.length > 0) {
      BusinessRules.forEach(rule => {
        if (rule !== null && rule.Active) {
          switch (rule.Type) {
            case 'AlwaysDiscount':
              // Apply the discount always
              alwaysDiscount = rule.DiscountPercentage;
              discountApplied = true;
              discountInfo.push({
                Name: rule.Name,
                DiscountPercentage: rule.DiscountPercentage,
                RuleId: rule._id
              });
              break;

            case 'MaxDayDiscount':
              // Check if total days selected is greater or equal to MaxDays
              if (totalDays >= rule.MaxDays) {
                maxDayDiscount = rule.DiscountPercentage;
                discountApplied = true;
                discountInfo.push({
                  Name: rule.Name,
                  DiscountPercentage: rule.DiscountPercentage,
                  RuleId: rule._id
                });
              }
              break;

            default:
              break;
          }
        }
      });

      totalPrice -= totalPrice * ((alwaysDiscount + maxDayDiscount) / 100);

    }

    // Update state with the calculated total price, grouped price details, and discount information
    this.setState({
      totalPrice,
      initPrice,
      priceDetails,
      discountApplied,
      discountInfo,
    });
  }

  render() {
    const { error, pristine, submitting, handleSubmit, machine, calendar } = this.props
    const { loading, actionCompleted, errorMessage, unavailableDates, totalPrice, priceDetails, language, showDropdown } = this.state

    if (loading) {
      return (
        <div className="justify-content-center">
          <span>Ejecutando reserva...</span>
          <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
        </div>
      )
    }

    if (actionCompleted) {
      return (
        <div className="text-center">
          <div className="alert alert-success d-block">Reserva registrada correctamente</div>
          <div className="alert alert-info d-block">Se a enviado un email al cliente con los datos de su reserva.</div>
          <div className="alert alert-info d-block">El día de inicio de su reserva le llegará otro email con la clave de la taquilla.</div>
        </div>
      )
    }

    if (!calendar) {
      return (
        <div className="justify-content-center">
          <span>Cargando calendario...</span>
          <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
        </div>
      )
    }

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-md-12 mt-2">
            <form onSubmit={handleSubmit(this.submit)} className="mb-3 mt-3">
              <div className="row">
                <div className="col col-xs-12 col-md-6 align-items-center justify-content-around">
                  <div className="row">
                    <div className="col col-xs-12 col-md-6 align-items-center justify-content-around">
                      <label className="d-block" htmlFor="StartDate">Fecha de inicio de reserva</label>
                      <Field
                        name="StartDate"
                        component={({ input, meta: { touched, error } }) => (
                          <div>
                            <DatePicker
                              {...input}
                              selected={input.value ? moment(input.value).toDate() : null}
                              onChange={this.handleStartDateChange}
                              minDate={new Date()}
                              excludeDates={unavailableDates}
                              placeholderText="Selecciona..."
                              className="form-control custom-datepicker"
                              inline
                              dayClassName={date => this.getDayClass(date)}
                              locale="es"
                            />
                            {touched && error && <span className="text-danger d-block mt-2">{error}</span>}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col col-xs-12 col-md-6 align-items-center justify-content-around">
                      <label className="d-block" htmlFor="FinishDate">Fecha de fin de reserva</label>
                      <Field
                        name="FinishDate"
                        component={({ input, meta: { touched, error } }) => (
                          <div>
                            <DatePicker
                              {...input}
                              selected={input.value ? moment(input.value).toDate() : null}
                              onChange={this.handleFinishDateChange}
                              minDate={this.props.formValues && this.props.formValues.StartDate ? moment(this.props.formValues.StartDate).toDate() : new Date()}
                              excludeDates={unavailableDates}
                              placeholderText="Selecciona..."
                              className="form-control custom-datepicker"
                              inline
                              dayClassName={date => this.getDayClass(date)}
                              locale="es"
                            />
                            {touched && error && <span className="text-danger d-block mt-2">{error}</span>}
                          </div>
                        )}
                      />

                    </div>
                  </div>
                  <p className="mt-5">*Los días marcados en rojo indican que esta taquilla ya está reservada.</p>
                  <p>**No se pueden seleccionar rangos que contengan días ya reservados.</p>
                </div>
                <div className="col col-xs-12 col-md-2 align-items-center justify-content-center">
                  <div className="form-group">
                    <label className="d-block">Desglose del precio</label>
                    {this.state.priceDetails.map((detail, index) => (
                      <div key={index} className="d-flex align-items-center justify-content-between py-2" style={{ borderBottom: '1px solid #ddd' }}>
                        <span>
                          {detail.startDate.isSame(detail.endDate, 'day')
                            ? detail.startDate.format("DD/MM/YYYY")
                            : `${detail.startDate.format("DD/MM/YYYY")} - ${detail.endDate.format("DD/MM/YYYY")}`}
                        </span>
                        <span>{(detail.price).toFixed(2)}€</span>
                      </div>
                    ))}
                    {/* Display discount information if a discount was applied */}
                    {this.state.discountApplied && (
                      <div>
                        <div className="d-flex justify-content-between mt-3">
                          <strong>Importe:</strong>
                          <strong>{`${(this.state.initPrice).toFixed(2)}€`}</strong>
                        </div>
                        {this.state.discountInfo.map((info, index) => (
                          <div key={index} className="d-flex justify-content-between mt-3">
                            <span>Descuento {index + 1}:</span>
                            <span>{`${info.DiscountPercentage}%`}</span> {/* Display only DiscountPercentage */}
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="d-flex justify-content-between mt-3">
                      <span>Base:</span>
                      <span>{`${(this.state.totalPrice / 1.21).toFixed(2)}€`}</span> {/* Base Price */}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <span>IVA (21%):</span>
                      <span>{`${(this.state.totalPrice - this.state.totalPrice / 1.21).toFixed(2)}€`}</span> {/* Tax */}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <strong>TOTAL:</strong>
                      <strong>{`${(this.state.totalPrice).toFixed(2)}€`}</strong>
                    </div>
                  </div>
                </div>
                <div className="col col-xs-12 col-md-1 align-items-center justify-content-center">

                </div>
                <div className="col col-xs-12 col-md-3 align-items-center justify-content-center">
                  <Field name="PaymentMethod" component={renderSelectField}
                    label="Método de pago">
                    <option value="">Selecciona un método de pago...</option>
                    <option value="CASH">Efectivo</option>
                    <option value="TPV">Tarjeta</option>
                    <option value="CREDIT">Crédito</option>
                  </Field>
                  <Field name="Name" label="Nombre completo del cliente" type="text" component={renderInputField} placeholder="Nombre" />
                  <div className="form-group">
                    <label htmlFor="phonePrefix">Teléfono del cliente</label>
                    <PhoneInput
                      country="es" // default country code
                      value={this.state.phonePrefix + this.state.phoneNumber}
                      onChange={this.handlePhoneChange}
                      inputClass="form-control w-100" // style to match form
                      containerClass="phone-input"
                    />
                  </div>
                  {/* <Field name="Phone"
                    type="text"
                    component={renderInputField}
                    label="Teléfono del cliente" /> */}
                  <Field name="Email" label="Email del cliente" type="text" component={renderInputField} placeholder="Email" />
                  <div className="form-group">
                    <label>Idioma Coms Cliente (Email)</label>
                    <div
                      className="custom-select-wrapper"
                      style={{ position: 'relative', width: '100%' }}
                      ref={(el) => (this.dropdown = el)}
                    >
                      <button
                        type="button"
                        className="custom-select"
                        onClick={this.toggleDropdown}
                        style={{
                          width: '100%',
                          textAlign: 'left',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: '8px 12px',
                          border: '1px solid #ced4da',
                          borderRadius: '4px',
                          backgroundColor: 'white'
                        }}
                      >
                        <span>
                          <span className={`fi fi-${language}`} style={{ marginRight: '8px' }}></span>
                          {language === 'es' ? 'Español' : language === 'gb' ? 'English' : 'Català'}
                        </span>
                        {/*<span style={{ fontSize: '1em' }}>▼</span>*/}
                      </button>

                      {showDropdown && (
                        <ul
                          className=""
                          style={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            width: '100%',
                            border: '1px solid #ced4da',
                            borderRadius: '4px',
                            backgroundColor: 'white',
                            zIndex: 1000,
                            listStyle: 'none',
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <li
                            onClick={() => this.handleLanguageChange('es')}
                            className="dropdown-item"
                            style={{
                              padding: '8px 12px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <span className="fi fi-es" style={{ marginRight: '8px' }}></span> Español
                          </li>
                          <li
                            onClick={() => this.handleLanguageChange('en')}
                            className="dropdown-item"
                            style={{
                              padding: '8px 12px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <span className="fi fi-gb" style={{ marginRight: '8px' }}></span> English
                          </li>
                          <li
                            onClick={() => this.handleLanguageChange('ct')}
                            className="dropdown-item"
                            style={{
                              padding: '8px 12px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <span className="fi fi-es-ct" style={{ marginRight: '8px' }}></span> Català
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row align-items-center flex justify-content-center mt-5">
                <div className="col-md-12 align-items-center flex justify-content-center">
                  <button className="btn bg-vw-light text-white" type="submit" disabled={pristine || submitting}>
                    <i className="fas fa-bolt mr-1"></i>Confirmar reserva
                  </button>
                </div>
              </div>
              <div className="form-row mt-5 justify-content-center">
                {error && <div className="alert alert-danger">{error}</div>}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

RemoteActionBaqueiraRentDaysForm = reduxForm({
  form: 'RemoteActionBaqueiraRentDaysForm',
})(RemoteActionBaqueiraRentDaysForm)

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
  rentals: state.rentals.rentals,
  formValues: state.form.RemoteActionBaqueiraRentDaysForm && state.form.RemoteActionBaqueiraRentDaysForm.values,
  calendar: state.calendars.calendar,
})

const mapDispatchToProps = dispatch => ({
  addRental: bindActionCreators(addRental, dispatch),
  fetchRentals: bindActionCreators(fetchRentals, dispatch),
  fetchRental: bindActionCreators(fetchRental, dispatch),
  fetchCalendarByOperator: bindActionCreators(fetchCalendarByOperator, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoteActionBaqueiraRentDaysForm)
