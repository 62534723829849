import { CALL_API } from '../middleware/api'

export const ARQUEOSBAQUEIRA_REQUEST = 'ARQUEOSBAQUEIRA_REQUEST'
export const ARQUEOSBAQUEIRA_SUCCESS = 'ARQUEOSBAQUEIRA_SUCCESS'
export const ARQUEOSBAQUEIRA_FAILURE = 'ARQUEOSBAQUEIRA_FAILURE'

export function fetchArqueosBaqueira(StartDate, EndDate) {
  let params = ''

  if (StartDate !== undefined) {
    params += `&StartDate=${StartDate}`
  }

  if (EndDate !== undefined) {
    params += `&EndDate=${EndDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `arqueosbaqueira?${params}`,
      authenticated: true,
      types: [ARQUEOSBAQUEIRA_REQUEST, ARQUEOSBAQUEIRA_SUCCESS, ARQUEOSBAQUEIRA_FAILURE]
    }
  }
}

export const ARQUEOBAQUEIRA_REQUEST = 'ARQUEOBAQUEIRA_REQUEST'
export const ARQUEOBAQUEIRA_SUCCESS = 'ARQUEOBAQUEIRA_SUCCESS'
export const ARQUEOBAQUEIRA_FAILURE = 'ARQUEOBAQUEIRA_FAILURE'

export function fetchArqueoBaqueira(id) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `arqueosbaqueira/${id}`,
      authenticated: true,
      types: [ARQUEOBAQUEIRA_REQUEST, ARQUEOBAQUEIRA_SUCCESS, ARQUEOBAQUEIRA_FAILURE]
    }
  }
}

export const ARQUEOBAQUEIRA_SEND_REQUEST = 'ARQUEOBAQUEIRA_SEND_REQUEST'
export const ARQUEOBAQUEIRA_SEND_SUCCESS = 'ARQUEOBAQUEIRA_SEND_SUCCESS'
export const ARQUEOBAQUEIRA_SEND_FAILURE = 'ARQUEOBAQUEIRA_SEND_FAILURE'

export function sendArqueoBaqueira(id) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `arqueosbaqueira/send/${id}`,
      authenticated: true,
      types: [ARQUEOBAQUEIRA_SEND_REQUEST, ARQUEOBAQUEIRA_SEND_SUCCESS, ARQUEOBAQUEIRA_SEND_FAILURE]
    }
  }
}