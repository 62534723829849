import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';
import moment from 'moment';
import { fetchSales } from '../actions/sales';
import { fetchCustomers } from '../actions/customers';
import { fetchMachines } from '../actions/machines';
import { renderInputField, renderSelectField } from '../utils/forms';

const validateDateRange = (values) => {
  const { StartDate, EndDate } = values;

  if (StartDate && EndDate) {
    const startDate = moment(StartDate);
    const endDate = moment(EndDate);

    if (endDate.diff(startDate, 'months') > 0) {
      return {
        EndDate: 'El rango máximo de consulta es de un mes.',
      };
    }
  }

  return {};
};

class OcupationFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loaded: false };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { role, fetchMachines, fetchCustomers } = this.props;

    fetchMachines();

    fetchCustomers();

    this.load(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps);
  }

  load(props) {
    const { role, machines, customers, sales } = props;

    if (!sales.loading && sales.salesNeedRefresh) {
      this.submit(this.props.formValues);
    }

    if (!this.state.loaded && machines && customers) {
      this.setState({ loaded: true });
    }
  }

  submit(props) {

    const { selectedCustomer, fetchSales, onSubmit } = this.props;

    return fetchSales(
      props.Machine,
      props.Customer,
      props.StartDate,
      props.EndDate,
      props.Country,
      props.Category,
      props.PaymentMethod
    ).then((response) => {
      if (response.error) {
        throw new SubmissionError({
          _error: response.error.message,
          ...response.error.fields,
        });
      }
      onSubmit(selectedCustomer); // Call the callback function

      return response;
    });
  }

  render() {
    const { loaded } = this.state;
    const {
      error,
      submitting,
      handleSubmit,
      machines,
      customers,
      role,
    } = this.props;

    if (!loaded) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        {submitting ? (<div className="row"></div>) : (
          <div className="row">
            <div className="col">
              <Field
                name="Customer"
                component={renderSelectField}
                label="Cliente"
              >
                <option value="">Todos</option>
                {customers &&
                  customers
                    .filter((customer) => customer.Type === 'GENERAL')
                    .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
                    .map((customer) => (
                      <option value={customer._id} key={customer._id}>
                        {customer.Fullname}
                      </option>
                    ))}
              </Field>
            </div>
            <div className="col">
              <Field
                name="Machine"
                component={renderSelectField}
                label="Máquina"
              >
                <option value="">Todas</option>
                {machines.map((machine) => (
                  <option value={machine._id} key={machine._id}>
                    {machine.Serial} - {machine.Customer?.Fullname || 'None'}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col">
              <Field
                name="PaymentMethod"
                component={renderSelectField}
                label="Tipo de pago"
              >
                <option value="">Todos</option>
                <option value="CASH">CASH</option>
                <option value="TPV">TPV</option>
                <option value="QR">QR</option>
                <option value="TOKEN">MANUAL</option>
                <option value="RFID">RFID</option>
                <option value="PDA">PDA</option>
                <option value="FREE">GRATIS</option>
              </Field>
            </div>
            {role === 'ADMIN' && (
              <div className="col">
                <Field
                  name="Country"
                  component={renderSelectField}
                  label="País"
                >
                  <option value="">Selecciona un país...</option>
                  <option value="España">España</option>
                  <option value="Francia">Francia</option>
                  <option value="Italia">Italia</option>
                  <option value="Chequia">Chequia</option>
                </Field>
              </div>
            )}
            {role === 'ADMIN' && (
              <div className="col">
                <Field
                  name="Category"
                  component={renderSelectField}
                  label="Categoria"
                >
                  <option value="">Todos</option>
                  <option value="BIGPLAYER">Big Player</option>
                  <option value="AWATERPARK">A Waterpark</option>
                  <option value="BWATERPARK">B WAterpark</option>
                  <option value="CWATERPARK">C Waterpark</option>
                  <option value="BAQUEIRA">BAQUEIRA</option>
                </Field>
              </div>
            )}
            <div className="col">
              <Field
                name="StartDate"
                component={renderInputField}
                type="date"
                label="Fecha inicio"
              />
            </div>
            <div className="col">
              <Field
                name="EndDate"
                component={renderInputField}
                type="date"
                label="Fecha fin"
              />
              {error && <div className="text-danger">{error.EndDate}</div>}
            </div>
          </div>
        )}
        <div className="form-row">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        {submitting ? (<div class="loading-wheel"></div>) : (
          <div className="form-row">
            <button
              className="btn bg-vw-dark text-white mr-1 button-center"
              type="submit"
              disabled={submitting}
            >
              <i className="fas fa-sync mr-1"></i>Actualizar
            </button>
          </div>
        )}
      </form>
    );
  }
}

OcupationFilterForm = reduxForm({
  form: 'OcupationFilterForm',
  validate: validateDateRange,
})(OcupationFilterForm);

const selector = formValueSelector('OcupationFilterForm');

const currentDate = new Date();
const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2);
const formattedStartDate = firstDayOfMonth.toISOString().substring(0, 10);

const startDate = new Date();
startDate.setDate(currentDate.getDate() - 6); // Subtract 6 days to get the start date
const startDateFormatted = startDate.toISOString().substring(0, 10);

const mapStateToProps = (state) => ({
  machines: state.machines.machines,
  customers: state.customers.customers,
  sales: state.sales,
  role: state.auth.role,
  initialValues: {
    Machine: '',
    PaymentMethod: '',
    Customer: '',
    Country: '',
    Category: '',
    StartDate: startDateFormatted,
    EndDate: new Date().toISOString().substring(0, 10)
  },
  formValues: {
    Machine: selector(state, 'Machine'),
    PaymentMethod: selector(state, 'PaymentMethod'),
    Customer: selector(state, 'Customer'),
    Country: selector(state, 'Country'),
    Category: selector(state, 'Category'),
    StartDate: selector(state, 'StartDate'),
    EndDate: selector(state, 'EndDate'),
  },
  selectedCustomer: selector(state, 'Customer'), // Add this line
});

const mapDispatchToProps = (dispatch) => ({
  fetchSales: bindActionCreators(fetchSales, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OcupationFilterForm);