import { CALL_API } from '../middleware/api'

export const FAULTS_REQUEST = 'FAULTS_REQUEST'
export const FAULTS_SUCCESS = 'FAULTS_SUCCESS'
export const FAULTS_FAILURE = 'FAULTS_FAILURE'

export function fetchFaults(Machine, Customer, StartDate, EndDate) {
  let params = ''

  if (Machine !== undefined) {
    params += `&Machine=${Machine}`
  }

  if (Customer !== undefined) {
    params += `&Customer=${Customer}`
  }

  if (StartDate !== undefined) {
    params += `&StartDate=${StartDate}`
  }

  if (EndDate !== undefined) {
    params += `&EndDate=${EndDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `faults?${params}`,
      authenticated: true,
      types: [FAULTS_REQUEST, FAULTS_SUCCESS, FAULTS_FAILURE]
    }
  }
}

export const FAULT_ADD_REQUEST = 'FAULT_ADD_REQUEST'
export const FAULT_ADD_SUCCESS = 'FAULT_ADD_SUCCESS'
export const FAULT_ADD_FAILURE = 'FAULT_ADD_FAILURE'

export function addFault(fault) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'faults',
      authenticated: true,
      body: JSON.stringify(fault),
      types: [FAULT_ADD_REQUEST, FAULT_ADD_SUCCESS, FAULT_ADD_FAILURE]
    }
  }
}

export const FAULT_REPAIR_REQUEST = 'FAULT_REPAIR_REQUEST'
export const FAULT_REPAIR_SUCCESS = 'FAULT_REPAIR_SUCCESS'
export const FAULT_REPAIR_FAILURE = 'FAULT_REPAIR_FAILURE'

export function repairFault(fault) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: 'faults',
      authenticated: true,
      body: JSON.stringify(fault),
      types: [FAULT_REPAIR_REQUEST, FAULT_REPAIR_SUCCESS, FAULT_REPAIR_FAILURE]
    }
  }
}
