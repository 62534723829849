import {
  ARQUEOSBAQUEIRA_REQUEST, ARQUEOSBAQUEIRA_SUCCESS, ARQUEOSBAQUEIRA_FAILURE,
  ARQUEOBAQUEIRA_REQUEST, ARQUEOBAQUEIRA_SUCCESS, ARQUEOBAQUEIRA_FAILURE,
  ARQUEOBAQUEIRA_SEND_REQUEST, ARQUEOBAQUEIRA_SEND_SUCCESS, ARQUEOBAQUEIRA_SEND_FAILURE
} from '../actions/arqueosbaqueira'

const INITIAL_STATE = {
  arqueobaqueira: null, arqueosbaqueira: null, error: null, loading: false
}

const arqueosbaqueiraReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ARQUEOSBAQUEIRA_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case ARQUEOSBAQUEIRA_SUCCESS:
      return {
        ...state,
        arqueosbaqueira: action.response, error: null, loading: false
      }
    case ARQUEOSBAQUEIRA_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case ARQUEOBAQUEIRA_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case ARQUEOBAQUEIRA_SUCCESS:
      return {
        ...state,
        arqueobaqueira: action.response, error: null, loading: false
      }
    case ARQUEOBAQUEIRA_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case ARQUEOBAQUEIRA_SEND_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case ARQUEOBAQUEIRA_SEND_SUCCESS:
      return {
        ...state,
        arqueobaqueira: action.response, error: null, loading: false
      }
    case ARQUEOBAQUEIRA_SEND_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default arqueosbaqueiraReducer

