import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'
import { renderInputField, renderSelectField } from '../utils/forms'

import { addFault } from '../actions/faults'

class RemoteActionFaultAddForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      actionCompleted: false,
      errorMessage: '',
      faultType: ''
    }

    this.submit = this.submit.bind(this)
    this.handleFaultTypeSelect = this.handleFaultTypeSelect.bind(this)
  }

  componentDidMount() {

  }

  handleFaultTypeSelect(faultType) {
    this.setState({ faultType })  // Set the selected BlockType in the state
  }

  submit = async (props) => {
    const { reset } = this.props;
    const { faultType } = this.state;

    if (faultType === '') {
      this.setState({ errorMessage: 'Por favor, selecciona un tipo de avería.' });
      throw new SubmissionError({ _error: 'Por favor, selecciona un tipo de avería.' });
    }

    this.setState({ loading: true, actionCompleted: false })

    //GENERAL

    props.Machine = this.props.machine._id
    props.Serial = this.props.machine.Serial
    props.Locker = this.props.lockerSN
    props.FaultCode = faultType;
    props.Status = 'PENDING'
    props.LockerType = this.props.lockerType

    try {
      const response = await this.props.addFault(props)

      if (response.error) {
        throw new SubmissionError({
          _error: response.error.message,
          ...response.error.fields
        })
      }

      this.setState({ loading: false, actionCompleted: true })

      // Trigger matrix data refresh
      if (this.props.refreshMatrixData) {
        this.props.refreshMatrixData();
      }
      reset();
      return response
    } catch (error) {
      this.setState({ loading: false, errorMessage: error.message || 'Error processing action' })
      throw error
    }
  }

  render() {
    const { error, pristine, submitting, handleSubmit } = this.props
    const { loading, actionCompleted, faultType } = this.state

    if (loading) {
      return (
        <div className="justify-content-center">
          <span>Ejecutando avería...</span>
          <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
        </div>
      )
    }

    if (actionCompleted) {
      return (
        <div className="text-center">
          <div className="alert alert-success d-block">Avería registrada correctamente</div>
        </div>
      )
    }


    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-md-12 mt-2">
            <form onSubmit={handleSubmit(this.submit)} className="mb-3 mt-3">
              <div className="row justify-content-around">
                <div className="col-xs-12 col-md-2 row align-items-center justify-content-center mt-2 mb-2">
                  <button
                    type="button"
                    className={`btn btn-fault ${this.state.faultType === 'BROKEN_LOCK' ? 'btn-fault-selected' : ''}`}
                    onClick={() => this.handleFaultTypeSelect('BROKEN_LOCK')}
                  >
                    <img src="/images/faults/lock_anchorage.png" alt="BROKEN_LOCK" />
                    <p>PINCHO ROTO</p>
                  </button>
                </div>
                <div className="col-xs-12 col-md-2 row align-items-center justify-content-center mt-2 mb-2">
                  <button
                    type="button"
                    className={`btn btn-fault ${this.state.faultType === 'PINPAD_BLOCK' ? 'btn-fault-selected' : ''}`}
                    onClick={() => this.handleFaultTypeSelect('PINPAD_BLOCK')}
                  >
                    <img src="/images/faults/lockpad_battery.png" alt="PINPAD_BLOCK" />
                    <p>TECLADO APAGADO</p>
                  </button>
                </div>
                <div className="col-xs-12 col-md-2 row align-items-center justify-content-center mt-2 mb-2">
                  <button
                    type="button"
                    className={`btn btn-fault ${this.state.faultType === 'DOOR_BLOCK' ? 'btn-fault-selected' : ''}`}
                    onClick={() => this.handleFaultTypeSelect('DOOR_BLOCK')}
                  >
                    <img src="/images/faults/lock_door.png" alt="DOOR_BLOCK" />
                    <p>PUERTA NO CIERRA</p>
                  </button>
                </div>
                <div className="col-xs-12 col-md-2 row align-items-center justify-content-center mt-2 mb-2">
                  <button
                    type="button"
                    className={`btn btn-fault ${this.state.faultType === 'PINPAD_ACTIVE' ? 'btn-fault-selected' : ''}`}
                    onClick={() => this.handleFaultTypeSelect('PINPAD_ACTIVE')}
                  >
                    <img src="/images/faults/lockpad_broken.png" alt="PINPAD_ACTIVE" />
                    <p>TECLADO ENCENDIDO</p>
                  </button>
                </div>
                <div className="col-xs-12 col-md-2 row align-items-center justify-content-center mt-2 mb-2">
                  <button
                    type="button"
                    className={`btn btn-fault ${this.state.faultType === 'RED_BLOCK' ? 'btn-fault-selected' : ''}`}
                    onClick={() => this.handleFaultTypeSelect('RED_BLOCK')}
                  >
                    <img src="/images/faults/red_lock.png" alt="RED_BLOCK" />
                    <p>PIN ROJO, NO ABRE</p>
                  </button>
                </div>
                <div className="col-xs-12 col-md-2 row align-items-center justify-content-center mt-2 mb-2">
                  <button
                    type="button"
                    className={`btn btn-fault ${this.state.faultType === 'GREEN_BLOCK' ? 'btn-fault-selected' : ''}`}
                    onClick={() => this.handleFaultTypeSelect('GREEN_BLOCK')}
                  >
                    <img src="/images/faults/green_lock.png" alt="GREEN_BLOCK" />
                    <p>PIN VERDE, NO ABRE</p>
                  </button>
                </div>
              </div>
              <div className="form-row align-items-center flex justify-content-center mt-5">
                <div className="col-md-12 align-items-center flex justify-content-center">
                  <button className="btn bg-vw-light text-white" type="submit" disabled={submitting || faultType === ''}>
                    <i className="fas fa-bolt mr-1"></i>Confirmar avería
                  </button>
                </div>
              </div>
              <div className="form-row mt-5 justify-content-center">
                {error && <div className="alert alert-danger">{error}</div>}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

RemoteActionFaultAddForm = reduxForm({
  form: 'RemoteActionFaultAddForm',
})(RemoteActionFaultAddForm)

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
  formValues: state.form.RemoteActionFaultAddForm && state.form.RemoteActionFaultAddForm.values,
})

const mapDispatchToProps = dispatch => ({
  addFault: bindActionCreators(addFault, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoteActionFaultAddForm)
