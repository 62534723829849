import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Box from '../components/Box';
import ErrorMessage from '../components/ErrorMessage';
import FaultsReportFilterForm from '../forms/FaultsReportFilterForm';
import { fetchFaults } from '../actions/faults';
import { fetchReplacement } from '../actions/replacements';
import { fetchCustomers } from '../actions/customers';
import { fetchMatrixs } from '../actions/matrixs';

class FaultsReportView extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const today = new Date().toISOString().substring(0, 10);
    this.props.fetchFaults('', '', today, today);
    this.props.fetchReplacement("64cca358874b8124a31919ab");
    this.props.fetchCustomers();
    this.props.fetchMatrixs();
  }

  getClientFault(fault) {
    switch (fault) {
      case 'BROKEN_LOCK':
        return "Pincho roto"
      case 'GREEN_BLOCK':
        return "Pin verde no abre"
      case 'RED_BLOCK':
        return "Pin rojo no abre"
      case 'PINPAD_BLOCK':
        return "Teclado no enciende"
      case 'PINPAD_ACTIVE':
        return "Teclado encendido"
      case 'DOOR_BLOCK':
        return "Puerta no cierra"
      case 'PINPAD_ACTIVE':
        return "Teclado encendido"
      default:
        return "none"
    }
  }

  getMaterialName(MaterialCode) {
    switch (MaterialCode) {
      case 'LOCK_MAIN':
        return "Centralita"
      case 'LOCK_PINPAD':
        return "Teclado"
      case 'LOCK_ANCHORAGE':
        return "Pincho"
      case 'LOCK_PLATEN':
        return "Pletina"
      case 'LOCK_NUMBER':
        return "Número"
      case 'USB_WIRE':
        return "Cable USB"
      case 'BATTERIES':
        return "Pilas (pack 4)"
      case 'HINGE':
        return "Bisagra"
      case 'DOOR_BNS':
        return "Puerta Básica"
      case 'DOOR_BWS':
        return "Puerta Básica XL"
      case 'DOOR_BNM':
        return "Puerta Grande"
      case 'DOOR_BWM':
        return "Puerta Grande XL"
      case 'DOOR_BNL':
        return "Puerta Jumbo"
      case 'DOOR_BWL':
        return "Puerta Jumbo XL"
      default:
        return "Ajuste sin material"
    }
  }

  getMaterialPrice(MaterialCode) {
    const { replacement } = this.props;

    if (MaterialCode === 'NO_MATERIAL') {
      return 0
    }

    return replacement[MaterialCode].Price

  }

  getMaterialTotalPrice(MaterialCode, Qty) {
    const { replacement } = this.props;

    if (MaterialCode === 'NO_MATERIAL') {
      return 0
    }

    return (replacement[MaterialCode] && replacement[MaterialCode].Price && replacement[MaterialCode].Price || 0) * Qty

  }

  renderFaultReport() {
    const { customers, faults, error, loading } = this.props;

    // Check if faults is an array before attempting to iterate
    if (!Array.isArray(faults)) {
      return null; // Return or render a message indicating no faults available
    }

    //Customer info

    let customerName = ""
    let customerFiscalName = ""
    let customerTax = ""
    let customerAddress = ""
    let customerCIF = ""
    let customerAssigned = false

    // Calculate total price of materials
    const materialsMap = new Map();

    faults.forEach((fault) => {
      if (fault.Materials && Array.isArray(fault.Materials) && fault.Materials.length > 0) {
        fault.Materials.forEach((material) => {
          let { MaterialCode, Qty } = material;

          if (MaterialCode === 'DOOR') {
            MaterialCode = `DOOR_${fault.LockerType}`
          }

          if (!materialsMap.has(MaterialCode)) {
            materialsMap.set(MaterialCode, { Qty, Total: Qty });
          } else {
            const existingMaterial = materialsMap.get(MaterialCode);
            existingMaterial.Qty += Qty;
            existingMaterial.Total += Qty;
          }

          if (!customerAssigned) {
            customers.forEach((customer) => {
              if (customer._id === fault.Machine.Customer) {
                customerName = customer.Fullname
                customerFiscalName = customer.Social_name
                customerTax = customer.Invoice_tax
                customerAddress = customer.Address
                customerCIF = customer.Invoice_taxid

                customerAssigned = true
              }
            });
          }
        });
      }
    });

    // Calculate subtotal, VAT, and total
    const VAT_RATE = customerTax / 100; // Assuming 21% VAT
    let subtotal = 0;

    {
      Array.from(materialsMap.entries()).map(([MaterialCode, material]) => (
        subtotal += this.getMaterialTotalPrice(MaterialCode, material.Qty)
      ))
    };

    const total = subtotal * (1 + VAT_RATE);
    const base = subtotal;
    const vat = total - base;

    return (
      <Box>
        <h1 className="title">INFORME DE REPARACIÓN</h1>
        <br></br>
        <div className="subtitle">Fecha/Hora: {new Date().toLocaleString()}</div>
        <br></br>
        <div className="client-info">
          <div className="info-label">Cliente: {customerName}</div>
          <div className="info-label">Nombre Fiscal: {customerFiscalName}</div>
          <div className="info-label">CIF: {customerCIF}</div>
          <div className="info-label">Dirección: {customerAddress}</div>
        </div>
        <br></br>
        <h2 className="subtitle">Coste material de reparación</h2>
        <br></br>
        <div className="materials-list">
          <table className="table table-hover table-clickable">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Material</th>
                <th scope="col">Uds</th>
                <th scope="col">Precio/ud</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              {Array.from(materialsMap.entries()).map(([MaterialCode, material]) => (
                <tr key={MaterialCode}>
                  <td>{this.getMaterialName(MaterialCode)}</td>
                  <td>{material.Qty}</td>
                  <td>{this.getMaterialPrice(MaterialCode).toFixed(2)} €</td>
                  <td>{this.getMaterialTotalPrice(MaterialCode, material.Qty).toFixed(2)} €</td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Base:</td>
                <td>{base.toFixed(2)} €</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>IVA({VAT_RATE * 100}%):</td>
                <td>{vat.toFixed(2)} €</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>TOTAL:</td>
                <td>{total.toFixed(2)} €</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    );
  }

  renderFaults() {
    const { customers, replacement, faults, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!customers || !replacement || !faults || loading) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    if (faults && faults.length === 0) {
      return (<div><h2>No hay reparaciones para la fecha, cliente o máquina solicitados</h2></div>);
    }

    return (
      <div>{this.renderFaultReport()}</div>
    )
  }

  formatDate(timestamp) {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };

    return new Intl.DateTimeFormat('es-ES', options).format(new Date(timestamp));
  }

  getMatrixIndex(firstindex, serial, lockerSN) {
    const { matrixs } = this.props;

    let lockerIndex = 0;

    matrixs.map((matrix) => {

      if (matrix.Serial === serial) {

        matrix.Locker.map((locker, index) => {

          if (locker.BLEName === lockerSN) {
            lockerIndex = index
          }
        });
      }
    });

    return firstindex + lockerIndex

  }

  renderFaultMachineReport() {
    const { matrixs, faults, error, loading } = this.props;

    // Check if faults is an array before attempting to iterate
    if (!Array.isArray(faults)) {
      return <div>No faults available</div>;
    }

    return (
      <Box>
        <h1 className="title">Resumen de actuaciones</h1>
        <br />
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Fecha/Hora Averia</th>
              <th scope="col">Fecha/Hora Reparación</th>
              <th scope="col">Maquina</th>
              <th scope="col">Taquilla</th>
              <th scope="col">SN</th>
              <th scope="col">Material</th>
            </tr>
          </thead>
          <tbody>
            {faults.map((fault, index) => (
              <tr key={index}>
                <td>{this.formatDate(fault.InitTimeStamp)}</td>
                <td>{this.formatDate(fault.EndTimeStamp)}</td>
                <td>{fault.Serial}</td>
                <td>{this.getMatrixIndex(fault.Machine.FirstIndex, fault.Serial, fault.Locker)}</td>
                <td>{fault.Locker}</td>
                <td>
                  {fault.Materials &&
                    fault.Materials.map((material, matIndex) => (
                      <span key={matIndex}>
                        <br />{this.getMaterialName(material.MaterialCode)}: {material.Qty} <br /><br />
                      </span>
                    ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    );
  }

  renderFaultsMachine() {
    const { matrixs, customers, replacement, faults, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!matrixs || !customers || !replacement || !faults || loading) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    if (faults && faults.length === 0) {
      return (<div></div>);
    }

    return (
      <div>{this.renderFaultMachineReport()}</div>
    )
  }

  handlePrint = () => {
    window.print();
  };

  render() {

    return (
      <div>
        <div className="row mb-2 no_print">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">INFORME DE REPARACIÓN</h1>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 text-right">
            <button type="button" className="btn bg-vw-excel text-white" onClick={this.handlePrint}>
              <i className="fas fa-file-excel mr-1"></i>Descargar Informe
            </button>
          </div>
        </div>
        <hr className="bg-vw-light no_print" />
        <div className="no_print">
          <Box title="Filtro" icon="filter">
            <FaultsReportFilterForm />
          </Box>
        </div>
        <div id="printable-content">
          {this.renderFaults()}
          {this.renderFaultsMachine()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  replacement: state.replacements.replacement,
  matrixs: state.matrixs.matrixs,
  faults: state.faults.faults,
  customers: state.customers.customers,
  error: state.faults.error,
  loading: state.faults.loading,
  form: state.form
});

const mapDispatchToProps = dispatch => ({
  fetchFaults: bindActionCreators(fetchFaults, dispatch),
  fetchReplacement: bindActionCreators(fetchReplacement, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  fetchMatrixs: bindActionCreators(fetchMatrixs, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FaultsReportView);


