import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { history } from '../store'

import MachineStatusBadge from './MachineStatusBadge'
import MachineCashBadge from './MachineCashBadge'
import { getMachineCash, getMachineCashColor } from '../utils/machines'
import { addUniversalUpdateSingleParameters, addUniversalUpdateSingleReboot } from '../actions/universalupdates'

class MachineList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parametersConfirmShown: false,
      rebootConfirmShown: false,
      machineId: null,
      filters: {
        serial: '',
        customer: '',
        status: '',
        biller: '',
      },
    };

    this.updateParameters = this.updateParameters.bind(this)
    this.rebootMachine = this.rebootMachine.bind(this)

    this.parametersConfirmShow = this.parametersConfirmShow.bind(this)
    this.parametersConfirmToggle = this.parametersConfirmToggle.bind(this)
    this.rebootConfirmShow = this.rebootConfirmShow.bind(this)
    this.rebootConfirmToggle = this.rebootConfirmToggle.bind(this)
  }

  goTo(id, e) {
    history.push(`/machines/${id}`)
  }

  handleFilterChange(filterKey, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [filterKey]: value,
      },
    }));
  }

  getPayments(tipo) {

    switch (tipo) {
      case 0: {
        return "TPV + CASH"
      }
      case 1: {
        return "CASH"
      }
      case 2: {
        return "TPV"
      }
      case 3: {
        return "RFID"
      }
      case 4: {
        return "GRATIS"
      }
    }

  }

  renderLanguages(languages) {

    let languageImages = []

    languages.forEach(language => {

      const languageCode = language.Language.split('_')[0];

      languageImages.push(
        <div className="col"><img className="machinelistlanguage" src={`/languages/${languageCode.toLowerCase()}.png`} alt={language.Language}></img></div>
      )
    });

    return (
      <div className="row" >
        {languageImages}
      </div>
    )
  }

  handleParametersClick = (machine, event) => {
    event.stopPropagation();
    if (machine.Status !== 'OPERATIVE' && machine.Status !== 'LOCKERS_FULL' && machine.Status !== 'SLEEP') {
      alert("Acción bloqueada! La máquina no está online.");
    } else {
      this.parametersConfirmShow(machine._id);
    }
  }

  updateParameters() {
    const { addUniversalUpdateSingleParameters } = this.props

    addUniversalUpdateSingleParameters(this.state.machineId)
    this.parametersConfirmToggle()
  }

  parametersConfirmShow(id) {
    this.setState({
      parametersConfirmShown: true,
      machineId: id
    })
  }

  parametersConfirmToggle() {
    this.setState({ parametersConfirmShown: !this.state.parametersConfirmShown })
  }

  handleRebootClick = (machine, event) => {

    event.stopPropagation();
    if (machine.Status !== 'OPERATIVE' && machine.Status !== 'LOCKERS_FULL' && machine.Status !== 'SLEEP') {
      alert("Acción bloqueada! La máquina no está online.");
    } else {
      this.rebootConfirmShow(machine._id);
    }
  }

  rebootMachine() {
    const { addUniversalUpdateSingleReboot } = this.props

    addUniversalUpdateSingleReboot(this.state.machineId)
    this.rebootConfirmToggle()
  }

  rebootConfirmShow(id) {
    this.setState({
      rebootConfirmShown: true,
      machineId: id
    })
  }

  rebootConfirmToggle() {
    this.setState({ rebootConfirmShown: !this.state.rebootConfirmShown })
  }

  formatNightscanDate = (timestamp) => {
    const year = timestamp.substring(0, 4);
    const month = timestamp.substring(4, 6) - 1; // JavaScript months are 0-11
    const day = timestamp.substring(6, 8);
    const hour = timestamp.substring(8, 10);
    const minute = timestamp.substring(10, 12);
    const second = timestamp.substring(12, 14);

    return new Date(year, month, day, hour, minute, second);
  };

  isNightscanInSleepPeriod = (nightscanDate, sleepStart, sleepEnd) => {
    const now = new Date();
    const today = new Date(now);
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    // Check if nightscanDate is from today or yesterday
    const isTodayOrYesterday =
      nightscanDate.toDateString() === today.toDateString() ||
      nightscanDate.toDateString() === yesterday.toDateString();

    if (!isTodayOrYesterday) return false;

    const startHours = Math.floor(sleepStart / 100);
    const startMinutes = sleepStart % 100;
    const endHours = Math.floor(sleepEnd / 100);
    const endMinutes = sleepEnd % 100;

    let startDate = new Date(nightscanDate);
    startDate.setHours(startHours, startMinutes, 0, 0);

    let endDate = new Date(nightscanDate);
    endDate.setHours(endHours, endMinutes, 0, 0);

    // If sleepStart is greater than sleepEnd, it means the sleep spans across midnight
    if (startHours > endHours || (startHours === endHours && startMinutes > endMinutes)) {
      // Adjust the dates to span midnight
      if (nightscanDate.toDateString() === yesterday.toDateString()) {
        // The nightscanDate is from yesterday, so we set the startDate as yesterday's date
        startDate = new Date(yesterday);
        startDate.setHours(startHours, startMinutes, 0, 0);

        // The endDate will be today at the sleepEnd time
        endDate = new Date(today);
        endDate.setHours(endHours, endMinutes, 0, 0);
      } else if (nightscanDate.toDateString() === today.toDateString()) {
        // The nightscanDate is from today, we need to check if it’s within the end range
        const previousDayStartDate = new Date(yesterday);
        previousDayStartDate.setHours(startHours, startMinutes, 0, 0);

        // End date remains as today's sleepEnd
        return (nightscanDate >= previousDayStartDate && nightscanDate <= endDate);
      }
    }

    // Normal case where sleepStart is less than or equal to sleepEnd (no midnight spanning)
    return (nightscanDate >= startDate && nightscanDate <= endDate);
  };

  getZoneName(zone) {

    switch (zone) {
      case 0:
        return 'GENERAL';
      case 1:
        return 'BAQUEIRA';
      case 2:
        return 'BERET';
      case 3:
        return 'BONAIGUA';
      case 4:
        return 'RUDA';
      case 5:
        return 'BOSQUE';
      default:
        return 'NONE';
    }
  }

  render() {
    const { filters } = this.state;
    const { machines, auth, role, category } = this.props;

    // Apply the filters to filter the machines array
    const filteredMachines = machines.filter(machine => {
      const { serial, customer, status, biller } = filters;

      // Apply the Serial filter
      if (serial && !machine.Serial.includes(serial)) {
        return false;
      }

      // Apply the Customer or Contract Zone filter based on category
      if (customer) {
        if (category === 'BAQUEIRA') {
          // Apply the Contract Zone filter
          if (!machine.Contract || !machine.Contract.Zone || !this.getZoneName(machine.Contract.Zone).toLowerCase().includes(customer.toLowerCase())) {
            return false;
          }
        } else {
          // Apply the Customer filter
          if (!machine.Customer || !machine.Customer.Fullname.toLowerCase().includes(customer.toLowerCase())) {
            return false;
          }
        }
      }

      // Apply the Status filter
      if (
        status &&
        (!machine.Status ||
          !machine.Status.toLowerCase().includes(status.toLowerCase()))
      ) {
        return false;
      }

      // Apply the Biller filter
      if (
        biller &&
        (!getMachineCash(machine).toLowerCase().includes(biller.toLowerCase()))
      ) {
        return false;
      }

      // If all conditions pass, include the machine in the filtered list
      return true;
    });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Identificador</th>
              <th scope="col">{category == 'BAQUEIRA' ? 'Zona' : 'Cliente'}</th>
              <th scope="col">Estado</th>
              {category !== 'BAQUEIRA' &&
                <th scope="col">Idiomas</th>
              }
              <th scope="col">Passwords</th>
              <th scope="col">Numeración</th>
              <th scope="col">Versiones</th>
              {category !== 'BAQUEIRA' &&
                <th scope="col">Pago</th>
              }
              {category !== 'BAQUEIRA' &&
                <th scope="col">Billetero</th>
              }
              {(auth.type !== 'LIMITED' && role === 'ADMIN') &&
                <th scope="col">Nightscan</th>
              }
              <th scope="col">MAC</th>
              {(auth.type !== 'LIMITED' && role === 'ADMIN') &&
                <th scope="col">Acciones</th>
              }
            </tr>
            <tr>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Serial"
                  value={filters.serial}
                  onChange={e => this.handleFilterChange('serial', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  placeholder={category == 'BAQUEIRA' ? 'Zona' : 'Cliente'}
                  value={filters.customer}
                  onChange={e => this.handleFilterChange('customer', e.target.value)}
                />
              </th>
              <th scope="col">
                <select
                  value={filters.status}
                  onChange={e => this.handleFilterChange('status', e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="OPERATIVE">ONLINE</option>
                  <option value="OFFLINE">OFFLINE</option>
                  <option value="SLEEP">SLEEP</option>
                  <option value="NIGHT_SCAN">NIGHTSCAN</option>
                  <option value="MAINTENANCE">MANTENIMIENTO</option>
                  <option value="LOCKERS_FULL">LOCKERS LLENOS</option>
                  <option value="TICKET_EMPTY">SIN TICKET</option>
                  <option value="KERONG_OTA">OTA KERONG</option>
                  <option value="BOOTLOADER_OTA">OTA BOOTLOADER</option>
                  <option value="BLE_OFF">POWER DOWN</option>
                </select>
              </th>
              {category !== 'BAQUEIRA' &&
                <th scope="col"></th>
              }
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              {category !== 'BAQUEIRA' &&
                <th scope="col"></th>
              }
              {category !== 'BAQUEIRA' &&
                <th scope="col">
                  <select
                    value={filters.biller}
                    onChange={e => this.handleFilterChange('biller', e.target.value)}
                  >
                    <option value="">Todos</option>
                    <option value="OK">OK</option>
                    <option value="FULL">FULL</option>
                  </select>
                </th>
              }
              {(auth.type !== 'LIMITED' && role === 'ADMIN') &&
                <th scope="col"></th>
              }
              <th scope="col"></th>
              {(auth.type !== 'LIMITED' && role === 'ADMIN') &&
                <th scope="col"></th>
              }
            </tr>
          </thead>
          <tbody>
            {filteredMachines.map(machine => {

              // Create a new Date object using the parsed parts
              const nightscanDate = this.formatNightscanDate(machine.NightScanTimeStampEnd);

              const isInSleepPeriod = this.isNightscanInSleepPeriod(nightscanDate, machine.SleepStart, machine.SleepEnd);
              const cellClass = isInSleepPeriod ? 'bg-success text-white rounded' : 'bg-danger text-white rounded';

              return (

                <tr key={machine._id}
                  onClick={e => this.goTo(machine._id, e)}>
                  <th scope="row">{machine.Serial}</th>
                  <td>
                    {(machine.Customer && (category == 'BAQUEIRA' ? this.getZoneName(machine.Contract.Zone) : machine.Customer.Fullname)) || 'None'}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    <MachineStatusBadge machine={machine} />
                    <br></br>
                    {(new Date(machine.Last_signal)).toLocaleString()}
                    <br></br>
                    Sleep: {`${machine.SleepStart.substring(0, 2)}:${machine.SleepStart.substring(2)} h`} - {`${machine.SleepEnd.substring(0, 2)}:${machine.SleepEnd.substring(2)} h`}
                  </td>
                  {category !== 'BAQUEIRA' &&
                    <td>
                      {/* {machine.Customer ? zoneDescription : 'None'} */}
                      {this.renderLanguages(machine.SupportedLanguages)}
                    </td>
                  }
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {category !== 'BAQUEIRA' &&
                      `Maquina: ${machine.PasswordMachine}`
                    }<br></br>
                    {category !== 'BAQUEIRA' &&
                      `Cash: ${machine.PasswordFund}`
                    }<br></br>
                    Universal: {machine.PasswordCash}<br></br>
                    {role === 'ADMIN' &&
                      `Master: ${machine.PasswordMaster}`
                    }
                  </td>
                  <td>{`${machine.FirstIndex} a ${parseInt(machine.LastIndex) + parseInt(machine.FirstIndex) - 1}`}<br></br>
                    ({machine.LastIndex} Taquillas)
                  </td>
                  <td>
                    SW: {machine.Sw_version}<br></br>
                    FW: {machine.Fw_version}<br></br>
                    UP: {machine.Up_version}<br></br>
                    ESP32: <br></br>{machine.ESP32_version}<br></br>
                  </td>
                  {category !== 'BAQUEIRA' &&
                    <td>
                      {this.getPayments(machine.PaymentMethods)}
                    </td>
                  }
                  {category !== 'BAQUEIRA' &&
                    <td>
                      <div className="d-inline-block p-2">
                        <MachineCashBadge machine={machine} />
                      </div>
                    </td>
                  }
                  {(auth.type !== 'LIMITED' && role === 'ADMIN') &&
                    <td>
                      <div className={`d-inline-block p-2 ${cellClass}`}>
                        {nightscanDate.toLocaleString()}
                      </div>
                    </td>
                  }
                  <td>
                    {machine.Anydesk}
                  </td>
                  {(auth.type !== 'LIMITED' && role === 'ADMIN') &&
                    <td>
                      <button
                        className="btn btn-info mb-3"
                        onClick={(event) => this.handleParametersClick(machine, event)}
                      >
                        <i className="fas fa-database"></i>
                        <span className="d-none d-sm-inline ml-1">Actualizar</span>
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={(event) => this.handleRebootClick(machine, event)}
                      >
                        <i className="fas fa-plug"></i>
                        <span className="d-none d-sm-inline ml-1">Reiniciar</span>
                      </button>
                    </td>
                  }
                </tr>
              )
            })}
          </tbody>
        </table>
        <div>
          <Modal isOpen={this.state.parametersConfirmShown}
            toggle={this.parametersConfirmToggle}>
            <ModalHeader toggle={this.parametersConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de actualizar los parámetros?</p>
              <br></br>
              <p>-La máquina se actualizará interrumpiendo los procesos y saldrá un mensaje en pantalla para reiniciar la máquina una vez finalizado el proceso.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.updateParameters}>
                Actualizar
              </Button>
              <Button color="secondary" onClick={this.parametersConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <div>
          <Modal isOpen={this.state.rebootConfirmShown}
            toggle={this.rebootConfirmToggle}>
            <ModalHeader toggle={this.rebootConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de reiniciar la máquina?</p>
              <br></br>
              <p>-La máquina se reiniciará interrumpiendo los procesos.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.rebootMachine}>
                Reiniciar
              </Button>
              <Button color="secondary" onClick={this.rebootConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
  category: state.auth.category
})

const mapDispatchToProps = dispatch => ({
  addUniversalUpdateSingleParameters: bindActionCreators(addUniversalUpdateSingleParameters, dispatch),
  addUniversalUpdateSingleReboot: bindActionCreators(addUniversalUpdateSingleReboot, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MachineList)
