import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { history } from '../store'
import { updateCalendar } from '../actions/calendars'

class CalendarsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editId: null, // Track which calendar is being edited
      newName: '', // Track new name input
    }

  }

  goTo(id, e) {
    e.stopPropagation();
    history.push(`/calendars/${id}`)
  }

  startEditing(id, currentName) {
    // Start editing by setting the editId and initial name
    this.setState({ editId: id, newName: currentName })
  }

  handleNameChange(e) {
    this.setState({ newName: e.target.value }) // Update name as user types
  }

  saveName(id) {
    // Call the update API with the new name
    this.props.updateCalendar(id, { Name: this.state.newName })
      .then(() => {
        // Reset the edit state and refresh the list
        this.setState({ editId: null, newName: '' })
        this.props.refreshCalendars() // Assuming a prop function to refresh the list
      })
      .catch(error => {
        // Handle error if the fetchMachine request fails
        console.error('Error updating calendar name:', error);
      });
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Fecha de creación</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {this.props.calendars.map(calendar => (
              <tr key={calendar._id} onClick={e => this.goTo(calendar._id, e)}>
                <td>
                  {this.state.editId === calendar._id ? (
                    <input
                      className="w-100 text-center"
                      type="text"
                      value={this.state.newName}
                      onClick={(e) => e.stopPropagation()}
                      onChange={e => this.handleNameChange(e)}
                    />
                  ) : (
                    calendar.Name
                  )}
                </td>
                <td>{(new Date(calendar.TimeStamp)).toLocaleDateString('es-ES', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}</td>
                <td>
                  {this.state.editId === calendar._id ? (
                    <button type="button" className="btn bg-warning text-dark" onClick={(e) => {
                      e.stopPropagation(); // Prevents the row's onClick from firing
                      this.saveName(calendar._id);
                    }}>
                      Guardar Nombre
                    </button>
                  ) : (
                    <button type="button" className="btn bg-info text-white" onClick={(e) => {
                      e.stopPropagation(); // Prevents the row's onClick from firing
                      this.startEditing(calendar._id, calendar.Name);
                    }}>
                      Editar Nombre
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  updateCalendar: (id, data) => dispatch(updateCalendar(id, data)),
});

export default connect(null, mapDispatchToProps)(CalendarsList);


