import React from 'react'
import { history } from '../store'

class QRList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filters: {
        code: '',
        customer: '',
      },
    };
  }

  goTo(id, e) {
    history.push(`/qrs/${id}`)
  }

  handleFilterChange(filterKey, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [filterKey]: value,
      },
    }));
  }

  returnQrType(type) { 
    
    switch(type) {
      case 'pay': return 'Pago';
      case 'free': return 'Gratis';
      case 'test': return 'Test';
    }

  }

  returnQrMode(mode) { 
    
    switch(mode) {
      case 0: return '24H CON PIN';
      case 1: return 'OTO SIN PIN';
      case 2: return 'BAQUEIRA';
    }

  }

  render() {
    const { filters } = this.state;
    const { qrs } = this.props;

    // Apply the filters to filter the qrs array
    const filteredqrs = qrs.filter(qr => {
      const { code: codeFilter, customer: customerFilter } = filters;

      if (
        codeFilter &&
        (!qr.Code ||
          !qr.Code.toLowerCase().includes(codeFilter.toLowerCase()))
      ) {
        return false;
      }

      if (
        customerFilter &&
        (!qr.Customer.Fullname ||
          !qr.Customer.Fullname.toLowerCase().includes(customerFilter.toLowerCase()))
      ) {
        return false;
      }

      // If all conditions pass, include the customer in the filtered list
      return true;
    });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Código</th>
              <th scope="col">Cliente</th>
              <th scope="col">Zona</th>
              <th scope="col">Tipo</th>
              <th scope="col">Modo</th>
              <th scope="col">Ventas</th>
              <th scope="col">Url</th>
            </tr>
            <tr>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Code"
                  value={filters.code}
                  onChange={e => this.handleFilterChange('code', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Cliente"
                  value={filters.customer}
                  onChange={e => this.handleFilterChange('customer', e.target.value)}
                />
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {filteredqrs.map(qr => (
              <tr key={qr.Code}
                onClick={e => this.goTo(qr._id, e)}>
                <th scope="row">{qr.Code}</th>
                <td scope="row">{qr.Customer.Fullname}</td>
                <td scope="row">Zona {qr.Zone}</td>
                <td scope="row">{this.returnQrType(qr.Type)}</td>
                <td scope="row">{this.returnQrMode(qr.Mode)}</td>
                <td scope="row">{qr.LastSale}</td>
                <td scope="row">{qr.Url}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default QRList