import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Box from '../components/Box'
import SaleList from '../components/SaleList'
import ErrorMessage from '../components/ErrorMessage'

import SalesPlots from '../components/SalesPlots'
import SalesFilterForm from '../forms/SalesFilterForm'
import { fetchSales, fetchSalesCSV } from '../actions/sales'
import { fetchMachines } from '../actions/machines';
import { getLockersName } from '../utils/machines'

class SalesView extends React.Component {
  constructor(props) {
    super(props)

    this.handleSalesCSV = this.handleSalesCSV.bind(this)
  }

  componentDidMount() {
    const today = new Date().toISOString().substring(0, 10);

    // Start the timer when fetching sales data begins
    const startTime = performance.now();

    this.props.fetchSales('', '', today, today, '', '', '', '')
      .then((response) => {
        // Calculate the total time taken to fetch sales data
        const endTime = performance.now();
        const totalTimeInSeconds = (endTime - startTime) / 1000;

      })
      .catch((error) => {
        console.error('Error fetching sales data:', error);

      });

    this.props.fetchMachines()
  }

  calculateAverageTime() {
    const { sales } = this.props

    if (!sales) {
      return
    }

    let totalTime = 0
    let indexTime = 0

    sales.forEach((sale, index) => {

      if (sale.OperationTime > 0 && sale.OperationTime < 500) {
        totalTime += sale.OperationTime

        if (sale.Amount > 0) {
          indexTime++;
        }
      }

    });

    totalTime = totalTime / indexTime

    const minutes = Math.floor(totalTime / 60);
    const seconds = Math.floor(totalTime % 60).toString().padStart(2, '0');;

    return (<div>{minutes}:{seconds}</div>)
  }

  calculatePercentageSales(type) {
    const { sales } = this.props

    if (!sales) {
      return
    }

    let totalSales = 0
    let totalCash = 0
    let percentageCash = 0

    sales.forEach((sale, index) => {

      if (sale.Amount >= 0) {
        if (sale.PaymentMethod == type) {
          totalCash++;
        }

        totalSales++;
      } else if (sale.Amount < 0) {
        if (sale.PaymentMethod == type) {
          totalCash--;
        }
        totalSales--;
      }

    });

    percentageCash = ((totalCash / totalSales) * 100).toFixed(2)

    return (percentageCash)
  }

  calculateBills5() {
    const { sales } = this.props;

    if (!sales) {
      return 0;
    }

    let bills5 = 0;

    sales.forEach((sale, index) => {
      if (sale.Amount > 0) {
        const detailsLines = sale.Details.split('\n');
        const bill5Pattern = 'B: 5.00 €';

        detailsLines.forEach((line) => {
          if (line.includes(bill5Pattern)) {
            bills5++;
          }
        });
      }
    });

    return bills5;
  }

  calculateBills10() {
    const { sales } = this.props;

    if (!sales) {
      return 0;
    }

    let bills10 = 0;

    sales.forEach((sale, index) => {
      if (sale.Amount > 0) {
        const detailsLines = sale.Details.split('\n');
        const bill10Pattern = 'B: 10.00 €';


        detailsLines.forEach((line) => {
          if (line.includes(bill10Pattern)) {
            bills10++;
          }
        });

      }
    });

    return bills10;
  }

  computeSalesTotals() {
    const { sales } = this.props

    if (!sales) {
      return
    }

    const itemIndices = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 1],
      ['LOCKERBWS', 2],
      ['LOCKERCWS', 3],
      ['LOCKERBNM', 4],
      ['LOCKERCNM', 5],
      ['LOCKERBWM', 6],
      ['LOCKERCWM', 7],
      ['LOCKERBNL', 8],
      ['LOCKERCNL', 9],
      ['LOCKERBWL', 10],
      ['LOCKERCWL', 11],
      ['LOCKERBNXL', 12],
      ['LOCKERCNXL', 13],
      ['LOCKERBWXL', 14],
      ['LOCKERCWXL', 15],
      ['LOCKERBN2XL', 16],
      ['LOCKERCN2XL', 17],
      ['LOCKERBW2XL', 18],
      ['LOCKERCW2XL', 19],
      ['LOCKERBN3XL', 20],
      ['LOCKERCN3XL', 21],
      ['LOCKERBW3XL', 22],
      ['LOCKERCW3XL', 23],
      ['TUMBONAS', 24],
      ['FLOTADORES', 25],
      ['FLOTADORESHALFDAY', 26],
      ['FASTPASS', 27]
    ])

    let total = 0
    let totalnotax = 0

    let totalsPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['QR', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['DEVOLUTION', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['PDA', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['FREE', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    let amount = 0

    let amountsPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['QR', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['DEVOLUTION', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['PDA', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['FREE', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    let qtyPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['QR', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['DEVOLUTION', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['PDA', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['FREE', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    let totalsPerItem = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 0],
      ['LOCKERBWS', 0],
      ['LOCKERCWS', 0],
      ['LOCKERBNM', 0],
      ['LOCKERCNM', 0],
      ['LOCKERBWM', 0],
      ['LOCKERCWM', 0],
      ['LOCKERBNL', 0],
      ['LOCKERCNL', 0],
      ['LOCKERBWL', 0],
      ['LOCKERCWL', 0],
      ['LOCKERBNXL', 0],
      ['LOCKERCNXL', 0],
      ['LOCKERBWXL', 0],
      ['LOCKERCWXL', 0],
      ['LOCKERBN2XL', 0],
      ['LOCKERCN2XL', 0],
      ['LOCKERBW2XL', 0],
      ['LOCKERCW2XL', 0],
      ['LOCKERBN3XL', 0],
      ['LOCKERCN3XL', 0],
      ['LOCKERBW3XL', 0],
      ['LOCKERCW3XL', 0],
      ['TUMBONAS', 0],
      ['FLOTADORES', 0],
      ['FLOTADORESHALFDAY', 0],
      ['FASTPASS', 0]
    ])

    for (let sale of sales) {
      let totalsPerSaleMethod = totalsPerMethod.get(sale.PaymentMethod)
      let amountsPerSaleMethod = amountsPerMethod.get(sale.PaymentMethod)
      let qtyPerSaleMethod = qtyPerMethod.get(sale.PaymentMethod)

      if (sale.Items) {
        for (let item of sale.Items) {

          let itemTotal = 0
          let itemAmount = 0

          itemTotal = item.price
          itemAmount = item.price

          if (sale.Amount != 0) {
            total += itemTotal
            totalnotax += (itemTotal / (1 + (sale.Customer.Invoice_tax / 100)))

            if (sale.Amount < 0) {
              amountsPerSaleMethod = amountsPerMethod.get("DEVOLUTION")
              totalsPerSaleMethod = totalsPerMethod.get("DEVOLUTION")
              qtyPerSaleMethod = qtyPerMethod.get("DEVOLUTION")
            }

            totalsPerSaleMethod[itemIndices.get(item.item)] += itemTotal
            totalsPerItem.set(item.item, totalsPerItem.get(item.item) + itemTotal)

            amount += itemAmount
            amountsPerSaleMethod[itemIndices.get(item.item)] += itemAmount
          }

          qtyPerSaleMethod[itemIndices.get(item.item)] += Math.abs(item.qty)

        }
      }

      if (sale.Amount < 0) {
        amountsPerMethod.set("DEVOLUTION", amountsPerSaleMethod)
        totalsPerMethod.set("DEVOLUTION", totalsPerSaleMethod)
        qtyPerMethod.set("DEVOLUTION", qtyPerSaleMethod)
      } else {
        amountsPerMethod.set(sale.PaymentMethod, amountsPerSaleMethod)
        totalsPerMethod.set(sale.PaymentMethod, totalsPerSaleMethod)
        qtyPerMethod.set(sale.PaymentMethod, qtyPerSaleMethod)
      }
    }

    return {
      total: total,
      totalnotax: totalnotax,
      totalsPerMethod: totalsPerMethod,
      totalsPerItem: totalsPerItem,
      amount: amount,
      amountsPerMethod: amountsPerMethod,
      qtyPerMethod: qtyPerMethod
    }
  }

  computeItemsTotals() {
    const { sales } = this.props

    let totalQty = 0

    let totalsPerItemQty = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 0],
      ['LOCKERBWS', 0],
      ['LOCKERCWS', 0],
      ['LOCKERBNM', 0],
      ['LOCKERCNM', 0],
      ['LOCKERBWM', 0],
      ['LOCKERCWM', 0],
      ['LOCKERBNL', 0],
      ['LOCKERCNL', 0],
      ['LOCKERBWL', 0],
      ['LOCKERCWL', 0],
      ['LOCKERBNXL', 0],
      ['LOCKERCNXL', 0],
      ['LOCKERBWXL', 0],
      ['LOCKERCWXL', 0],
      ['LOCKERBN2XL', 0],
      ['LOCKERCN2XL', 0],
      ['LOCKERBW2XL', 0],
      ['LOCKERCW2XL', 0],
      ['LOCKERBN3XL', 0],
      ['LOCKERCN3XL', 0],
      ['LOCKERBW3XL', 0],
      ['LOCKERCW3XL', 0],
      ['TUMBONAS', 0],
      ['FLOTADORES', 0],
      ['FLOTADORESHALFDAY', 0],
      ['FASTPASS', 0]
    ])

    for (let sale of sales) {

      if (sale.Amount != 0) {
        for (let item of sale.Items) {

          let itemTotal = item.qty

          totalQty += itemTotal
          totalsPerItemQty.set(item.item, totalsPerItemQty.get(item.item) + itemTotal)
        }
      }
    }

    return {
      totalQty: totalQty,
      totalsPerItemQty: totalsPerItemQty
    }
  }

  handleSalesCSV() {
    const { fetchSalesCSV, form } = this.props

    fetchSalesCSV(form.SalesFilterForm.values.Machine,
      form.SalesFilterForm.values.Customer,
      form.SalesFilterForm.values.StartDate,
      form.SalesFilterForm.values.EndDate,
      form.SalesFilterForm.values.Country,
      form.SalesFilterForm.values.Category,
      form.SalesFilterForm.values.PaymentMethod)
  }

  salesTotalRow({ label, methods, totals, currency }) {
    return (
      <tr>
        <th scope="row"><h6 className="text-left text-vw-dark">{label}</h6></th>
        {methods.map(method => (
          <th scope="row" key={method}>
            {`${totals.amountsPerMethod.get(method).toLocaleString("es-ES", { style: "currency", currency })} / ${totals.qtyPerMethod.get(method)} u`}
          </th>
        ))}
      </tr>
    );
  }

  anyItemSale(totals, index) {

    if (
      totals.amountsPerMethod.get('CASH')[index] > 0 ||
      totals.amountsPerMethod.get('TPV')[index] > 0 ||
      totals.amountsPerMethod.get('TOKEN')[index] > 0 ||
      totals.amountsPerMethod.get('DEVOLUTION')[index] > 0 ||
      totals.amountsPerMethod.get('QR')[index] > 0 ||
      totals.amountsPerMethod.get('PDA')[index] > 0 ||
      totals.amountsPerMethod.get('FREE')[index] > 0
    ) {
      return true
    } else {
      return false
    }
  }

  renderSalesTotals() {
    const { sales, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!sales || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    let totals = this.computeSalesTotals()
    let totalsQty = this.computeItemsTotals()

    const itemsArray = [
      "LOCKERBNS", "LOCKERCNS", "LOCKERBWS", "LOCKERCWS",
      "LOCKERBNM", "LOCKERCNM", "LOCKERBWM", "LOCKERCWM",
      "LOCKERBNL", "LOCKERCNL", "LOCKERBWL", "LOCKERCWL",
      "LOCKERBNXL", "LOCKERCNXL", "LOCKERBWXL", "LOCKERCWXL",
      "LOCKERBN2XL", "LOCKERCN2XL", "LOCKERBW2XL", "LOCKERCW2XL",
      "LOCKERBN3XL", "LOCKERCN3XL", "LOCKERBW3XL", "LOCKERCW3XL",
      "TUMBONAS", "FLOTADORES", "FLOTADORESHALFDAY", "FASTPASS"
    ];

    return (
      <div className="mb-3 p-3 bg-white box-shadow rounded text-vw-dark text-center">
        <div className="row justify-content-center">
          <div className="col-xs-12 col-md-4">
            <div className="row justify-content-center">
              <h5>TOTAL DE VENTAS CON IVA: </h5>
              <h5 className="text-vw-light ml-2">{`${(totals.total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="row justify-content-center">
              <h5>TOTAL DE VENTAS SIN IVA: </h5>
              <h5 className="text-vw-light ml-2">{`${(totals.totalnotax).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="row justify-content-center">
              <h5>TIEMPO MEDIO DE COMPRA: </h5>
              <h5 className="text-vw-light ml-2">{this.calculateAverageTime()}</h5>
            </div>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <div className="table-responsive">
          <table className="table table-sm table-stripped">
            <thead className="thead-dark">
              <tr>
                <th scope="col"></th>
                <th scope="col"><h6 className="text-white">CASH ({this.calculatePercentageSales('CASH')}%)</h6></th>
                <th scope="col"><h6 className="text-white">TPV ({this.calculatePercentageSales('TPV')}%)</h6></th>
                <th scope="col"><h6 className="text-white">QR ({this.calculatePercentageSales('QR')}%)</h6></th>
                <th scope="col"><h6 className="text-white">PDA ({this.calculatePercentageSales('PDA')}%)</h6></th>
                <th scope="col"><h6 className="text-white">GRATIS ({this.calculatePercentageSales('FREE')}%)</h6></th>
                <th scope="col"><h6 className="text-white">MANUAL ({this.calculatePercentageSales('TOKEN')}%)</h6></th>
                <th scope="col"><h6 className="text-white">DEVOLUCIONES</h6></th>
                <th scope="col"><h6 className="text-white">TOTAL</h6></th>
              </tr>
            </thead>
            <tbody>
              {
                itemsArray.map((item, index) => {
                  if (this.anyItemSale(totals, index)) {
                    return (
                      <tr key={item}>
                        <th scope="row"><h6 className="text-left text-vw-dark">{getLockersName(item)}</h6></th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('CASH')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('CASH')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('TPV')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('TPV')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('QR')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('QR')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('PDA')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('PDA')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('FREE')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('FREE')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('TOKEN')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('TOKEN')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('DEVOLUTION')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('DEVOLUTION')[index]} u`}</th>
                        <th scope="row">{`${(totals.totalsPerItem.get(item)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totalsQty.totalsPerItemQty.get(item)} u`}</th>
                      </tr>
                    );
                  }
                  return null;
                })
              }
              <tr>
                <th scope="row"><h5 className="text-left text-vw-dark">TOTALES</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('CASH').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('TPV').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('QR').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('PDA').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('FREE').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('TOKEN').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('DEVOLUTION').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderSalesStats() {
    const { sales, machines, error, loading, auth } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!sales || loading || !machines) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <Box title="Estadísticas" icon="chart-bar">
        <SalesPlots sales={sales} machines={machines} />
      </Box>
    )
  }

  renderSales() {
    const { sales, machines, error, loading, auth } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!sales || loading || !machines) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <div>
        {this.renderSalesStats()}
        <Box title="Listado (100 ventas por página)">
          <div className="row mb-3">
            <div className="col">
              <button className="btn bg-vw-excel text-white" onClick={this.handleSalesCSV}>
                <i className="fas fa-file-excel mr-1"></i>Descargar
              </button>
            </div>
          </div>
          <SaleList auth={auth} />
        </Box>
      </div>
    )
  }

  render() {
    const { role, type } = this.props.auth

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              VENTAS DIARIAS
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <Box title="Filtro" icon="filter">
          <SalesFilterForm />
        </Box>
        <div>
          {this.renderSalesTotals()}
        </div>
        {this.renderSales()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sales: state.sales.sales,
  error: state.sales.error,
  loading: state.sales.loading,
  auth: state.auth,
  form: state.form,
  machines: state.machines.machines,
  role: state.auth.role
})

const mapDispatchToProps = dispatch => ({
  fetchSales: bindActionCreators(fetchSales, dispatch),
  fetchSalesCSV: bindActionCreators(fetchSalesCSV, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesView)


