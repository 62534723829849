import { CALL_API } from '../middleware/api'

export const MATRIXS_REQUEST = 'MATRIXS_REQUEST'
export const MATRIXS_SUCCESS = 'MATRIXS_SUCCESS'
export const MATRIXS_FAILURE = 'MATRIXS_FAILURE'

export function fetchMatrixs(machine, phone, customer, zone) {
  let params = ''

  if (machine !== undefined) {
    params += `&machine=${machine}`
  }

  if (phone !== undefined) {
    params += `&phone=${phone}`
  }

  if (customer !== undefined) {
    params += `&customer=${customer}`
  }

  if (zone !== undefined) {
    params += `&zone=${zone}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `matrixs?${params}`,
      authenticated: true,
      types: [MATRIXS_REQUEST, MATRIXS_SUCCESS, MATRIXS_FAILURE]
    }
  }
}

export const MATRIX_REQUEST = 'MATRIX_REQUEST'
export const MATRIX_SUCCESS = 'MATRIX_SUCCESS'
export const MATRIX_FAILURE = 'MATRIX_FAILURE'

export function fetchMatrix(Serial) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `matrixs/${Serial}`,
      authenticated: true,
      types: [MATRIX_REQUEST, MATRIX_SUCCESS, MATRIX_FAILURE]
    }
  }
}

export function blockMatrix(id, blockType, matrixSN, matrixMatricula) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `matrixs/block/${id}`,
      authenticated: true,
      types: [MATRIX_REQUEST, MATRIX_SUCCESS, MATRIX_FAILURE],
      body: JSON.stringify({ BlockType: blockType, BLEName: matrixSN, Matricula: matrixMatricula })
    }
  }
}

export function sendMatrix(Serial, FirstIndex, LastIndex, Matrix) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `matrixs/send/${Serial}`,
      authenticated: true,
      types: [MATRIX_REQUEST, MATRIX_SUCCESS, MATRIX_FAILURE],
      body: JSON.stringify({ FirstIndex: FirstIndex, LastIndex: LastIndex, Matrix: Matrix })
    }
  }
}

export function blockOnlineSale(id, blockType, matrixSN, matrixMatricula) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `matrixs/blockOnlineSale/${id}`,
      authenticated: true,
      types: [MATRIX_REQUEST, MATRIX_SUCCESS, MATRIX_FAILURE],
      body: JSON.stringify({ BlockType: blockType, BLEName: matrixSN, Matricula: matrixMatricula })
    }
  }
}

export const DOWNLOAD_REQUEST = 'DOWNLOAD_REQUEST'
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS'
export const DOWNLOAD_FAILURE = 'DOWNLOAD_FAILURE'

export function fetchFaultsCSV(Customer) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `matrixs/pdf/${Customer}`,
      authenticated: true,
      types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE]
    }
  }
}

export const DOWNLOAD_BATERIES_REQUEST = 'DOWNLOAD_BATERIES_REQUEST'
export const DOWNLOAD_BATERIES_SUCCESS = 'DOWNLOAD_BATERIES_SUCCESS'
export const DOWNLOAD_BATERIES_FAILURE = 'DOWNLOAD_BATERIES_FAILURE'

export function fetchBateriesCSV() {
  let params = ''

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `matrixs/bateries?${params}`,
      authenticated: true,
      types: [DOWNLOAD_BATERIES_REQUEST, DOWNLOAD_BATERIES_SUCCESS, DOWNLOAD_BATERIES_FAILURE]
    }
  }
}

export const DOWNLOAD_SN_REQUEST = 'DOWNLOAD_SN_REQUEST'
export const DOWNLOAD_SN_SUCCESS = 'DOWNLOAD_SN_SUCCESS'
export const DOWNLOAD_SN_FAILURE = 'DOWNLOAD_SN_FAILURE'

export function fetchSNCSV() {
  let params = ''

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `matrixs/sn?${params}`,
      authenticated: true,
      types: [DOWNLOAD_SN_REQUEST, DOWNLOAD_SN_SUCCESS, DOWNLOAD_SN_FAILURE]
    }
  }
}

export const MATRIX_DELETE_REQUEST = 'MATRIX_DELETE_REQUEST'
export const MATRIX_DELETE_SUCCESS = 'MATRIX_DELETE_SUCCESS'
export const MATRIX_DELETE_FAILURE = 'MATRIX_DELETE_FAILURE'

export function deleteMatrix(Serial) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `matrixs/${Serial}`,
      authenticated: true,
      types: [MATRIX_DELETE_REQUEST, MATRIX_DELETE_SUCCESS,
        MATRIX_DELETE_FAILURE]
    }
  }
}