import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router-dom'

import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap'

import { authLogout } from '../actions/auth'

class AppHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      showDragon: false,
    }

    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.showDragonGif = this.showDragonGif.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  logout() {
    this.props.authLogout()
    this.props.push('/login')
  }

  handleNavLinkClick() {
    this.setState({
      isOpen: false
    });
  }

  showDragonGif() {
    const { role, type, fullname, id } = this.props

    if (fullname === 'Daniel Souto') {
      this.setState(prevState => ({
        showDragon: !prevState.showDragon
      }));
    }

  }

  topFunction() {
    window.scrollTo(0, 0);
  }

  componentDidMount() {

    const { role, type, fullname, id } = this.props

    if (fullname === 'Joaquin Abellan') {
      this.logout();
    }

  }

  render() {
    const { role, type, fullname, id, category } = this.props

    return (
      <Navbar className="box-shadow bg-dark" dark expand="md">
        <Container>
          <NavbarBrand tag={Link} to="/">
            <img className="logo" src="/images/logo.png" alt="Logo" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto align-items-center" navbar>
              {((role === 'ADMIN' && type === 'GOD') || (role === "CUSTOMER" && category !== 'BAQUEIRA')) &&
                <NavItem>
                  <NavLink tag={Link} to="/lockersale" onClick={() => this.handleNavLinkClick()}>
                    <button className="btn btn-primary">
                      <i className="fas fa-credit-card mr-1"></i>VENDER TAQUILLA
                    </button>
                  </NavLink>
                </NavItem>
              }
              {((role === 'ADMIN' && type === 'GOD') || (role === "CUSTOMER")) &&
                <NavItem>
                  <NavLink tag={Link} to="/pin" onClick={() => this.handleNavLinkClick()}>
                    <button className="btn btn-warning">
                      <i className="fas fa-unlock-alt mr-1"></i>RECUPERAR PIN
                    </button>
                  </NavLink>
                </NavItem>
              }
              {((role === 'ADMIN' && type === 'GOD') || (role === "CUSTOMER")) &&
                <NavItem>
                  <NavLink tag={Link} to="/supportticketadd" onClick={() => this.handleNavLinkClick()}>
                    <button className="btn btn-secondary">
                      <i className="fas fa-receipt mr-1"></i>TICKET SOPORTE
                    </button>
                  </NavLink>
                </NavItem>
              }
              {((role === 'ADMIN' && type !== 'PRODUCTION') || (role === "CUSTOMER")) &&
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    CANALES DE VENTA
                  </DropdownToggle>
                  <DropdownMenu className="custom-submenu">
                    <DropdownItem tag={Link} to="/machines" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-desktop mr-1"></i>{category === 'BAQUEIRA' ? 'CONTROLADORES' : 'KIOSCOS'}
                    </DropdownItem>
                    {role === 'ADMIN' && type !== 'CASH' &&
                      <DropdownItem tag={Link} to="/qrs" onClick={() => this.handleNavLinkClick()}>
                        <i class="fas fa-qrcode mr-1"></i>QRS
                      </DropdownItem>
                    }
                    {role === 'ADMIN' && type !== 'CASH' &&
                      <DropdownItem tag={Link} to="/pdas" onClick={() => this.handleNavLinkClick()}>
                        <i class="fas fa-mobile mr-1"></i>PDAS
                      </DropdownItem>
                    }
                    {((role === 'ADMIN' && type !== 'PRODUCTION') || (role === "CUSTOMER" && category === 'BAQUEIRA')) &&
                      <DropdownItem tag={Link} to="/operators" onClick={() => this.handleNavLinkClick()}>
                        <i class="fas fa-map-signs mr-1"></i>AGREGADORES
                      </DropdownItem>
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              {((role === 'ADMIN' && type !== 'CASH') || (role === "CUSTOMER" && category === 'BAQUEIRA')) &&
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    OPERATIVA
                  </DropdownToggle>
                  <DropdownMenu className="custom-submenu">
                    {(role === 'ADMIN' && type !== 'PRODUCTION' && type !== 'LIMITED') &&
                      <DropdownItem tag={Link} to="/supporttickets" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-receipt mr-1"></i>TICKETS
                      </DropdownItem>
                    }
                    {(role === 'ADMIN' && type !== 'PRODUCTION') &&
                      <DropdownItem tag={Link} to="/faults" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-info mr-1"></i>AVERIAS
                      </DropdownItem>
                    }
                    {(role === 'ADMIN' && type !== 'PRODUCTION' && type !== 'LIMITED') &&
                      <DropdownItem tag={Link} to="/incidences" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-exclamation-triangle mr-1"></i>INCIDENCIAS
                      </DropdownItem>
                    }
                    {(role === 'ADMIN' && type !== 'PRODUCTION') &&
                      <DropdownItem tag={Link} to="/audit" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-database mr-1"></i>AUDIT
                      </DropdownItem>
                    }
                    {(role === 'ADMIN' && type !== 'PRODUCTION') &&
                      <DropdownItem tag={Link} to="/tpvs" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-mobile mr-1"></i>TPVs
                      </DropdownItem>
                    }
                    {(role === 'ADMIN') &&
                      <DropdownItem tag={Link} to="/modules" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-th mr-1"></i>MÓDULOS
                      </DropdownItem>
                    }
                    {(role === 'ADMIN' && type !== 'PRODUCTION') &&
                      <DropdownItem tag={Link} to="/matrixs" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-cubes mr-1"></i>VERSIONES
                      </DropdownItem>
                    }
                    {(role === 'ADMIN' && type !== 'PRODUCTION') &&
                      <DropdownItem tag={Link} to="/lowbluetooth" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-wifi mr-1"></i>BLUETOOTH
                      </DropdownItem>
                    }
                    {(role === 'ADMIN' && type !== 'PRODUCTION') &&
                      <DropdownItem tag={Link} to="/openings" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-door-open mr-1"></i>APERTURAS
                      </DropdownItem>
                    }
                    {(role === 'ADMIN' && type !== 'PRODUCTION') &&
                      <DropdownItem tag={Link} to="/pinrecovers" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-lock mr-1"></i>RECUPERACIÓN PINES
                      </DropdownItem>
                    }
                    {((role === 'ADMIN' && type !== 'PRODUCTION') || (role === "CUSTOMER" && category === 'BAQUEIRA')) &&
                      <DropdownItem tag={Link} to="/rentals" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-key mr-1"></i>RESERVAS
                      </DropdownItem>
                    }
                    {((role === 'ADMIN' && type !== 'PRODUCTION' && type !== 'LIMITED') || (role === "CUSTOMER" && category === 'BAQUEIRA')) &&
                      <DropdownItem tag={Link} to="/calendars" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-calendar mr-1"></i>CALENDARIOS
                      </DropdownItem>
                    }
                    {((role === 'ADMIN' && type !== 'PRODUCTION' && type !== 'LIMITED') || (role === "CUSTOMER" && category === 'BAQUEIRA')) &&
                      <DropdownItem tag={Link} to="/rules" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-pencil-ruler mr-1"></i>REGLAS DE NEGOCIO
                      </DropdownItem>
                    }
                    {((role === 'ADMIN' && type !== 'PRODUCTION' && type !== 'LIMITED') || (role === "CUSTOMER" && category === 'BAQUEIRA')) &&
                      <DropdownItem tag={Link} to="/articles" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-sitemap mr-1"></i>ARTÍCULOS
                      </DropdownItem>
                    }
                    {((role === 'ADMIN' && type !== 'PRODUCTION' && type !== 'LIMITED') || (role === "CUSTOMER" && category === 'BAQUEIRA')) &&
                      <DropdownItem tag={Link} to="/arqueosbaqueira" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-gem mr-1"></i>ARQUEOS ERP BAQUEIRA
                      </DropdownItem>
                    }
                    {/* {type !== 'PRODUCTION' &&
                      <DropdownItem tag={Link} to="/universalupdates" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-download mr-1"></i>ACTUALIZACIONES
                      </DropdownItem>
                    } */}
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              {((role === 'ADMIN' && type !== 'PRODUCTION' && type !== 'LIMITED') || (role === "CUSTOMER" && type !== "PDA")) &&
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    VENTAS
                  </DropdownToggle>
                  <DropdownMenu className="custom-submenu">
                    <DropdownItem tag={Link} to="/sales" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-filter mr-1"></i>ANÁLISIS
                    </DropdownItem>
                    {role === 'ADMIN' &&
                      <DropdownItem tag={Link} to="/internalsales" onClick={() => this.handleNavLinkClick()}>
                        <i class="fas fa-star mr-1"></i>ANÁLISIS POR TIPO
                      </DropdownItem>
                    }
                    {(role === 'ADMIN' || (role === "CUSTOMER" && type === "GROUP")) &&
                      <DropdownItem tag={Link} to="/arqueos" onClick={() => this.handleNavLinkClick()}>
                        <i class="fas fa-chart-bar mr-1"></i>GRÁFICOS
                      </DropdownItem>
                    }
                    {(role === 'ADMIN' || (role === "CUSTOMER" && type === "GROUP")) &&
                      <DropdownItem tag={Link} to="/arqueossales">
                        <i class="fas fa-chart-pie mr-1"></i>KPI
                      </DropdownItem>
                    }
                    {(role === 'ADMIN' || (role === "CUSTOMER" && category !== 'BAQUEIRA')) &&
                      <DropdownItem tag={Link} to="/withdrawals" onClick={() => this.handleNavLinkClick()}>
                        <i class="fas fa-calculator mr-1"></i>RETIROS
                      </DropdownItem>
                    }
                    {(role === 'ADMIN' || (role === "CUSTOMER" && type === "GROUP")) &&
                      <DropdownItem tag={Link} to="/ocupation" onClick={() => this.handleNavLinkClick()}>
                        <i class="fas fa-chart-bar mr-1"></i>OCUPACIÓN
                      </DropdownItem>
                    }
                    {role === 'ADMIN' &&
                      <DropdownItem tag={Link} to="/objectives" onClick={() => this.handleNavLinkClick()}>
                        <i class="fas fa-bullseye mr-1"></i>OBJETIVOS
                      </DropdownItem>
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              {(role === 'ADMIN' && type !== 'PRODUCTION' && type !== 'CASH') &&
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    ADMINISTRACIÓN
                  </DropdownToggle>
                  <DropdownMenu className="custom-submenu">
                    <DropdownItem tag={Link} to="/customers" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-crown mr-1"></i>CLIENTES
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/contracts" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-file-signature mr-1"></i>CONTRATOS
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/promoters" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-users mr-1"></i>PROMOTORES
                    </DropdownItem>
                    {type !== 'LIMITED' &&
                      <DropdownItem tag={Link} to="/replacements" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-bars mr-1"></i>RECAMBIOS
                      </DropdownItem>
                    }
                    {type === 'GOD' &&
                      <div>
                        <DropdownItem divider />
                        <DropdownItem tag={Link} to="/users" onClick={() => this.handleNavLinkClick()}>
                          <i className="fas fa-user-secret mr-1"></i>ADMINISTRADORES
                        </DropdownItem>
                      </div>
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              <div className="text-white" style={{ paddingRight: '20px', paddingLeft: '20px' }} onClick={this.showDragonGif}>
                <i className="fas fa-user mr-1"></i>
                Hola {fullname} !
              </div>
              <button className="btn btn-outline-light" onClick={this.logout}>
                <i className="fas fa-sign-out-alt mr-1"></i>
                SALIR
              </button>
              {this.state.showDragon && (
                <img className="dragon-gif" src="/images/7VE.gif" alt="Dragon GIF" />
              )}
            </Nav>
          </Collapse>
        </Container>
        <div className="topButton" onClick={this.topFunction}>^</div>
      </Navbar>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
  category: state.auth.category,
})

const mapDispatchToProps = dispatch => ({
  authLogout: bindActionCreators(authLogout, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
