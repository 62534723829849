import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Line, Doughnut, Bar, Radar } from 'react-chartjs-2'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'
import ArqueosBaqueiraList from '../components/ArqueosBaqueiraList'
import { fetchArqueosBaqueira } from '../actions/arqueosbaqueira'

class ArqueosBaqueiraView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }

  }

  componentDidMount() {
    this.props.fetchArqueosBaqueira();
  }

  // Toggle the visibility of the arqueos list
  toggleArqueos = () => {
    this.setState((prevState) => ({
      showArqueos: !prevState.showArqueos,
    }));
  };

  render() {
    const { arqueosbaqueira, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueosbaqueira || loading) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Loading..." />
        </div>
      );
    }

    return (
      <div>
        <div className="row mb-2 align-items-center">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">ARQUEOS </span>
              <span className="text-vw-dark">BAQUEIRA</span>
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <ArqueosBaqueiraList arqueosbaqueira={arqueosbaqueira} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  arqueosbaqueira: state.arqueosbaqueira.arqueosbaqueira,
  error: state.arqueos.error,
  loading: state.arqueos.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchArqueosBaqueira: bindActionCreators(fetchArqueosBaqueira, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArqueosBaqueiraView);
