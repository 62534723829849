import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { history } from '../store'

import { updateOperator, fetchOperators } from '../actions/operators'

class OperatorsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saleblockConfirmShown: false,
      operatorId: null,
      operatorBlocked: null,
    };

    this.updateSaleblock = this.updateSaleblock.bind(this)

    this.saleblockConfirmShow = this.saleblockConfirmShow.bind(this)
    this.saleblockConfirmToggle = this.saleblockConfirmToggle.bind(this)
  }

  goTo(id, e) {
    e.stopPropagation();
    history.push(`/operators/${id}`)
  }

  goToCalendar(id, e) {
    e.stopPropagation();
    history.push(`/calendars/${id}`)
  }

  goToRule(id, e) {
    e.stopPropagation();
    history.push(`/rules`)
  }

  getInvoiceName(type) {
    switch (type) {
      case 'Invoice': return 'Factura'
      case 'Note': return 'Albarán'
      case 'Free': return 'No Contable'
    }
  }

  handleSaleblockClick = (operator, e) => {
    e.stopPropagation();
    this.saleblockConfirmShow(operator._id, operator.Blocked);
  }

  updateSaleblock() {
    const { updateOperator, fetchOperators } = this.props

    let body = {};
    body.Blocked = !this.state.operatorBlocked

    updateOperator(this.state.operatorId, body)
      .then(() => {
        fetchOperators();
      })
      .catch((error) => {
        console.error(error);
      });

    this.saleblockConfirmToggle()
  }

  saleblockConfirmShow(id, blocked) {
    this.setState({
      saleblockConfirmShown: true,
      operatorId: id,
      operatorBlocked: blocked
    })
  }

  saleblockConfirmToggle() {
    this.setState({ saleblockConfirmShown: !this.state.saleblockConfirmShown })
  }

  getBusinessRules(rules) {
    if (!rules || rules.length === 0) {
      return null;
    }
  
    return rules.map((rule, index) => (
      <button
        key={rule._id}  // Add a key for each button in the array
        className={`btn mb-1 mt-1 text-white ${rule.BusinessRule.Active ? 'bg-success' : 'bg-danger'}`}
        onClick={e => this.goToRule(rule.BusinessRule._id, e)}
      >
        <i className={`fas mr-1 ${rule.BusinessRule.Active ? 'fa-check' : 'fa-ban'}`}></i>
        {rule.BusinessRule.Name}
      </button>
    ));
  }

  render() {

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Venta habilitada</th>
              <th scope="col">Calendario de precios</th>
              <th scope="col">Reglas de negocio</th>
              <th scope="col">Modelo de facturación</th>
              <th scope="col">Comisión</th>
              <th scope="col">Nif</th>
            </tr>
          </thead>
          <tbody>
            {this.props.operators.map(operator => (
              <tr key={operator._id} onClick={e => this.goTo(operator._id, e)}>
                <td>{operator.Name}</td>
                <td>
                  <div className="flex align-items-center justify-content-center">
                    <div className="mr-5">
                      {operator.Blocked ? 'NO' : 'SI'}
                    </div>
                    <button className={`btn text-white ${operator.Blocked ? 'bg-success' : 'bg-danger'}`}
                      onClick={(e) => this.handleSaleblockClick(operator, e)}>
                      <i className={`fas mr-1 ${operator.Blocked ? 'fa-check' : 'fa-ban'}`}></i>{operator.Blocked ? 'Habilitar' : 'Deshabilitar'}
                    </button>
                  </div>
                </td>
                <td>
                  <button className="btn bg-warning text-dark" onClick={e => this.goToCalendar(operator.Calendar._id, e)}>{operator.Calendar ? operator.Calendar.Name : 'None'}</button>
                </td>
                <td>{this.getBusinessRules(operator.BusinessRules)}</td>
                <td>{this.getInvoiceName(operator.InvoiceType)}</td>
                <td>{operator.Comission} %</td>
                <td>{operator.Nif}</td>
              </tr>
            )
            )}
          </tbody>
        </table>
        <div>
          <Modal isOpen={this.state.saleblockConfirmShown}
            toggle={this.saleblockConfirmToggle}>
            <ModalHeader toggle={this.saleblockConfirmToggle}>
              {this.state.operatorBlocked != null && this.state.operatorBlocked ?
                (<p className="text-center">Habilitar venta</p>) : (<p className="text-center">Deshabilitar venta</p>)
              }
            </ModalHeader>
            <ModalBody>
              {this.state.operatorBlocked != null && this.state.operatorBlocked ?
                (
                  <div className="text-center">
                    <p>¿Estás seguro de habilitar la venta del agregador?</p>
                    <br></br>
                    <p>-Este agregador podrá vender en su plataforma.</p>
                  </div>
                ) : (
                  <div className="text-center">
                    <p>¿Estás seguro de deshabilitar la venta del agregador?</p>
                    <br></br>
                    <p>-Este agregador no podrá vender en su plataforma.</p>
                  </div>
                )
              }
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <Button color="info mr-5" onClick={this.updateSaleblock}>
                Confirmar
              </Button>
              <Button color="danger" onClick={this.saleblockConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  operators: state.operators.operators,
})

const mapDispatchToProps = dispatch => ({
  updateOperator: bindActionCreators(updateOperator, dispatch),
  fetchOperators: bindActionCreators(fetchOperators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsList)


