import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Line, Doughnut, Bar, Radar } from 'react-chartjs-2'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'

import { fetchSalesArqueos } from '../actions/arqueos'
import ArqueosSalesFilterForm from '../forms/ArqueosSalesFilterForm'
import { getLockersName } from '../utils/machines'

class ArqueosSalesView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showForm: false,
      selectedType: 'Units',
    }

    this.handleTypeClick = this.handleTypeClick.bind(this);
  }

  componentDidMount() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const yearStart = new Date(currentYear, 0, 1);
    const formattedStartDate =
      `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
    const yearEnd = new Date(currentYear + 1, 0, 0);
    const formattedEndDate =
      `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;


    this.props.fetchSalesArqueos('', '', formattedStartDate, formattedEndDate, '');
  }

  calculateUnitsCustomerTotals(customer) {
    const { arqueos } = this.props;

    // Initialize an object to store monthly totals
    const monthlyTotals = {
      LOCKERBNS: Array(12).fill(0),
      LOCKERCNS: Array(12).fill(0),
      LOCKERBWS: Array(12).fill(0),
      LOCKERCWS: Array(12).fill(0),
      LOCKERBNM: Array(12).fill(0),
      LOCKERCNM: Array(12).fill(0),
      LOCKERBWM: Array(12).fill(0),
      LOCKERCWM: Array(12).fill(0),
      LOCKERBNL: Array(12).fill(0),
      LOCKERCNL: Array(12).fill(0),
      LOCKERBWL: Array(12).fill(0),
      LOCKERCWL: Array(12).fill(0),
      LOCKERBNXL: Array(12).fill(0),
      LOCKERCNXL: Array(12).fill(0),
      LOCKERBWXL: Array(12).fill(0),
      LOCKERCWXL: Array(12).fill(0),
      LOCKERBN2XL: Array(12).fill(0),
      LOCKERCN2XL: Array(12).fill(0),
      LOCKERBW2XL: Array(12).fill(0),
      LOCKERCW2XL: Array(12).fill(0),
      LOCKERBN3XL: Array(12).fill(0),
      LOCKERCN3XL: Array(12).fill(0),
      LOCKERBW3XL: Array(12).fill(0),
      LOCKERCW3XL: Array(12).fill(0),
      TUMBONAS: Array(12).fill(0),
      FLOTADORES: Array(12).fill(0),
      FLOTADORESHALFDAY: Array(12).fill(0),
      FASTPASS: Array(12).fill(0),
      LOCKERBNSManual: Array(12).fill(0),
      LOCKERCNSManual: Array(12).fill(0),
      LOCKERBWSManual: Array(12).fill(0),
      LOCKERCWSManual: Array(12).fill(0),
      LOCKERBNMManual: Array(12).fill(0),
      LOCKERCNMManual: Array(12).fill(0),
      LOCKERBWMManual: Array(12).fill(0),
      LOCKERCWMManual: Array(12).fill(0),
      LOCKERBNLManual: Array(12).fill(0),
      LOCKERCNLManual: Array(12).fill(0),
      LOCKERBWLManual: Array(12).fill(0),
      LOCKERCWLManual: Array(12).fill(0),
      LOCKERBNXLManual: Array(12).fill(0),
      LOCKERCNXLManual: Array(12).fill(0),
      LOCKERBWXLManual: Array(12).fill(0),
      LOCKERCWXLManual: Array(12).fill(0),
      LOCKERBN2XLManual: Array(12).fill(0),
      LOCKERCN2XLManual: Array(12).fill(0),
      LOCKERBW2XLManual: Array(12).fill(0),
      LOCKERCW2XLManual: Array(12).fill(0),
      LOCKERBN3XLManual: Array(12).fill(0),
      LOCKERCN3XLManual: Array(12).fill(0),
      LOCKERBW3XLManual: Array(12).fill(0),
      LOCKERCW3XLManual: Array(12).fill(0),
      TUMBONASManual: Array(12).fill(0),
      FLOTADORESManual: Array(12).fill(0),
      FLOTADORESHALFDAYManual: Array(12).fill(0),
      FASTPASSManual: Array(12).fill(0),
      productMonthTotal: Array(12).fill(0),
      productTotal: 0,
      ticketsMonthTotal: Array(12).fill(0),
      ticketsTotal: 0,
      ocupationMonthTotal: Array(12).fill(0),
      ocupationTotal: 0,
      LOCKERBNSTotal: 0,
      LOCKERCNSTotal: 0,
      LOCKERBWSTotal: 0,
      LOCKERCWSTotal: 0,
      LOCKERBNMTotal: 0,
      LOCKERCNMTotal: 0,
      LOCKERBWMTotal: 0,
      LOCKERCWMTotal: 0,
      LOCKERBNLTotal: 0,
      LOCKERCNLTotal: 0,
      LOCKERBWLTotal: 0,
      LOCKERCWLTotal: 0,
      LOCKERBNXLTotal: 0,
      LOCKERCNXLTotal: 0,
      LOCKERBWXLTotal: 0,
      LOCKERCWXLTotal: 0,
      LOCKERBN2XLTotal: 0,
      LOCKERCN2XLTotal: 0,
      LOCKERBW2XLTotal: 0,
      LOCKERCW2XLTotal: 0,
      LOCKERBN3XLTotal: 0,
      LOCKERCN3XLTotal: 0,
      LOCKERBW3XLTotal: 0,
      LOCKERCW3XLTotal: 0,
      TUMBONASTotal: 0,
      FLOTADORESTotal: 0,
      FLOTADORESHALFDAYTotal: 0,
      FASTPASSTotal: 0,
      LOCKERBNSManualTotal: 0,
      LOCKERCNSManualTotal: 0,
      LOCKERBWSManualTotal: 0,
      LOCKERCWSManualTotal: 0,
      LOCKERBNMManualTotal: 0,
      LOCKERCNMManualTotal: 0,
      LOCKERBWMManualTotal: 0,
      LOCKERCWMManualTotal: 0,
      LOCKERBNLManualTotal: 0,
      LOCKERCNLManualTotal: 0,
      LOCKERBWLManualTotal: 0,
      LOCKERCWLManualTotal: 0,
      LOCKERBNXLManualTotal: 0,
      LOCKERCNXLManualTotal: 0,
      LOCKERBWXLManualTotal: 0,
      LOCKERCWXLManualTotal: 0,
      LOCKERBN2XLManualTotal: 0,
      LOCKERCN2XLManualTotal: 0,
      LOCKERBW2XLManualTotal: 0,
      LOCKERCW2XLManualTotal: 0,
      LOCKERBN3XLManualTotal: 0,
      LOCKERCN3XLManualTotal: 0,
      LOCKERBW3XLManualTotal: 0,
      LOCKERCW3XLManualTotal: 0,
      TUMBONASManualTotal: 0,
      FLOTADORESManualTotal: 0,
      FLOTADORESHALFDAYManualTotal: 0,
      FASTPASSManualTotal: 0,
    };

    // Iterate through arqueos to find the ones belonging to the current customer
    arqueos.forEach(arqueo => {
      if (arqueo.Customer && arqueo.Customer.Fullname === customer && arqueo.Customer._id !== "620d1664db950220fabc0a41") {

        // Extract month from the timestamp
        const month = new Date(arqueo.TimeStamp).getUTCMonth();

        monthlyTotals.LOCKERBNS[month] += arqueo.Items.LOCKERBNS || 0;
        monthlyTotals.LOCKERCNS[month] += arqueo.Items.LOCKERCNS || 0;
        monthlyTotals.LOCKERBWS[month] += arqueo.Items.LOCKERBWS || 0;
        monthlyTotals.LOCKERCWS[month] += arqueo.Items.LOCKERCWS || 0;
        monthlyTotals.LOCKERBNM[month] += arqueo.Items.LOCKERBNM || 0;
        monthlyTotals.LOCKERCNM[month] += arqueo.Items.LOCKERCNM || 0;
        monthlyTotals.LOCKERBWM[month] += arqueo.Items.LOCKERBWM || 0;
        monthlyTotals.LOCKERCWM[month] += arqueo.Items.LOCKERCWM || 0;
        monthlyTotals.LOCKERBNL[month] += arqueo.Items.LOCKERBNL || 0;
        monthlyTotals.LOCKERCNL[month] += arqueo.Items.LOCKERCNL || 0;
        monthlyTotals.LOCKERBWL[month] += arqueo.Items.LOCKERBWL || 0;
        monthlyTotals.LOCKERCWL[month] += arqueo.Items.LOCKERCWL || 0;
        monthlyTotals.LOCKERBNXL[month] += arqueo.Items.LOCKERBNXL || 0;
        monthlyTotals.LOCKERCNXL[month] += arqueo.Items.LOCKERCNXL || 0;
        monthlyTotals.LOCKERBWXL[month] += arqueo.Items.LOCKERBWXL || 0;
        monthlyTotals.LOCKERCWXL[month] += arqueo.Items.LOCKERCWXL || 0;
        monthlyTotals.LOCKERBN2XL[month] += arqueo.Items.LOCKERBN2XL || 0;
        monthlyTotals.LOCKERCN2XL[month] += arqueo.Items.LOCKERCN2XL || 0;
        monthlyTotals.LOCKERBW2XL[month] += arqueo.Items.LOCKERBW2XL || 0;
        monthlyTotals.LOCKERCW2XL[month] += arqueo.Items.LOCKERCW2XL || 0;
        monthlyTotals.LOCKERBN3XL[month] += arqueo.Items.LOCKERBN3XL || 0;
        monthlyTotals.LOCKERCN3XL[month] += arqueo.Items.LOCKERCN3XL || 0;
        monthlyTotals.LOCKERBW3XL[month] += arqueo.Items.LOCKERBW3XL || 0;
        monthlyTotals.LOCKERCW3XL[month] += arqueo.Items.LOCKERCW3XL || 0;
        monthlyTotals.TUMBONAS[month] += arqueo.Items.TUMBONAS || 0;
        monthlyTotals.FLOTADORES[month] += arqueo.Items.FLOTADORES || 0;
        monthlyTotals.FLOTADORESHALFDAY[month] += arqueo.Items.FLOTADORESHALFDAY || 0;
        monthlyTotals.FASTPASS[month] += arqueo.Items.FASTPASS || 0;

        monthlyTotals.LOCKERBNSManual[month] += arqueo.Items.LOCKERBNSManual || 0;
        monthlyTotals.LOCKERCNSManual[month] += arqueo.Items.LOCKERCNSManual || 0;
        monthlyTotals.LOCKERBWSManual[month] += arqueo.Items.LOCKERBWSManual || 0;
        monthlyTotals.LOCKERCWSManual[month] += arqueo.Items.LOCKERCWSManual || 0;
        monthlyTotals.LOCKERBNMManual[month] += arqueo.Items.LOCKERBNMManual || 0;
        monthlyTotals.LOCKERCNMManual[month] += arqueo.Items.LOCKERCNMManual || 0;
        monthlyTotals.LOCKERBWMManual[month] += arqueo.Items.LOCKERBWMManual || 0;
        monthlyTotals.LOCKERCWMManual[month] += arqueo.Items.LOCKERCWMManual || 0;
        monthlyTotals.LOCKERBNLManual[month] += arqueo.Items.LOCKERBNLManual || 0;
        monthlyTotals.LOCKERCNLManual[month] += arqueo.Items.LOCKERCNLManual || 0;
        monthlyTotals.LOCKERBWLManual[month] += arqueo.Items.LOCKERBWLManual || 0;
        monthlyTotals.LOCKERCWLManual[month] += arqueo.Items.LOCKERCWLManual || 0;
        monthlyTotals.LOCKERBNXLManual[month] += arqueo.Items.LOCKERBNXLManual || 0;
        monthlyTotals.LOCKERCNXLManual[month] += arqueo.Items.LOCKERCNXLManual || 0;
        monthlyTotals.LOCKERBWXLManual[month] += arqueo.Items.LOCKERBWXLManual || 0;
        monthlyTotals.LOCKERCWXLManual[month] += arqueo.Items.LOCKERCWXLManual || 0;
        monthlyTotals.LOCKERBN2XLManual[month] += arqueo.Items.LOCKERBN2XLManual || 0;
        monthlyTotals.LOCKERCN2XLManual[month] += arqueo.Items.LOCKERCN2XLManual || 0;
        monthlyTotals.LOCKERBW2XLManual[month] += arqueo.Items.LOCKERBW2XLManual || 0;
        monthlyTotals.LOCKERCW2XLManual[month] += arqueo.Items.LOCKERCW2XLManual || 0;
        monthlyTotals.LOCKERBN3XLManual[month] += arqueo.Items.LOCKERBN3XLManual || 0;
        monthlyTotals.LOCKERCN3XLManual[month] += arqueo.Items.LOCKERCN3XLManual || 0;
        monthlyTotals.LOCKERBW3XLManual[month] += arqueo.Items.LOCKERBW3XLManual || 0;
        monthlyTotals.LOCKERCW3XLManual[month] += arqueo.Items.LOCKERCW3XLManual || 0;
        monthlyTotals.TUMBONASManual[month] += arqueo.Items.TUMBONASManual || 0;
        monthlyTotals.FLOTADORESManual[month] += arqueo.Items.FLOTADORESManual || 0;
        monthlyTotals.FLOTADORESHALFDAYManual[month] += arqueo.Items.FLOTADORESHALFDAYManual || 0;
        monthlyTotals.FASTPASSManual[month] += arqueo.Items.FASTPASSManual || 0;

        monthlyTotals.LOCKERBNSTotal += arqueo.Items.LOCKERBNS || 0;
        monthlyTotals.LOCKERCNSTotal += arqueo.Items.LOCKERCNS || 0;
        monthlyTotals.LOCKERBWSTotal += arqueo.Items.LOCKERBWS || 0;
        monthlyTotals.LOCKERCWSTotal += arqueo.Items.LOCKERCWS || 0;
        monthlyTotals.LOCKERBNMTotal += arqueo.Items.LOCKERBNM || 0;
        monthlyTotals.LOCKERCNMTotal += arqueo.Items.LOCKERCNM || 0;
        monthlyTotals.LOCKERBWMTotal += arqueo.Items.LOCKERBWM || 0;
        monthlyTotals.LOCKERCWMTotal += arqueo.Items.LOCKERCWM || 0;
        monthlyTotals.LOCKERBNLTotal += arqueo.Items.LOCKERBNL || 0;
        monthlyTotals.LOCKERCNLTotal += arqueo.Items.LOCKERCNL || 0;
        monthlyTotals.LOCKERBWLTotal += arqueo.Items.LOCKERBWL || 0;
        monthlyTotals.LOCKERCWLTotal += arqueo.Items.LOCKERCWL || 0;
        monthlyTotals.LOCKERBNXLTotal += arqueo.Items.LOCKERBNXL || 0;
        monthlyTotals.LOCKERCNXLTotal += arqueo.Items.LOCKERCNXL || 0;
        monthlyTotals.LOCKERBWXLTotal += arqueo.Items.LOCKERBWXL || 0;
        monthlyTotals.LOCKERCWXLTotal += arqueo.Items.LOCKERCWXL || 0;
        monthlyTotals.LOCKERBN2XLTotal += arqueo.Items.LOCKERBN2XL || 0;
        monthlyTotals.LOCKERCN2XLTotal += arqueo.Items.LOCKERCN2XL || 0;
        monthlyTotals.LOCKERBW2XLTotal += arqueo.Items.LOCKERBW2XL || 0;
        monthlyTotals.LOCKERCW2XLTotal += arqueo.Items.LOCKERCW2XL || 0;
        monthlyTotals.LOCKERBN3XLTotal += arqueo.Items.LOCKERBN3XL || 0;
        monthlyTotals.LOCKERCN3XLTotal += arqueo.Items.LOCKERCN3XL || 0;
        monthlyTotals.LOCKERBW3XLTotal += arqueo.Items.LOCKERBW3XL || 0;
        monthlyTotals.LOCKERCW3XLTotal += arqueo.Items.LOCKERCW3XL || 0;
        monthlyTotals.TUMBONASTotal += arqueo.Items.TUMBONAS || 0;
        monthlyTotals.FLOTADORESTotal += arqueo.Items.FLOTADORES || 0;
        monthlyTotals.FLOTADORESHALFDAYTotal += arqueo.Items.FLOTADORESHALFDAY || 0;
        monthlyTotals.FASTPASSTotal += arqueo.Items.FASTPASS || 0;


        monthlyTotals.productMonthTotal[month] +=
          (arqueo.Items.LOCKERBNS || 0) +
          (arqueo.Items.LOCKERCNS || 0) +
          (arqueo.Items.LOCKERBWS || 0) +
          (arqueo.Items.LOCKERCWS || 0) +
          (arqueo.Items.LOCKERBNM || 0) +
          (arqueo.Items.LOCKERCNM || 0) +
          (arqueo.Items.LOCKERBWM || 0) +
          (arqueo.Items.LOCKERCWM || 0) +
          (arqueo.Items.LOCKERBNL || 0) +
          (arqueo.Items.LOCKERCNL || 0) +
          (arqueo.Items.LOCKERBWL || 0) +
          (arqueo.Items.LOCKERCWL || 0) +
          (arqueo.Items.LOCKERBNXL || 0) +
          (arqueo.Items.LOCKERCNXL || 0) +
          (arqueo.Items.LOCKERBWXL || 0) +
          (arqueo.Items.LOCKERCWXL || 0) +
          (arqueo.Items.LOCKERBN2XL || 0) +
          (arqueo.Items.LOCKERCN2XL || 0) +
          (arqueo.Items.LOCKERBW2XL || 0) +
          (arqueo.Items.LOCKERCW2XL || 0) +
          (arqueo.Items.LOCKERBN3XL || 0) +
          (arqueo.Items.LOCKERCN3XL || 0) +
          (arqueo.Items.LOCKERBW3XL || 0) +
          (arqueo.Items.LOCKERCW3XL || 0) +
          (arqueo.Items.TUMBONAS || 0) +
          (arqueo.Items.FLOTADORES || 0) +
          (arqueo.Items.FLOTADORESHALFDAY || 0) +
          (arqueo.Items.FASTPASS || 0);

        monthlyTotals.productTotal +=
          (arqueo.Items.LOCKERBNS || 0) +
          (arqueo.Items.LOCKERCNS || 0) +
          (arqueo.Items.LOCKERBWS || 0) +
          (arqueo.Items.LOCKERCWS || 0) +
          (arqueo.Items.LOCKERBNM || 0) +
          (arqueo.Items.LOCKERCNM || 0) +
          (arqueo.Items.LOCKERBWM || 0) +
          (arqueo.Items.LOCKERCWM || 0) +
          (arqueo.Items.LOCKERBNL || 0) +
          (arqueo.Items.LOCKERCNL || 0) +
          (arqueo.Items.LOCKERBWL || 0) +
          (arqueo.Items.LOCKERCWL || 0) +
          (arqueo.Items.LOCKERBNXL || 0) +
          (arqueo.Items.LOCKERCNXL || 0) +
          (arqueo.Items.LOCKERBWXL || 0) +
          (arqueo.Items.LOCKERCWXL || 0) +
          (arqueo.Items.LOCKERBN2XL || 0) +
          (arqueo.Items.LOCKERCN2XL || 0) +
          (arqueo.Items.LOCKERBW2XL || 0) +
          (arqueo.Items.LOCKERCW2XL || 0) +
          (arqueo.Items.LOCKERBN3XL || 0) +
          (arqueo.Items.LOCKERCN3XL || 0) +
          (arqueo.Items.LOCKERBW3XL || 0) +
          (arqueo.Items.LOCKERCW3XL || 0) +
          (arqueo.Items.TUMBONAS || 0) +
          (arqueo.Items.FLOTADORES || 0) +
          (arqueo.Items.FLOTADORESHALFDAY || 0) +
          (arqueo.Items.FASTPASS || 0);

        monthlyTotals.ticketsMonthTotal[month] += arqueo.TotalTickets
        monthlyTotals.ticketsTotal += arqueo.TotalTickets

        monthlyTotals.ocupationMonthTotal[month] += arqueo.Ocupation
        monthlyTotals.ocupationTotal += arqueo.Ocupation

      }
    });

    return {
      customerName: customer,
      monthlyTotals,
    };
  }


  createUnitsClientsArray() {
    const { arqueos } = this.props;

    if (!arqueos) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    const customers = Array.from(new Set(arqueos.map(arqueo => arqueo.Customer && arqueo.Customer.Fullname)));
    const clientsArray = [];

    customers.forEach(customer => {
      if (customer && customer !== "VACWAY WATERPROOF SL") {
        const customerTotals = this.calculateUnitsCustomerTotals(customer);
        clientsArray.push(customerTotals);
      }
    });

    // Sort clientsArray in descending order based on productTotal
    clientsArray.sort((a, b) => b.monthlyTotals.productTotal - a.monthlyTotals.productTotal);

    return clientsArray;
  }

  renderUnitsTableBody() {
    const { arqueos, loading, error } = this.props;
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    const clientsArray = this.createUnitsClientsArray();

    const totalRow = {
      customerName: 'Todos',
      monthlyTotals: {
        productTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.productTotal, 0),
        ticketsTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.ticketsTotal, 0),
        ocupationTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.ocupationTotal, 0),
        productMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.productMonthTotal[i]), Array(12).fill(0)),
        ticketsMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.ticketsMonthTotal[i]), Array(12).fill(0)),
        ocupationMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.ocupationMonthTotal[i]), Array(12).fill(0)),

        LOCKERBNSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNSTotal, 0),
        LOCKERCNSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNSTotal, 0),
        LOCKERBWSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWSTotal, 0),
        LOCKERCWSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWSTotal, 0),
        LOCKERBNMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNMTotal, 0),
        LOCKERCNMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNMTotal, 0),
        LOCKERBWMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWMTotal, 0),
        LOCKERCWMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWMTotal, 0),
        LOCKERBNLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNLTotal, 0),
        LOCKERCNLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNLTotal, 0),
        LOCKERBWLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWLTotal, 0),
        LOCKERCWLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWLTotal, 0),
        LOCKERBNXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNXLTotal, 0),
        LOCKERCNXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNXLTotal, 0),
        LOCKERBWXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWLTotal, 0),
        LOCKERCWXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWXLTotal, 0),
        LOCKERBN2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBN2XLTotal, 0),
        LOCKERCN2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCN2XLTotal, 0),
        LOCKERBW2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBW2XLTotal, 0),
        LOCKERCW2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCW2XLTotal, 0),
        LOCKERBN3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBN3XLTotal, 0),
        LOCKERCN3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCN3XLTotal, 0),
        LOCKERBW3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBW3XLTotal, 0),
        LOCKERCW3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCW3XLTotal, 0),
        TUMBONASTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.TUMBONASTotal, 0),
        FLOTADORESTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FLOTADORESTotal, 0),
        FLOTADORESHALFDAYTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FLOTADORESHALFDAYTotal, 0),
        FASTPASSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FASTPASSTotal, 0),
        LOCKERBNSManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNSManualTotal, 0),
        LOCKERCNSManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNSManualTotal, 0),
        LOCKERBWSManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWSManualTotal, 0),
        LOCKERCWSManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWSManualTotal, 0),
        LOCKERBNMManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNMManualTotal, 0),
        LOCKERCNMManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNMManualTotal, 0),
        LOCKERBWMManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWMManualTotal, 0),
        LOCKERCWMManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWMManualTotal, 0),
        LOCKERBNLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNLManualTotal, 0),
        LOCKERCNLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNLManualTotal, 0),
        LOCKERBWLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWLManualTotal, 0),
        LOCKERCWLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWLManualTotal, 0),
        LOCKERBNXLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNXLManualTotal, 0),
        LOCKERCNXLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNXLManualTotal, 0),
        LOCKERBWXLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWXLManualTotal, 0),
        LOCKERCWXLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWXLManualTotal, 0),
        LOCKERBN2XLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBN2XLManualTotal, 0),
        LOCKERCN2XLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCN2XLManualTotal, 0),
        LOCKERBW2XLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBW2XLManualTotal, 0),
        LOCKERCW2XLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCW2XLManualTotal, 0),
        LOCKERBN3XLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBN3XLManualTotal, 0),
        LOCKERCN3XLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCN3XLManualTotal, 0),
        LOCKERBW3XLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBW3XLManualTotal, 0),
        LOCKERCW3XLManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCW3XLManualTotal, 0),
        TUMBONASManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.TUMBONASManualTotal, 0),
        FLOTADORESManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FLOTADORESManualTotal, 0),
        FLOTADORESHALFDAYManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FLOTADORESHALFDAYManualTotal, 0),
        FASTPASSManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FASTPASSManualTotal, 0),

        LOCKERBNSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNS[i]), Array(12).fill(0)),
        LOCKERCNSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNS[i]), Array(12).fill(0)),
        LOCKERBWSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWS[i]), Array(12).fill(0)),
        LOCKERCWSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWS[i]), Array(12).fill(0)),
        LOCKERBNMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNM[i]), Array(12).fill(0)),
        LOCKERCNMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNM[i]), Array(12).fill(0)),
        LOCKERBWMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWM[i]), Array(12).fill(0)),
        LOCKERCWMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWM[i]), Array(12).fill(0)),
        LOCKERBNLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNL[i]), Array(12).fill(0)),
        LOCKERCNLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNL[i]), Array(12).fill(0)),
        LOCKERBWLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWL[i]), Array(12).fill(0)),
        LOCKERCWLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWL[i]), Array(12).fill(0)),
        LOCKERBNXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNXL[i]), Array(12).fill(0)),
        LOCKERCNXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNXL[i]), Array(12).fill(0)),
        LOCKERBWXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWXL[i]), Array(12).fill(0)),
        LOCKERCWXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWXL[i]), Array(12).fill(0)),
        LOCKERBN2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBN2XL[i]), Array(12).fill(0)),
        LOCKERCN2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCN2XL[i]), Array(12).fill(0)),
        LOCKERBW2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBW2XL[i]), Array(12).fill(0)),
        LOCKERCW2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCW2XL[i]), Array(12).fill(0)),
        LOCKERBN3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBN3XL[i]), Array(12).fill(0)),
        LOCKERCN3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCN3XL[i]), Array(12).fill(0)),
        LOCKERBW3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBW3XL[i]), Array(12).fill(0)),
        LOCKERCW3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCW3XL[i]), Array(12).fill(0)),
        TUMBONASMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.TUMBONAS[i]), Array(12).fill(0)),
        FLOTADORESMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FLOTADORES[i]), Array(12).fill(0)),
        FLOTADORESHALFDAYMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FLOTADORESHALFDAY[i]), Array(12).fill(0)),
        FASTPASSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FASTPASS[i]), Array(12).fill(0)),
        LOCKERBNSManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNSManual[i]), Array(12).fill(0)),
        LOCKERCNSManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNSManual[i]), Array(12).fill(0)),
        LOCKERBWSManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWSManual[i]), Array(12).fill(0)),
        LOCKERCWSManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWSManual[i]), Array(12).fill(0)),
        LOCKERBNMManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNMManual[i]), Array(12).fill(0)),
        LOCKERCNMManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNMManual[i]), Array(12).fill(0)),
        LOCKERBWMManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWMManual[i]), Array(12).fill(0)),
        LOCKERCWMManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWMManual[i]), Array(12).fill(0)),
        LOCKERBNLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNLManual[i]), Array(12).fill(0)),
        LOCKERCNLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNLManual[i]), Array(12).fill(0)),
        LOCKERBWLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWLManual[i]), Array(12).fill(0)),
        LOCKERCWLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWLManual[i]), Array(12).fill(0)),
        LOCKERBNXLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNXLManual[i]), Array(12).fill(0)),
        LOCKERCNXLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNXLManual[i]), Array(12).fill(0)),
        LOCKERBWXLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWXLManual[i]), Array(12).fill(0)),
        LOCKERCWXLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWXLManual[i]), Array(12).fill(0)),
        LOCKERBN2XLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBN2XLManual[i]), Array(12).fill(0)),
        LOCKERCN2XLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCN2XLManual[i]), Array(12).fill(0)),
        LOCKERBW2XLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBW2XLManual[i]), Array(12).fill(0)),
        LOCKERCW2XLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCW2XLManual[i]), Array(12).fill(0)),
        LOCKERBN3XLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBN3XLManual[i]), Array(12).fill(0)),
        LOCKERCN3XLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCN3XLManual[i]), Array(12).fill(0)),
        LOCKERBW3XLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBW3XLManual[i]), Array(12).fill(0)),
        LOCKERCW3XLManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCW3XLManual[i]), Array(12).fill(0)),
        TUMBONASManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.TUMBONASManual[i]), Array(12).fill(0)),
        FLOTADORESManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FLOTADORESManual[i]), Array(12).fill(0)),
        FLOTADORESHALFDAYManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FLOTADORESHALFDAYManual[i]), Array(12).fill(0)),
        FASTPASSManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FASTPASSManual[i]), Array(12).fill(0)),
      },
    };

    return (
      <tbody>
        <tr className="thead-totals">
          <th scope="row">{totalRow.customerName}</th>
          <th>{totalRow.monthlyTotals.productTotal}u</th>
          {totalRow.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{total}u</td>
          ))}
        </tr>
        {Object.keys(totalRow.monthlyTotals).map((productKey) => {
          const totalsArray = Array.isArray(totalRow.monthlyTotals[productKey])
            ? totalRow.monthlyTotals[productKey]
            : Object.values(totalRow.monthlyTotals[productKey]);

          const total = totalsArray.reduce((sum, value) => sum + value, 0);

          if (total > 0 && productKey !== 'productTotal' && productKey !== 'ticketsTotal' && productKey !== 'ocupationTotal' && productKey !== 'productMonthTotal' && productKey !== 'ticketsMonthTotal' && productKey !== 'ocupationMonthTotal') {
            return (
              <tr key={productKey + 'TotalRow'} className={((productKey.slice(0, -10)).slice(-6)) === 'Manual' ? 'thead-manual' : ''}>
                <th scope="row">{((productKey.slice(0, -10)).slice(-6)) === 'Manual' ? `${getLockersName(productKey.slice(0, -16))} Manual` : getLockersName(productKey.slice(0, -10))}</th>
                <th>{total}u</th>
                {totalsArray.map((total, monthIndex) => (
                  <td key={monthIndex}>{total}u</td>
                ))}
              </tr>
            );
          }

          return null;
        })}
        <tr className="thead-clients">
          <th scope="row">Clientes</th>
          <th>{totalRow.monthlyTotals.ticketsTotal}</th>
          {totalRow.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
            return <td key={monthIndex}>{ticketsTotal}</td>;
          })}
        </tr>
        {/* <tr className="thead-clients">
          <th scope="row">Ocupación</th>
          <th>-</th>
          {totalRow.monthlyTotals.ocupationMonthTotal.map((ocupationTotal, monthIndex) => {
            return <td key={monthIndex}>{((totalRow.monthlyTotals.ticketsMonthTotal[monthIndex] / ocupationTotal) * 100).toFixed(2)}%</td>;
          })}
        </tr> */}
        {clientsArray.map((client, index) => (
          <React.Fragment key={index}>
            <tr className="thead-dark">
              <th scope="row">{client.customerName}</th>
              <th>{client.monthlyTotals.productTotal}u</th>
              {client.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
                <th key={monthIndex}>{total}u</th>
              ))}
            </tr>
            {Object.keys(client.monthlyTotals).map((productKey) => {
              const totalsArray = Array.isArray(client.monthlyTotals[productKey])
                ? client.monthlyTotals[productKey]
                : Object.values(client.monthlyTotals[productKey]);

              const total = totalsArray.reduce((sum, value) => sum + value, 0);

              if (total > 0 && productKey !== 'productTotal' && productKey !== 'ticketsTotal' && productKey !== 'ocupationTotal' && productKey !== 'productMonthTotal' && productKey !== 'ticketsMonthTotal' && productKey !== 'ocupationMonthTotal') {
                return (
                  <tr key={productKey + 'TotalRow'} className={(productKey.slice(-6)) === 'Manual' ? 'thead-manual' : ''}>
                    <th scope="row">{(productKey.slice(-6)) === 'Manual' ? `${getLockersName(productKey.slice(0, -6))} Manual` : getLockersName(productKey)}</th>
                    <th>{total}u</th>
                    {totalsArray.map((total, monthIndex) => (
                      <td key={monthIndex}>{total}u</td>
                    ))}
                  </tr>
                );
              }

              return null;
            })}
            <tr className="thead-clients">
              <th scope="row">Clientes</th>
              <th>{client.monthlyTotals.ticketsTotal}</th>
              {client.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
                return <td key={monthIndex}>{ticketsTotal}</td>;
              })}
            </tr>
            {/* <tr className="thead-clients">
              <th scope="row">Ocupación</th>
              <th>-</th>
              {client.monthlyTotals.ocupationMonthTotal.map((ocupationTotal, monthIndex) => {
                return <td key={monthIndex}>{((client.monthlyTotals.ticketsMonthTotal[monthIndex] / ocupationTotal) * 100).toFixed(2)}%</td>;
              })}
            </tr> */}
          </React.Fragment>
        ))}
      </tbody>
    );
  }

  calculatePriceWithoutTax(priceWithTax, taxRate) {
    // Convert tax rate from percentage to decimal
    const taxMultiplier = 1 + taxRate / 100;

    // Calculate price without tax and round to two decimal places
    const priceWithoutTax = (priceWithTax / taxMultiplier).toFixed(2);

    // Convert the string back to a number
    return parseFloat(priceWithoutTax);
  }

  calculateGrossCustomerTotals(customer) {
    const { arqueos } = this.props;

    // Initialize an object to store monthly totals
    const monthlyTotals = {
      LOCKERBNS: Array(12).fill(0),
      LOCKERCNS: Array(12).fill(0),
      LOCKERBWS: Array(12).fill(0),
      LOCKERCWS: Array(12).fill(0),
      LOCKERBNM: Array(12).fill(0),
      LOCKERCNM: Array(12).fill(0),
      LOCKERBWM: Array(12).fill(0),
      LOCKERCWM: Array(12).fill(0),
      LOCKERBNL: Array(12).fill(0),
      LOCKERCNL: Array(12).fill(0),
      LOCKERBWL: Array(12).fill(0),
      LOCKERCWL: Array(12).fill(0),
      LOCKERBNXL: Array(12).fill(0),
      LOCKERCNXL: Array(12).fill(0),
      LOCKERBWXL: Array(12).fill(0),
      LOCKERCWXL: Array(12).fill(0),
      LOCKERBN2XL: Array(12).fill(0),
      LOCKERCN2XL: Array(12).fill(0),
      LOCKERBW2XL: Array(12).fill(0),
      LOCKERCW2XL: Array(12).fill(0),
      LOCKERBN3XL: Array(12).fill(0),
      LOCKERCN3XL: Array(12).fill(0),
      LOCKERBW3XL: Array(12).fill(0),
      LOCKERCW3XL: Array(12).fill(0),
      TUMBONAS: Array(12).fill(0),
      FLOTADORES: Array(12).fill(0),
      FLOTADORESHALFDAY: Array(12).fill(0),
      FASTPASS: Array(12).fill(0),
      productMonthTotal: Array(12).fill(0),
      productTotal: 0,
      ticketsMonthTotal: Array(12).fill(0),
      ticketsTotal: 0,
      LOCKERBNSTotal: 0,
      LOCKERCNSTotal: 0,
      LOCKERBWSTotal: 0,
      LOCKERCWSTotal: 0,
      LOCKERBNMTotal: 0,
      LOCKERCNMTotal: 0,
      LOCKERBWMTotal: 0,
      LOCKERCWMTotal: 0,
      LOCKERBNLTotal: 0,
      LOCKERCNLTotal: 0,
      LOCKERBWLTotal: 0,
      LOCKERCWLTotal: 0,
      LOCKERBNXLTotal: 0,
      LOCKERCNXLTotal: 0,
      LOCKERBWXLTotal: 0,
      LOCKERCWXLTotal: 0,
      LOCKERBN2XLTotal: 0,
      LOCKERCN2XLTotal: 0,
      LOCKERBW2XLTotal: 0,
      LOCKERCW2XLTotal: 0,
      LOCKERBN3XLTotal: 0,
      LOCKERCN3XLTotal: 0,
      LOCKERBW3XLTotal: 0,
      LOCKERCW3XLTotal: 0,
      TUMBONASTotal: 0,
      FLOTADORESTotal: 0,
      FLOTADORESHALFDAYTotal: 0,
      FASTPASSTotal: 0,
    };

    // Iterate through arqueos to find the ones belonging to the current customer
    arqueos.forEach(arqueo => {
      if (arqueo.Customer && arqueo.Customer.Fullname === customer && arqueo.Customer._id !== "620d1664db950220fabc0a41") {
        // Extract month from the timestamp
        const month = new Date(arqueo.TimeStamp).getUTCMonth();

        monthlyTotals.LOCKERBNS[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNS[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWS[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWS[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBNM[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNM[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWM[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWM[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBNL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBNXL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNXL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWXL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWXL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBN2XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCN2XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBW2XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCW2XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBN3XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN3XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCN3XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN3XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBW3XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW3XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCW3XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW3XL, arqueo.Tax) || 0;
        monthlyTotals.TUMBONAS[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.TUMBONAS, arqueo.Tax) || 0;
        monthlyTotals.FLOTADORES[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORES, arqueo.Tax) || 0;
        monthlyTotals.FLOTADORESHALFDAY[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORESHALFDAY, arqueo.Tax) || 0;
        monthlyTotals.FASTPASS[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.FASTPASS, arqueo.Tax) || 0;

        monthlyTotals.LOCKERBNSTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNSTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWSTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWSTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBNMTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNMTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWMTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWMTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBNLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBNXLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNXLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWXLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWXLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBN2XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCN2XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBW2XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCW2XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBN3XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN3XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCN3XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN3XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBW3XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW3XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCW3XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW3XL, arqueo.Tax) || 0;
        monthlyTotals.TUMBONASTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.TUMBONAS, arqueo.Tax) || 0;
        monthlyTotals.FLOTADORESTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORES, arqueo.Tax) || 0;
        monthlyTotals.FLOTADORESHALFDAYTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORESHALFDAY, arqueo.Tax) || 0;
        monthlyTotals.FASTPASSTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.FASTPASS, arqueo.Tax) || 0;

        monthlyTotals.productMonthTotal[month] +=
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.TUMBONAS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORES, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORESHALFDAY, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.FASTPASS, arqueo.Tax) || 0);

        monthlyTotals.productTotal +=
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.TUMBONAS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORES, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORESHALFDAY, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.FASTPASS, arqueo.Tax) || 0);

        monthlyTotals.ticketsMonthTotal[month] += arqueo.TotalTickets
        monthlyTotals.ticketsTotal += arqueo.TotalTickets
      }
    });

    return {
      customerName: customer,
      monthlyTotals,
    };
  }


  createGrossClientsArray() {
    const { arqueos } = this.props;

    if (!arqueos) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    const customers = Array.from(new Set(arqueos.map(arqueo => arqueo.Customer && arqueo.Customer.Fullname)));
    const clientsArray = [];

    customers.forEach(customer => {
      if (customer && customer !== "VACWAY WATERPROOF SL") {
        const customerTotals = this.calculateGrossCustomerTotals(customer);
        clientsArray.push(customerTotals);
      }
    });

    // Sort clientsArray in descending order based on productTotal
    clientsArray.sort((a, b) => b.monthlyTotals.productTotal - a.monthlyTotals.productTotal);

    return clientsArray;
  }

  renderGrossTableBody() {
    const { arqueos, loading, error } = this.props;
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    const clientsArray = this.createGrossClientsArray();

    const totalRow = {
      customerName: 'Todos',
      monthlyTotals: {
        productTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.productTotal, 0),
        ticketsTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.ticketsTotal, 0),
        productMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.productMonthTotal[i]), Array(12).fill(0)),
        ticketsMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.ticketsMonthTotal[i]), Array(12).fill(0)),

        LOCKERBNSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNSTotal, 0),
        LOCKERCNSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNSTotal, 0),
        LOCKERBWSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWSTotal, 0),
        LOCKERCWSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWSTotal, 0),
        LOCKERBNMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNMTotal, 0),
        LOCKERCNMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNMTotal, 0),
        LOCKERBWMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWMTotal, 0),
        LOCKERCWMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWMTotal, 0),
        LOCKERBNLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNLTotal, 0),
        LOCKERCNLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNLTotal, 0),
        LOCKERBWLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWLTotal, 0),
        LOCKERCWLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWLTotal, 0),
        LOCKERBNXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNXLTotal, 0),
        LOCKERCNXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNXLTotal, 0),
        LOCKERBWXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWLTotal, 0),
        LOCKERCWXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWXLTotal, 0),
        LOCKERBN2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBN2XLTotal, 0),
        LOCKERCN2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCN2XLTotal, 0),
        LOCKERBW2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBW2XLTotal, 0),
        LOCKERCW2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCW2XLTotal, 0),
        LOCKERBN3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBN3XLTotal, 0),
        LOCKERCN3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCN3XLTotal, 0),
        LOCKERBW3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBW3XLTotal, 0),
        LOCKERCW3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCW3XLTotal, 0),
        TUMBONASTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.TUMBONASTotal, 0),
        FLOTADORESTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FLOTADORESTotal, 0),
        FLOTADORESHALFDAYTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FLOTADORESHALFDAYTotal, 0),
        FASTPASSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FASTPASSTotal, 0),

        LOCKERBNSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNS[i]), Array(12).fill(0)),
        LOCKERCNSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNS[i]), Array(12).fill(0)),
        LOCKERBWSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWS[i]), Array(12).fill(0)),
        LOCKERCWSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWS[i]), Array(12).fill(0)),
        LOCKERBNMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNM[i]), Array(12).fill(0)),
        LOCKERCNMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNM[i]), Array(12).fill(0)),
        LOCKERBWMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWM[i]), Array(12).fill(0)),
        LOCKERCWMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWM[i]), Array(12).fill(0)),
        LOCKERBNLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNL[i]), Array(12).fill(0)),
        LOCKERCNLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNL[i]), Array(12).fill(0)),
        LOCKERBWLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWL[i]), Array(12).fill(0)),
        LOCKERCWLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWL[i]), Array(12).fill(0)),
        LOCKERBNXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNXL[i]), Array(12).fill(0)),
        LOCKERCNXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNXL[i]), Array(12).fill(0)),
        LOCKERBWXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWXL[i]), Array(12).fill(0)),
        LOCKERCWXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWXL[i]), Array(12).fill(0)),
        LOCKERBN2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBN2XL[i]), Array(12).fill(0)),
        LOCKERCN2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCN2XL[i]), Array(12).fill(0)),
        LOCKERBW2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBW2XL[i]), Array(12).fill(0)),
        LOCKERCW2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCW2XL[i]), Array(12).fill(0)),
        LOCKERBN3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBN3XL[i]), Array(12).fill(0)),
        LOCKERCN3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCN3XL[i]), Array(12).fill(0)),
        LOCKERBW3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBW3XL[i]), Array(12).fill(0)),
        LOCKERCW3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCW3XL[i]), Array(12).fill(0)),
        TUMBONASMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.TUMBONAS[i]), Array(12).fill(0)),
        FLOTADORESMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FLOTADORES[i]), Array(12).fill(0)),
        FLOTADORESHALFDAYMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FLOTADORESHALFDAY[i]), Array(12).fill(0)),
        FASTPASSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FASTPASS[i]), Array(12).fill(0)),
      },
    };

    return (
      <tbody>
        <tr className="thead-totals">
          <th scope="row">{totalRow.customerName}</th>
          <th>{`${(totalRow.monthlyTotals.productTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        {Object.keys(totalRow.monthlyTotals).map((productKey) => {
          const totalsArray = Array.isArray(totalRow.monthlyTotals[productKey])
            ? totalRow.monthlyTotals[productKey]
            : Object.values(totalRow.monthlyTotals[productKey]);

          const total = totalsArray.reduce((sum, value) => sum + value, 0);

          if (total > 0 && productKey !== 'productTotal' && productKey !== 'ticketsTotal' && productKey !== 'productMonthTotal' && productKey !== 'ticketsMonthTotal') {
            return (
              <tr key={productKey + 'TotalRow'} >
                <th scope="row">{getLockersName(productKey.slice(0, -10))}</th>
                <th>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
                {totalsArray.map((total, monthIndex) => (
                  <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
                ))}
              </tr>
            );
          }

          return null;
        })}
        <tr className="thead-clients">
          <th scope="row">Ticket Medio</th>
          <th>{`${((totalRow.monthlyTotals.productTotal / totalRow.monthlyTotals.ticketsTotal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((productTotal, monthIndex) => {
            const ticketsTotal = totalRow.monthlyTotals.ticketsMonthTotal[monthIndex];
            const averageTicket = ticketsTotal !== 0 ? (productTotal / ticketsTotal).toFixed(2) : "N/A";

            return <td key={monthIndex}>{`${(averageTicket).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>;
          })}
        </tr>
        <tr className="thead-clients">
          <th scope="row">Clientes</th>
          <th>{totalRow.monthlyTotals.ticketsTotal}</th>
          {totalRow.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
            return <td key={monthIndex}>{ticketsTotal}</td>;
          })}
        </tr>
        {clientsArray.map((client, index) => (
          <React.Fragment key={index}>
            <tr className="thead-dark">
              <th scope="row">{client.customerName}</th>
              <th>{`${(client.monthlyTotals.productTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              {client.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
                <th key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              ))}
            </tr>
            {Object.keys(client.monthlyTotals).map((productKey) => {
              const totalsArray = Array.isArray(client.monthlyTotals[productKey])
                ? client.monthlyTotals[productKey]
                : Object.values(client.monthlyTotals[productKey]);

              const total = totalsArray.reduce((sum, value) => sum + value, 0);

              if (total > 0 && productKey !== 'productTotal' && productKey !== 'ticketsTotal' && productKey !== 'productMonthTotal' && productKey !== 'ticketsMonthTotal') {
                return (
                  <tr key={productKey + 'TotalRow'} >
                    <th scope="row">{getLockersName(productKey)}</th>
                    <th>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
                    {totalsArray.map((total, monthIndex) => (
                      <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
                    ))}
                  </tr>
                );
              }

              return null;
            })}
            <tr className="thead-clients">
              <th scope="row">Ticket Medio</th>
              <th>{`${((client.monthlyTotals.productTotal / client.monthlyTotals.ticketsTotal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              {client.monthlyTotals.productMonthTotal.map((productTotal, monthIndex) => {
                const ticketsTotal = client.monthlyTotals.ticketsMonthTotal[monthIndex];
                const averageTicket = ticketsTotal !== 0 ? (productTotal / ticketsTotal).toFixed(2) : "N/A";

                return <td key={monthIndex}>{`${(averageTicket).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>;
              })}
            </tr>
            <tr className="thead-clients">
              <th scope="row">Clientes</th>
              <th>{client.monthlyTotals.ticketsTotal}</th>
              {client.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
                return <td key={monthIndex}>{ticketsTotal}</td>;
              })}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    );
  }

  calculateActiveCustomerTotals(customer) {
    const { arqueos } = this.props;

    // Initialize an object to store monthly totals
    const monthlyTotals = {
      LOCKERBNS: Array(12).fill(0),
      LOCKERCNS: Array(12).fill(0),
      LOCKERBWS: Array(12).fill(0),
      LOCKERCWS: Array(12).fill(0),
      LOCKERBNM: Array(12).fill(0),
      LOCKERCNM: Array(12).fill(0),
      LOCKERBWM: Array(12).fill(0),
      LOCKERCWM: Array(12).fill(0),
      LOCKERBNL: Array(12).fill(0),
      LOCKERCNL: Array(12).fill(0),
      LOCKERBWL: Array(12).fill(0),
      LOCKERCWL: Array(12).fill(0),
      LOCKERBNXL: Array(12).fill(0),
      LOCKERCNXL: Array(12).fill(0),
      LOCKERBWXL: Array(12).fill(0),
      LOCKERCWXL: Array(12).fill(0),
      LOCKERBN2XL: Array(12).fill(0),
      LOCKERCN2XL: Array(12).fill(0),
      LOCKERBW2XL: Array(12).fill(0),
      LOCKERCW2XL: Array(12).fill(0),
      LOCKERBN3XL: Array(12).fill(0),
      LOCKERCN3XL: Array(12).fill(0),
      LOCKERBW3XL: Array(12).fill(0),
      LOCKERCW3XL: Array(12).fill(0),
      TUMBONAS: Array(12).fill(0),
      FLOTADORES: Array(12).fill(0),
      FLOTADORESHALFDAY: Array(12).fill(0),
      FASTPASS: Array(12).fill(0),
      productMonthTotal: Array(12).fill(0),
      productTotal: 0,
      ticketsMonthTotal: Array(12).fill(0),
      ticketsTotal: 0,
      LOCKERBNSTotal: 0,
      LOCKERCNSTotal: 0,
      LOCKERBWSTotal: 0,
      LOCKERCWSTotal: 0,
      LOCKERBNMTotal: 0,
      LOCKERCNMTotal: 0,
      LOCKERBWMTotal: 0,
      LOCKERCWMTotal: 0,
      LOCKERBNLTotal: 0,
      LOCKERCNLTotal: 0,
      LOCKERBWLTotal: 0,
      LOCKERCWLTotal: 0,
      LOCKERBNXLTotal: 0,
      LOCKERCNXLTotal: 0,
      LOCKERBWXLTotal: 0,
      LOCKERCWXLTotal: 0,
      LOCKERBN2XLTotal: 0,
      LOCKERCN2XLTotal: 0,
      LOCKERBW2XLTotal: 0,
      LOCKERCW2XLTotal: 0,
      LOCKERBN3XLTotal: 0,
      LOCKERCN3XLTotal: 0,
      LOCKERBW3XLTotal: 0,
      LOCKERCW3XLTotal: 0,
      TUMBONASTotal: 0,
      FLOTADORESTotal: 0,
      FLOTADORESHALFDAYTotal: 0,
      FASTPASSTotal: 0,
    };

    // Iterate through arqueos to find the ones belonging to the current customer
    arqueos.forEach(arqueo => {
      if (arqueo.Customer && arqueo.Customer.Fullname === customer && arqueo.Customer._id !== "620d1664db950220fabc0a41") {
        // Extract month from the timestamp
        const month = new Date(arqueo.TimeStamp).getUTCMonth();

        monthlyTotals.LOCKERBNS[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNS[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWS[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWS[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBNM[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNM[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWM[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWM[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBNL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBNXL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNXL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWXL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWXL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBN2XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCN2XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBW2XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCW2XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBN3XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN3XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCN3XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN3XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBW3XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW3XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCW3XL[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW3XL, arqueo.Tax) || 0;
        monthlyTotals.TUMBONAS[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.TUMBONAS, arqueo.Tax) || 0;
        monthlyTotals.FLOTADORES[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORES, arqueo.Tax) || 0;
        monthlyTotals.FLOTADORESHALFDAY[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORESHALFDAY, arqueo.Tax) || 0;
        monthlyTotals.FASTPASS[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.FASTPASS, arqueo.Tax) || 0;

        monthlyTotals.LOCKERBNSTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNSTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWSTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWSTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWS, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBNMTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNMTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWMTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWMTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWM, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBNLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBNXLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCNXLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBWXLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCWXLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWXL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBN2XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCN2XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBW2XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCW2XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW2XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBN3XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN3XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCN3XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN3XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERBW3XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW3XL, arqueo.Tax) || 0;
        monthlyTotals.LOCKERCW3XLTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW3XL, arqueo.Tax) || 0;
        monthlyTotals.TUMBONASTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.TUMBONAS, arqueo.Tax) || 0;
        monthlyTotals.FLOTADORESTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORES, arqueo.Tax) || 0;
        monthlyTotals.FLOTADORESHALFDAYTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORESHALFDAY, arqueo.Tax) || 0;
        monthlyTotals.FASTPASSTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.FASTPASS, arqueo.Tax) || 0;

        monthlyTotals.productMonthTotal[month] +=
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.TUMBONAS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORES, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORESHALFDAY, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.FASTPASS, arqueo.Tax) || 0);

        monthlyTotals.productTotal +=
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWM, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBNXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCNXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBWXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCWXL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW2XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBN3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCN3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERBW3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.LOCKERCW3XL, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.TUMBONAS, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORES, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.FLOTADORESHALFDAY, arqueo.Tax) || 0) +
          (this.calculatePriceWithoutTax(arqueo.ItemsPrices.FASTPASS, arqueo.Tax) || 0);

        monthlyTotals.ticketsMonthTotal[month] += arqueo.TotalTickets
        monthlyTotals.ticketsTotal += arqueo.TotalTickets
      }
    });

    return {
      customerName: customer,
      monthlyTotals,
    };
  }

  createActiveClientsArray() {
    const { arqueos } = this.props;

    if (!arqueos) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    const customers = Array.from(new Set(arqueos.map(arqueo => (arqueo.Customer && arqueo.ContractType != "SALE") && arqueo.Customer.Fullname)));
    const clientsArray = [];

    customers.forEach(customer => {
      if (customer && customer !== "VACWAY WATERPROOF SL") {
        const customerTotals = this.calculateActiveCustomerTotals(customer);
        clientsArray.push(customerTotals);
      }
    });

    // Sort clientsArray in descending order based on productTotal
    clientsArray.sort((a, b) => b.monthlyTotals.productTotal - a.monthlyTotals.productTotal);

    return clientsArray;
  }

  renderActiveTableBody() {
    const { arqueos, loading, error } = this.props;
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    const clientsArray = this.createActiveClientsArray();

    const totalRow = {
      customerName: 'Todos',
      monthlyTotals: {
        productTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.productTotal, 0),
        ticketsTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.ticketsTotal, 0),
        productMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.productMonthTotal[i]), Array(12).fill(0)),
        ticketsMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.ticketsMonthTotal[i]), Array(12).fill(0)),

        LOCKERBNSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNSTotal, 0),
        LOCKERCNSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNSTotal, 0),
        LOCKERBWSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWSTotal, 0),
        LOCKERCWSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWSTotal, 0),
        LOCKERBNMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNMTotal, 0),
        LOCKERCNMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNMTotal, 0),
        LOCKERBWMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWMTotal, 0),
        LOCKERCWMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWMTotal, 0),
        LOCKERBNLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNLTotal, 0),
        LOCKERCNLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNLTotal, 0),
        LOCKERBWLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWLTotal, 0),
        LOCKERCWLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWLTotal, 0),
        LOCKERBNXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNXLTotal, 0),
        LOCKERCNXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNXLTotal, 0),
        LOCKERBWXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWLTotal, 0),
        LOCKERCWXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWXLTotal, 0),
        LOCKERBN2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBN2XLTotal, 0),
        LOCKERCN2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCN2XLTotal, 0),
        LOCKERBW2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBW2XLTotal, 0),
        LOCKERCW2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCW2XLTotal, 0),
        LOCKERBN3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBN3XLTotal, 0),
        LOCKERCN3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCN3XLTotal, 0),
        LOCKERBW3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBW3XLTotal, 0),
        LOCKERCW3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCW3XLTotal, 0),
        TUMBONASTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.TUMBONASTotal, 0),
        FLOTADORESTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FLOTADORESTotal, 0),
        FLOTADORESHALFDAYTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FLOTADORESHALFDAYTotal, 0),
        FASTPASSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FASTPASSTotal, 0),

        LOCKERBNSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNS[i]), Array(12).fill(0)),
        LOCKERCNSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNS[i]), Array(12).fill(0)),
        LOCKERBWSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWS[i]), Array(12).fill(0)),
        LOCKERCWSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWS[i]), Array(12).fill(0)),
        LOCKERBNMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNM[i]), Array(12).fill(0)),
        LOCKERCNMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNM[i]), Array(12).fill(0)),
        LOCKERBWMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWM[i]), Array(12).fill(0)),
        LOCKERCWMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWM[i]), Array(12).fill(0)),
        LOCKERBNLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNL[i]), Array(12).fill(0)),
        LOCKERCNLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNL[i]), Array(12).fill(0)),
        LOCKERBWLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWL[i]), Array(12).fill(0)),
        LOCKERCWLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWL[i]), Array(12).fill(0)),
        LOCKERBNXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNXL[i]), Array(12).fill(0)),
        LOCKERCNXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNXL[i]), Array(12).fill(0)),
        LOCKERBWXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWXL[i]), Array(12).fill(0)),
        LOCKERCWXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWXL[i]), Array(12).fill(0)),
        LOCKERBN2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBN2XL[i]), Array(12).fill(0)),
        LOCKERCN2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCN2XL[i]), Array(12).fill(0)),
        LOCKERBW2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBW2XL[i]), Array(12).fill(0)),
        LOCKERCW2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCW2XL[i]), Array(12).fill(0)),
        LOCKERBN3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBN3XL[i]), Array(12).fill(0)),
        LOCKERCN3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCN3XL[i]), Array(12).fill(0)),
        LOCKERBW3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBW3XL[i]), Array(12).fill(0)),
        LOCKERCW3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCW3XL[i]), Array(12).fill(0)),
        TUMBONASMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.TUMBONAS[i]), Array(12).fill(0)),
        FLOTADORESMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FLOTADORES[i]), Array(12).fill(0)),
        FLOTADORESHALFDAYMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FLOTADORESHALFDAY[i]), Array(12).fill(0)),
        FASTPASSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FASTPASS[i]), Array(12).fill(0)),
      },
    };

    return (
      <tbody>
        <tr className="thead-totals">
          <th scope="row">{totalRow.customerName}</th>
          <th>{`${(totalRow.monthlyTotals.productTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        {Object.keys(totalRow.monthlyTotals).map((productKey) => {
          const totalsArray = Array.isArray(totalRow.monthlyTotals[productKey])
            ? totalRow.monthlyTotals[productKey]
            : Object.values(totalRow.monthlyTotals[productKey]);

          const total = totalsArray.reduce((sum, value) => sum + value, 0);

          if (total > 0 && productKey !== 'productTotal' && productKey !== 'ticketsTotal' && productKey !== 'productMonthTotal' && productKey !== 'ticketsMonthTotal') {
            return (
              <tr key={productKey + 'TotalRow'} >
                <th scope="row">{getLockersName(productKey.slice(0, -10))}</th>
                <th>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
                {totalsArray.map((total, monthIndex) => (
                  <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
                ))}
              </tr>
            );
          }

          return null;
        })}
        <tr className="thead-clients">
          <th scope="row">Ticket Medio</th>
          <th>{`${((totalRow.monthlyTotals.productTotal / totalRow.monthlyTotals.ticketsTotal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((productTotal, monthIndex) => {
            const ticketsTotal = totalRow.monthlyTotals.ticketsMonthTotal[monthIndex];
            const averageTicket = ticketsTotal !== 0 ? (productTotal / ticketsTotal).toFixed(2) : "N/A";

            return <td key={monthIndex}>{`${(averageTicket).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>;
          })}
        </tr>
        <tr className="thead-clients">
          <th scope="row">Clientes</th>
          <th>{totalRow.monthlyTotals.ticketsTotal}</th>
          {totalRow.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
            return <td key={monthIndex}>{ticketsTotal}</td>;
          })}
        </tr>
        {clientsArray.map((client, index) => (
          <React.Fragment key={index}>
            <tr className="thead-dark">
              <th scope="row">{client.customerName}</th>
              <th>{`${(client.monthlyTotals.productTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              {client.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
                <th key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              ))}
            </tr>
            {Object.keys(client.monthlyTotals).map((productKey) => {
              const totalsArray = Array.isArray(client.monthlyTotals[productKey])
                ? client.monthlyTotals[productKey]
                : Object.values(client.monthlyTotals[productKey]);

              const total = totalsArray.reduce((sum, value) => sum + value, 0);

              if (total > 0 && productKey !== 'productTotal' && productKey !== 'ticketsTotal' && productKey !== 'productMonthTotal' && productKey !== 'ticketsMonthTotal') {
                return (
                  <tr key={productKey + 'TotalRow'} >
                    <th scope="row">{getLockersName(productKey)}</th>
                    <th>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
                    {totalsArray.map((total, monthIndex) => (
                      <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
                    ))}
                  </tr>
                );
              }

              return null;
            })}
            <tr className="thead-clients">
              <th scope="row">Ticket Medio</th>
              <th>{`${((client.monthlyTotals.productTotal / client.monthlyTotals.ticketsTotal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              {client.monthlyTotals.productMonthTotal.map((productTotal, monthIndex) => {
                const ticketsTotal = client.monthlyTotals.ticketsMonthTotal[monthIndex];
                const averageTicket = ticketsTotal !== 0 ? (productTotal / ticketsTotal).toFixed(2) : "N/A";

                return <td key={monthIndex}>{`${(averageTicket).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>;
              })}
            </tr>
            <tr className="thead-clients">
              <th scope="row">Clientes</th>
              <th>{client.monthlyTotals.ticketsTotal}</th>
              {client.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
                return <td key={monthIndex}>{ticketsTotal}</td>;
              })}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    );
  }

  calculatePriceWithoutTaxAndComission(priceWithTax, taxRate, comissionRate) {
    // Convert tax rate from percentage to decimal
    const taxMultiplier = 1 + (taxRate / 100);

    let comissionMultiplier = 1;

    comissionRate != 0 ? comissionMultiplier = comissionRate / 100 : comissionMultiplier = 1

    // Calculate price without tax and round to two decimal places
    const priceWithoutTaxAndComission = ((priceWithTax / taxMultiplier) * (1 - comissionMultiplier)).toFixed(2);

    // Convert the string back to a number
    return parseFloat(priceWithoutTaxAndComission);
  }

  calculateNetCustomerTotals(customer) {
    const { arqueos } = this.props;

    // Initialize an object to store monthly totals
    const monthlyTotals = {
      LOCKERBNS: Array(12).fill(0),
      LOCKERCNS: Array(12).fill(0),
      LOCKERBWS: Array(12).fill(0),
      LOCKERCWS: Array(12).fill(0),
      LOCKERBNM: Array(12).fill(0),
      LOCKERCNM: Array(12).fill(0),
      LOCKERBWM: Array(12).fill(0),
      LOCKERCWM: Array(12).fill(0),
      LOCKERBNL: Array(12).fill(0),
      LOCKERCNL: Array(12).fill(0),
      LOCKERBWL: Array(12).fill(0),
      LOCKERCWL: Array(12).fill(0),
      LOCKERBNXL: Array(12).fill(0),
      LOCKERCNXL: Array(12).fill(0),
      LOCKERBWXL: Array(12).fill(0),
      LOCKERCWXL: Array(12).fill(0),
      LOCKERBN2XL: Array(12).fill(0),
      LOCKERCN2XL: Array(12).fill(0),
      LOCKERBW2XL: Array(12).fill(0),
      LOCKERCW2XL: Array(12).fill(0),
      LOCKERBN3XL: Array(12).fill(0),
      LOCKERCN3XL: Array(12).fill(0),
      LOCKERBW3XL: Array(12).fill(0),
      LOCKERCW3XL: Array(12).fill(0),
      TUMBONAS: Array(12).fill(0),
      FLOTADORES: Array(12).fill(0),
      FLOTADORESHALFDAY: Array(12).fill(0),
      FASTPASS: Array(12).fill(0),
      productMonthTotal: Array(12).fill(0),
      productTotal: 0,
      ticketsMonthTotal: Array(12).fill(0),
      ticketsTotal: 0,
      LOCKERBNSTotal: 0,
      LOCKERCNSTotal: 0,
      LOCKERBWSTotal: 0,
      LOCKERCWSTotal: 0,
      LOCKERBNMTotal: 0,
      LOCKERCNMTotal: 0,
      LOCKERBWMTotal: 0,
      LOCKERCWMTotal: 0,
      LOCKERBNLTotal: 0,
      LOCKERCNLTotal: 0,
      LOCKERBWLTotal: 0,
      LOCKERCWLTotal: 0,
      LOCKERBNXLTotal: 0,
      LOCKERCNXLTotal: 0,
      LOCKERBWXLTotal: 0,
      LOCKERCWXLTotal: 0,
      LOCKERBN2XLTotal: 0,
      LOCKERCN2XLTotal: 0,
      LOCKERBW2XLTotal: 0,
      LOCKERCW2XLTotal: 0,
      LOCKERBN3XLTotal: 0,
      LOCKERCN3XLTotal: 0,
      LOCKERBW3XLTotal: 0,
      LOCKERCW3XLTotal: 0,
      TUMBONASTotal: 0,
      FLOTADORESTotal: 0,
      FLOTADORESHALFDAYTotal: 0,
      FASTPASSTotal: 0,
      ContractType: "",
      SpaceRental: 0
    };

    // Iterate through arqueos to find the ones belonging to the current customer
    arqueos.forEach(arqueo => {
      if (arqueo.Customer && arqueo.Customer.Fullname === customer && arqueo.Customer._id !== "620d1664db950220fabc0a41") {
        // Extract month from the timestamp
        const month = new Date(arqueo.TimeStamp).getUTCMonth();

        monthlyTotals.LOCKERBNS[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNS, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNS) || 0;
        monthlyTotals.LOCKERCNS[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNS, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNS) || 0;
        monthlyTotals.LOCKERBWS[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWS, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWS) || 0;
        monthlyTotals.LOCKERCWS[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWS, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWS) || 0;
        monthlyTotals.LOCKERBNM[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNM, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNM) || 0;
        monthlyTotals.LOCKERCNM[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNM, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNM) || 0;
        monthlyTotals.LOCKERBWM[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWM, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWM) || 0;
        monthlyTotals.LOCKERCWM[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWM, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWM) || 0;
        monthlyTotals.LOCKERBNL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNL) || 0;
        monthlyTotals.LOCKERCNL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNL) || 0;
        monthlyTotals.LOCKERBWL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWL) || 0;
        monthlyTotals.LOCKERCWL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWL) || 0;
        monthlyTotals.LOCKERBNXL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNXL) || 0;
        monthlyTotals.LOCKERCNXL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNXL) || 0;
        monthlyTotals.LOCKERBWXL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWXL) || 0;
        monthlyTotals.LOCKERCWXL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWXL) || 0;
        monthlyTotals.LOCKERBN2XL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBN2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBN2XL) || 0;
        monthlyTotals.LOCKERCN2XL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCN2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCN2XL) || 0;
        monthlyTotals.LOCKERBW2XL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBW2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBW2XL) || 0;
        monthlyTotals.LOCKERCW2XL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCW2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCW2XL) || 0;
        monthlyTotals.LOCKERBN3XL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBN3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBN3XL) || 0;
        monthlyTotals.LOCKERCN3XL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCN3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCN3XL) || 0;
        monthlyTotals.LOCKERBW3XL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBW3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBW3XL) || 0;
        monthlyTotals.LOCKERCW3XL[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCW3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCW3XL) || 0;
        monthlyTotals.TUMBONAS[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.TUMBONAS, arqueo.Tax, arqueo.ItemsComissions.TUMBONAS) || 0;
        monthlyTotals.FLOTADORES[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.FLOTADORES, arqueo.Tax, arqueo.ItemsComissions.FLOTADORES) || 0;
        monthlyTotals.FLOTADORESHALFDAY[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.FLOTADORESHALFDAY, arqueo.Tax, arqueo.ItemsComissions.FLOTADORESHALFDAY) || 0;
        monthlyTotals.FASTPASS[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.FASTPASS, arqueo.Tax, arqueo.ItemsComissions.FASTPASS) || 0;

        monthlyTotals.LOCKERBNSTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNS, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNS) || 0;
        monthlyTotals.LOCKERCNSTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNS, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNS) || 0;
        monthlyTotals.LOCKERBWSTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWS, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWS) || 0;
        monthlyTotals.LOCKERCWSTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWS, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWS) || 0;
        monthlyTotals.LOCKERBNMTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNM, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNM) || 0;
        monthlyTotals.LOCKERCNMTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNM, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNM) || 0;
        monthlyTotals.LOCKERBWMTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWM, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWM) || 0;
        monthlyTotals.LOCKERCWMTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWM, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWM) || 0;
        monthlyTotals.LOCKERBNLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNL) || 0;
        monthlyTotals.LOCKERCNLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNL) || 0;
        monthlyTotals.LOCKERBWLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWL) || 0;
        monthlyTotals.LOCKERCWLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWL) || 0;
        monthlyTotals.LOCKERBNXLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNXL) || 0;
        monthlyTotals.LOCKERCNXLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNXL) || 0;
        monthlyTotals.LOCKERBWXLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWXL) || 0;
        monthlyTotals.LOCKERCWXLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWXL) || 0;
        monthlyTotals.LOCKERBN2XLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBN2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBN2XL) || 0;
        monthlyTotals.LOCKERCN2XLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCN2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCN2XL) || 0;
        monthlyTotals.LOCKERBW2XLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBW2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBW2XL) || 0;
        monthlyTotals.LOCKERCW2XLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCW2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCW2XL) || 0;
        monthlyTotals.LOCKERBN3XLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBN3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBN3XL) || 0;
        monthlyTotals.LOCKERCN3XLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCN3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCN3XL) || 0;
        monthlyTotals.LOCKERBW3XLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBW3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBW3XL) || 0;
        monthlyTotals.LOCKERCW3XLTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCW3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCW3XL) || 0;
        monthlyTotals.TUMBONASTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.TUMBONAS, arqueo.Tax, arqueo.ItemsComissions.TUMBONAS) || 0;
        monthlyTotals.FLOTADORESTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.FLOTADORES, arqueo.Tax, arqueo.ItemsComissions.FLOTADORES) || 0;
        monthlyTotals.FLOTADORESHALFDAYTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.FLOTADORESHALFDAY, arqueo.Tax, arqueo.ItemsComissions.FLOTADORESHALFDAY) || 0;
        monthlyTotals.FASTPASSTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.FASTPASS, arqueo.Tax, arqueo.ItemsComissions.FASTPASS) || 0;

        monthlyTotals.productMonthTotal[month] +=
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNS, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNS) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNS, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNS) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWS, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWS) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWS, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWS) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNM, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNM) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNM, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNM) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWM, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWM) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWM, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWM) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNXL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNXL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWXL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWXL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBN2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBN2XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCN2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCN2XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBW2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBW2XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCW2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCW2XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBN3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBN3XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCN3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCN3XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBW3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBW3XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCW3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCW3XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.TUMBONAS, arqueo.Tax, arqueo.ItemsComissions.TUMBONAS) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.FLOTADORES, arqueo.Tax, arqueo.ItemsComissions.FLOTADORES) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.FLOTADORESHALFDAY, arqueo.Tax, arqueo.ItemsComissions.FLOTADORESHALFDAY) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.FASTPASS, arqueo.Tax, arqueo.ItemsComissions.FASTPASS) || 0);

        monthlyTotals.productTotal +=
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNS, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNS) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNS, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNS) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWS, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWS) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWS, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWS) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNM, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNM) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNM, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNM) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWM, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWM) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWM, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWM) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBNXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBNXL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCNXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCNXL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBWXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBWXL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCWXL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCWXL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBN2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBN2XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCN2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCN2XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBW2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBW2XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCW2XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCW2XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBN3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBN3XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCN3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCN3XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERBW3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERBW3XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.LOCKERCW3XL, arqueo.Tax, arqueo.ItemsComissions.LOCKERCW3XL) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.TUMBONAS, arqueo.Tax, arqueo.ItemsComissions.TUMBONAS) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.FLOTADORES, arqueo.Tax, arqueo.ItemsComissions.FLOTADORES) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.FLOTADORESHALFDAY, arqueo.Tax, arqueo.ItemsComissions.FLOTADORESHALFDAY) || 0) +
          (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.FASTPASS, arqueo.Tax, arqueo.ItemsComissions.FASTPASS) || 0);

        monthlyTotals.ContractType = arqueo.ContractType || "None";
        monthlyTotals.SpaceRental = arqueo.SpaceRental || 0;
        monthlyTotals.ticketsMonthTotal[month] += arqueo.TotalTickets
        monthlyTotals.ticketsTotal += arqueo.TotalTickets
      }
    });

    return {
      customerName: customer,
      monthlyTotals,
    };
  }

  createNetClientsArray() {
    const { arqueos } = this.props;

    if (!arqueos) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    const customers = Array.from(new Set(arqueos.map(arqueo => arqueo.Customer && arqueo.Customer.Fullname)));
    const clientsArray = [];

    customers.forEach(customer => {
      if (customer && customer !== "VACWAY WATERPROOF SL") {
        const customerTotals = this.calculateNetCustomerTotals(customer);
        clientsArray.push(customerTotals);
      }
    });

    // Sort clientsArray in descending order based on productTotal
    clientsArray.sort((a, b) => b.monthlyTotals.productTotal - a.monthlyTotals.productTotal);

    return clientsArray;
  }

  renderNetTableBody() {
    const { arqueos, loading, error } = this.props;

    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    const clientsArray = this.createNetClientsArray();

    const totalRow = {
      customerName: 'Todos',
      monthlyTotals: {
        productTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.productTotal, 0),
        ticketsTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.ticketsTotal, 0),
        productMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.productMonthTotal[i]), Array(12).fill(0)),
        ticketsMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.ticketsMonthTotal[i]), Array(12).fill(0)),

        LOCKERBNSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNSTotal, 0),
        LOCKERCNSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNSTotal, 0),
        LOCKERBWSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWSTotal, 0),
        LOCKERCWSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWSTotal, 0),
        LOCKERBNMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNMTotal, 0),
        LOCKERCNMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNMTotal, 0),
        LOCKERBWMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWMTotal, 0),
        LOCKERCWMTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWMTotal, 0),
        LOCKERBNLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNLTotal, 0),
        LOCKERCNLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNLTotal, 0),
        LOCKERBWLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWLTotal, 0),
        LOCKERCWLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWLTotal, 0),
        LOCKERBNXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBNXLTotal, 0),
        LOCKERCNXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCNXLTotal, 0),
        LOCKERBWXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBWLTotal, 0),
        LOCKERCWXLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCWXLTotal, 0),
        LOCKERBN2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBN2XLTotal, 0),
        LOCKERCN2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCN2XLTotal, 0),
        LOCKERBW2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBW2XLTotal, 0),
        LOCKERCW2XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCW2XLTotal, 0),
        LOCKERBN3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBN3XLTotal, 0),
        LOCKERCN3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCN3XLTotal, 0),
        LOCKERBW3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERBW3XLTotal, 0),
        LOCKERCW3XLTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.LOCKERCW3XLTotal, 0),
        TUMBONASTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.TUMBONASTotal, 0),
        FLOTADORESTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FLOTADORESTotal, 0),
        FLOTADORESHALFDAYTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FLOTADORESHALFDAYTotal, 0),
        FASTPASSTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.FASTPASSTotal, 0),

        LOCKERBNSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNS[i]), Array(12).fill(0)),
        LOCKERCNSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNS[i]), Array(12).fill(0)),
        LOCKERBWSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWS[i]), Array(12).fill(0)),
        LOCKERCWSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWS[i]), Array(12).fill(0)),
        LOCKERBNMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNM[i]), Array(12).fill(0)),
        LOCKERCNMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNM[i]), Array(12).fill(0)),
        LOCKERBWMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWM[i]), Array(12).fill(0)),
        LOCKERCWMMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWM[i]), Array(12).fill(0)),
        LOCKERBNLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNL[i]), Array(12).fill(0)),
        LOCKERCNLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNL[i]), Array(12).fill(0)),
        LOCKERBWLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWL[i]), Array(12).fill(0)),
        LOCKERCWLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWL[i]), Array(12).fill(0)),
        LOCKERBNXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBNXL[i]), Array(12).fill(0)),
        LOCKERCNXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCNXL[i]), Array(12).fill(0)),
        LOCKERBWXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBWXL[i]), Array(12).fill(0)),
        LOCKERCWXLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCWXL[i]), Array(12).fill(0)),
        LOCKERBN2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBN2XL[i]), Array(12).fill(0)),
        LOCKERCN2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCN2XL[i]), Array(12).fill(0)),
        LOCKERBW2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBW2XL[i]), Array(12).fill(0)),
        LOCKERCW2XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCW2XL[i]), Array(12).fill(0)),
        LOCKERBN3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBN3XL[i]), Array(12).fill(0)),
        LOCKERCN3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCN3XL[i]), Array(12).fill(0)),
        LOCKERBW3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERBW3XL[i]), Array(12).fill(0)),
        LOCKERCW3XLMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.LOCKERCW3XL[i]), Array(12).fill(0)),
        TUMBONASMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.TUMBONAS[i]), Array(12).fill(0)),
        FLOTADORESMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FLOTADORES[i]), Array(12).fill(0)),
        FLOTADORESHALFDAYMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FLOTADORESHALFDAY[i]), Array(12).fill(0)),
        FASTPASSMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.FASTPASS[i]), Array(12).fill(0)),
      },
    };

    return (
      <tbody>
        <tr className="thead-totals">
          <th scope="row">{totalRow.customerName}</th>
          <th>{`${((totalRow.monthlyTotals.productTotal - (totalRow.monthlyTotals.SpaceRental ? totalRow.monthlyTotals.SpaceRental : 0))).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(monthIndex == 7 ? (total - (totalRow.monthlyTotals.SpaceRental ? totalRow.monthlyTotals.SpaceRental : 0)) : total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        {Object.keys(totalRow.monthlyTotals).map((productKey) => {
          const totalsArray = Array.isArray(totalRow.monthlyTotals[productKey])
            ? totalRow.monthlyTotals[productKey]
            : Object.values(totalRow.monthlyTotals[productKey]);

          const total = totalsArray.reduce((sum, value) => sum + value, 0);

          if (total > 0 && productKey !== 'productTotal' && productKey !== 'ticketsTotal' && productKey !== 'productMonthTotal' && productKey !== 'ticketsMonthTotal') {
            return (
              <tr key={productKey + 'TotalRow'} >
                <th scope="row">{getLockersName(productKey.slice(0, -10))}</th>
                <th>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
                {totalsArray.map((total, monthIndex) => (
                  <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
                ))}
              </tr>
            );
          }

          return null;
        })}
        <tr className="thead-clients">
          <th scope="row">Ticket Medio</th>
          <th>{`${((totalRow.monthlyTotals.productTotal / totalRow.monthlyTotals.ticketsTotal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((productTotal, monthIndex) => {
            const ticketsTotal = totalRow.monthlyTotals.ticketsMonthTotal[monthIndex];
            const averageTicket = ticketsTotal !== 0 ? (productTotal / ticketsTotal).toFixed(2) : "N/A";

            return <td key={monthIndex}>{`${(averageTicket).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>;
          })}
        </tr>
        <tr className="thead-clients">
          <th scope="row">Clientes</th>
          <th>{totalRow.monthlyTotals.ticketsTotal}</th>
          {totalRow.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
            return <td key={monthIndex}>{ticketsTotal}</td>;
          })}
        </tr>
        {clientsArray.map((client, index) => (
          <React.Fragment key={index}>
            <tr className="thead-dark">
              <th scope="row">{client.customerName}</th>
              <th>{`${((client.monthlyTotals.productTotal - client.monthlyTotals.SpaceRental)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              {client.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
                <th key={monthIndex}>{`${(monthIndex == 7 ? (total - client.monthlyTotals.SpaceRental) : total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              ))}
            </tr>
            {Object.keys(client.monthlyTotals).map((productKey) => {
              if (client && client.monthlyTotals[productKey]) {

                const totalsArray = Array.isArray(client.monthlyTotals[productKey])
                  ? client.monthlyTotals[productKey]
                  : Object.values(client.monthlyTotals[productKey]);

                const total = totalsArray.reduce((sum, value) => sum + value, 0);

                if (total > 0 && productKey !== 'productTotal' && productKey !== 'ticketsTotal' && productKey !== 'productMonthTotal' && productKey !== 'ticketsMonthTotal') {
                  return (
                    <tr key={productKey + 'TotalRow'} >
                      <th scope="row">{getLockersName(productKey)}</th>
                      <th>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
                      {totalsArray.map((total, monthIndex) => (
                        <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
                      ))}
                    </tr>
                  );
                }
              }

              return null;
            })}
            <tr>
              <th scope="row">Ticket Medio</th>
              <th>{`${((client.monthlyTotals.productTotal / client.monthlyTotals.ticketsTotal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              {client.monthlyTotals.productMonthTotal.map((productTotal, monthIndex) => {
                const ticketsTotal = client.monthlyTotals.ticketsMonthTotal[monthIndex];
                const averageTicket = ticketsTotal !== 0 ? (productTotal / ticketsTotal).toFixed(2) : "N/A";

                return <td key={monthIndex}>{`${(averageTicket).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>;
              })}
            </tr>
            <tr className="thead-clients">
              <th scope="row">Clientes</th>
              <th>{client.monthlyTotals.ticketsTotal}</th>
              {client.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
                return <td key={monthIndex}>{ticketsTotal}</td>;
              })}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    );
  }

  handleTypeClick(type) {
    this.setState({ selectedType: type });
  }

  render() {
    const { selectedType } = this.state

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              KPI DE VENTAS
            </h1>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Filtro General" icon="filter">
              <div className="row">
                <div className="col d-flex align-items-center justify-content-center">
                  <div className="btn-group mt-2 mb-2 p-2" role="group">
                    <button
                      type="button"
                      className={`btn btn-multi-line ${selectedType === 'Units' ? 'btn-primary' : 'btn-secondary'}`}
                      onClick={() => this.handleTypeClick('Units')}
                    >
                      Unidades (Temp mercado)
                    </button>
                    <button
                      type="button"
                      className={`btn btn-multi-line ${selectedType === 'Gross' ? 'btn-primary' : 'btn-secondary'}`}
                      onClick={() => this.handleTypeClick('Gross')}
                    >
                      € GMV (Temp mercado)
                    </button>
                    <button
                      type="button"
                      className={`btn btn-multi-line ${selectedType === 'Active' ? 'btn-primary' : 'btn-secondary'}`}
                      onClick={() => this.handleTypeClick('Active')}
                    >
                      € GMV (Activos Vacway)
                    </button>
                    <button
                      type="button"
                      className={`btn btn-multi-line ${selectedType === 'Net' ? 'btn-primary' : 'btn-secondary'}`}
                      onClick={() => this.handleTypeClick('Net')}
                    >
                      € Venta Neta
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8">
            <Box title="Filtro Específico" icon="filter">
              <ArqueosSalesFilterForm />
            </Box>
          </div>
        </div>

        {/* Table to display arqueo information */}
        <div className="table-responsive">
          <table className="table table-hover table-clickable">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Cliente</th>
                <th scope="col">Total</th>
                <th scope="col">Enero</th>
                <th scope="col">Febrero</th>
                <th scope="col">Marzo</th>
                <th scope="col">Abril</th>
                <th scope="col">Mayo</th>
                <th scope="col">Junio</th>
                <th scope="col">Julio</th>
                <th scope="col">Agosto</th>
                <th scope="col">Septiembre</th>
                <th scope="col">Octubre</th>
                <th scope="col">Noviembre</th>
                <th scope="col">Diciembre</th>
              </tr>
            </thead>
            {this.state.selectedType === 'Units' && this.renderUnitsTableBody()}
            {this.state.selectedType === 'Gross' && this.renderGrossTableBody()}
            {this.state.selectedType === 'Active' && this.renderActiveTableBody()}
            {this.state.selectedType === 'Net' && this.renderNetTableBody()}
          </table>
        </div>
      </div >
    )
  }
}

const mapStateToProps = state => ({
  arqueos: state.arqueos.arqueos,
  error: state.arqueos.error,
  loading: state.arqueos.loading,
  auth: state.auth,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchSalesArqueos: bindActionCreators(fetchSalesArqueos, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ArqueosSalesView)
