import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'
import { fetchRentals, addRental, fetchRental } from '../actions/rentals'
import { renderInputField, renderSelectField } from '../utils/forms'
import { fetchCalendarByOperator } from '../actions/calendars'
import { fetchArticles } from '../actions/articles'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'flag-icons/css/flag-icons.min.css'

class RemoteActionBaqueiraSeasonForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,           // Used to show a loading indicator during operations
      actionCompleted: false,   // To indicate if the reservation action was successful
      errorMessage: '',         // To hold any error messages for display
      rentals: [],              // Array to store the fetched rental data
      unavailableDates: [],     // Dates that are not available for selection
      phone: '',
      initPrice: 0,            // Holds the phone number input value
      totalPrice: 0,            // Total price calculated for the selected dates
      priceDetails: [],         // Array to store grouped price details for the selected date ranges
      discountApplied: false,   // Boolean to indicate if a discount was applied
      discountInfo: [],         // Array to store descriptions of the applied discount rules
      cancelled: false,          // To track whether the user has canceled the operation
      startDate: '',
      endDate: '',
      language: 'es', // Default language set to Spanish
      phonePrefix: '',
      phoneNumber: '',
      showDropdown: false
    }

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    this.props.fetchCalendarByOperator('66bc5611f9ae01de8277bbc6', this.props.lockerType, this.props.machine.Contract.Zone)
      .then(response => {
        console.log('Calendar response:', response);
        this.calculateTotalPrice();
      })
      .catch(error => {
        console.error('Error fetching calendar:', error);
      });
  }

  waitForRentalCompletion(actionId) {
    return new Promise((resolve, reject) => {
      const checkStatus = async () => {

        if (this.state.cancelled) {
          reject(new Error('Action cancelled by user'));
          return;
        }

        try {
          const response = await this.props.fetchRental(actionId);
          const action = response.response;

          if (action && (action.Status === 'PENDING' || action.Status === 'ERROR')) {
            resolve(action);
          } else {
            setTimeout(checkStatus, 1000); // Check status again after 1 second
          }
        } catch (error) {
          reject(error);
        }
      };

      checkStatus();
    });
  }

  submit = async (props) => {

    // Check if totalPrice is 0 before allowing submission
    if (this.state.totalPrice === 0) {
      this.setState({ errorMessage: 'No hay entrada de precio en Artículos -> Temporada para esta zona y tipo de taquilla.' });
      throw new SubmissionError({ _error: 'No hay entrada de precio en Artículos -> Temporada para esta zona y tipo de taquilla.' });
    }

    this.setState({ loading: true, actionCompleted: false, errorMessage: '' })

    // Format StartDate and FinishDate as "YYYY-MM-DD"
    props.StartDate = moment(props.StartDate).format("YYYY-MM-DD");
    props.FinishDate = moment(props.FinishDate).format("YYYY-MM-DD");

    //GENERAL

    props.Machine = this.props.machine._id
    props.LockerMatricula = this.props.lockerMatricula
    props.LockerSN = this.props.lockerSN
    props.Type = 'SEASON'
    props.Operator = '66bc5611f9ae01de8277bbc6'

    //PRICE

    props.InitAmount = this.state.initPrice
    props.BaseAmount = this.state.totalPrice / 1.21
    props.TaxAmount = this.state.totalPrice - (this.state.totalPrice / 1.21)
    props.TotalAmount = this.state.totalPrice
    props.Tax = 21

    //DISCOUNTS

    props.Discounts = [];

    //DETAILS

    props.Details = [];

    try {
      const response = await this.props.addRental(props)

      if (response.error) {
        throw new SubmissionError({
          _error: response.error.message,
          ...response.error.fields
        })
      }

      await this.waitForRentalCompletion(response.response._id)

      this.setState({ loading: false, actionCompleted: true })
      return response
    } catch (error) {
      this.setState({ loading: false, errorMessage: error.message || 'Error processing action' })
      throw error
    }
  }

  handlePhoneChange = (value) => {
    // Directly set the combined value in the form field
    this.setState({ phone: value });
    this.props.dispatch(change('RemoteActionBaqueiraSeasonForm', 'Phone', value));
  };

  handleLanguageChange = (lang) => {

    switch (lang) {
      case 'en': lang = 'gb'; break;
      case 'es': lang = 'es'; break;
      case 'ct': lang = 'es-ct'; break;
    }

    this.setState({ language: lang, showDropdown: false })
    this.props.dispatch(change('RemoteActionBaqueiraSeasonForm', 'Language', lang))
  }

  toggleDropdown = () => {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }))
  }

  handleCancel() {
    this.setState({ loading: false, cancelled: true });
  }

  calculateTotalPrice() {
    const { calendar, articles, lockerType, machine } = this.props;

    if (!articles || !machine) return;

    let totalPrice = 0;
    let startDate = new Date();
    let endDate = null; // To store the last date with a price

    // 1. Extract and sort the PriceCalendar by date
    const priceCalendar = calendar.PriceCalendar;
    if (priceCalendar.length > 0) {
      // Sort priceCalendar by date in ascending order
      const sortedPriceCalendar = priceCalendar.sort((a, b) => new Date(a.date) - new Date(b.date));
      // Get the last date in the sorted array
      endDate = new Date(sortedPriceCalendar[sortedPriceCalendar.length - 1].date);
    }

    // 2. Check for the price from Season that matches lockerType and machine.Contract.Zone
    const { Contract } = machine;
    const matchedLocker = articles.Season.find(locker =>
      locker.type === lockerType && locker.zone === Contract.Zone
    );

    if (matchedLocker) {
      totalPrice = matchedLocker.price;
    }

    // Update state with the calculated total price, grouped price details, discount information, and endDate
    this.setState({
      totalPrice,
      startDate,
      endDate, // Assign the extracted last date to endDate in the state
    });
  }

  render() {
    const { error, pristine, submitting, handleSubmit, machine, calendar } = this.props
    const { loading, actionCompleted, errorMessage, unavailableDates, totalPrice, priceDetails, language, showDropdown } = this.state

    if (loading) {
      return (
        <div className="justify-content-center">
          <span>Ejecutando reserva...</span>
          <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
        </div>
      )
    }

    if (actionCompleted) {
      return (
        <div className="text-center">
          <div className="alert alert-success d-block">Reserva registrada correctamente</div>
          <div className="alert alert-info d-block">Se a enviado un email al cliente con los datos de su reserva.</div>
          <div className="alert alert-info d-block">El día de inicio de su reserva le llegará otro email con la clave de la taquilla.</div>
        </div>
      )
    }

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-md-12 mt-2">
            <form onSubmit={handleSubmit(this.submit)} className="mb-3 mt-3">
              <div className="row">
                <div className="col col-xs-12 col-md-6 align-items-center justify-content-around">
                  <div className="row">
                    <div className="col col-xs-12 col-lg-6">
                      <label>Fecha de inicio</label>
                      <label>{new Date(this.state.startDate).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</label>
                    </div>
                    <div className="col col-xs-12 col-lg-6">
                      <label>Fecha de fin</label>
                      <label>{new Date(this.state.endDate).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</label>
                    </div>
                  </div>
                  <p className="mt-5">*La reserva de temporada coge la fecha actual como inicio y la última fecha con precios del calendario asignado como fin.</p>
                </div>
                <div className="col col-xs-12 col-md-2 align-items-center justify-content-center">
                  <div className="form-group">
                    <label className="d-block">Desglose del precio</label>
                    <div className="d-flex justify-content-between mt-3">
                      <span>Base:</span>
                      <span>{`${(this.state.totalPrice / 1.21).toFixed(2)}€`}</span> {/* Base Price */}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <span>IVA (21%):</span>
                      <span>{`${(this.state.totalPrice - this.state.totalPrice / 1.21).toFixed(2)}€`}</span> {/* Tax */}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <strong>TOTAL:</strong>
                      <strong>{`${(this.state.totalPrice).toFixed(2)}€`}</strong>
                    </div>
                  </div>
                </div>
                <div className="col col-xs-12 col-md-1 align-items-center justify-content-center">

                </div>
                <div className="col col-xs-12 col-md-3 align-items-center justify-content-center">
                  <Field name="PaymentMethod" component={renderSelectField}
                    label="Método de pago">
                    <option value="">Selecciona un método de pago...</option>
                    <option value="CASH">Efectivo</option>
                    <option value="TPV">Tarjeta</option>
                    <option value="CREDIT">Crédito</option>
                  </Field>
                  <Field name="Name" label="Nombre completo del cliente" type="text" component={renderInputField} placeholder="Nombre" />
                  <div className="form-group">
                    <label htmlFor="phonePrefix">Teléfono del cliente</label>
                    <PhoneInput
                      country="es" // default country code
                      value={this.state.phonePrefix + this.state.phoneNumber}
                      onChange={this.handlePhoneChange}
                      inputClass="form-control w-100" // style to match form
                      containerClass="phone-input"
                    />
                  </div>
                  <Field name="Email" label="Email del cliente" type="text" component={renderInputField} placeholder="Email" />
                  <div className="form-group">
                    <label>Idioma Coms Cliente (Email)</label>
                    <div
                      className="custom-select-wrapper"
                      style={{ position: 'relative', width: '100%' }}
                      ref={(el) => (this.dropdown = el)}
                    >
                      <button
                        type="button"
                        className="custom-select"
                        onClick={this.toggleDropdown}
                        style={{
                          width: '100%',
                          textAlign: 'left',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: '8px 12px',
                          border: '1px solid #ced4da',
                          borderRadius: '4px',
                          backgroundColor: 'white'
                        }}
                      >
                        <span>
                          <span className={`fi fi-${language}`} style={{ marginRight: '8px' }}></span>
                          {language === 'es' ? 'Español' : language === 'gb' ? 'English' : 'Català'}
                        </span>
                        {/*<span style={{ fontSize: '1em' }}>▼</span>*/}
                      </button>

                      {showDropdown && (
                        <ul
                          className=""
                          style={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            width: '100%',
                            border: '1px solid #ced4da',
                            borderRadius: '4px',
                            backgroundColor: 'white',
                            zIndex: 1000,
                            listStyle: 'none',
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <li
                            onClick={() => this.handleLanguageChange('es')}
                            className="dropdown-item"
                            style={{
                              padding: '8px 12px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <span className="fi fi-es" style={{ marginRight: '8px' }}></span> Español
                          </li>
                          <li
                            onClick={() => this.handleLanguageChange('en')}
                            className="dropdown-item"
                            style={{
                              padding: '8px 12px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <span className="fi fi-gb" style={{ marginRight: '8px' }}></span> English
                          </li>
                          <li
                            onClick={() => this.handleLanguageChange('ct')}
                            className="dropdown-item"
                            style={{
                              padding: '8px 12px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <span className="fi fi-es-ct" style={{ marginRight: '8px' }}></span> Català
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row align-items-center flex justify-content-center mt-5">
                <div className="col-md-12 align-items-center flex justify-content-center">
                  <button className="btn bg-vw-light text-white" type="submit" disabled={pristine || submitting}>
                    <i className="fas fa-bolt mr-1"></i>Confirmar reserva
                  </button>
                </div>
              </div>
              <div className="form-row mt-5 justify-content-center">
                {error && <div className="alert alert-danger">{error}</div>}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

RemoteActionBaqueiraSeasonForm = reduxForm({
  form: 'RemoteActionBaqueiraSeasonForm',
})(RemoteActionBaqueiraSeasonForm)

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
  rentals: state.rentals.rentals,
  formValues: state.form.RemoteActionBaqueiraSeasonForm && state.form.RemoteActionBaqueiraSeasonForm.values,
  calendar: state.calendars.calendar,
  articles: state.articles.articles,
})

const mapDispatchToProps = dispatch => ({
  addRental: bindActionCreators(addRental, dispatch),
  fetchRental: bindActionCreators(fetchRental, dispatch),
  fetchCalendarByOperator: bindActionCreators(fetchCalendarByOperator, dispatch),
  fetchArticles: bindActionCreators(fetchArticles, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoteActionBaqueiraSeasonForm)
