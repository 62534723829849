import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { history } from '../store'
import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'
import moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es'; // Import Spanish locale from date-fns

registerLocale("es", es); // Register Spanish locale

import { addRental, fetchRental, sendRentalInvoiceEmail, sendRentalCredentialsEmail, addCounterInvoiceRental } from '../actions/rentals'

class RentalDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counterinvoiceConfirmShown: false,
      emailInvoiceConfirmShown: false,
      emailCredentialsConfirmShown: false,
      newrentalConfirmShown: false,
      rentalId: null,
      startDate: new Date(),   // Start date for the new rental
      endDate: new Date(),     // End date for the new rental
      totalPrice: 0,
      initPrice: 0,           // Total price calculated for the selected dates
      priceDetails: [],        // Details of prices for each selected date
      discountApplied: false,
      originalRental: null,
    };

    this.updateCounterInvoice = this.updateCounterInvoice.bind(this)

    this.counterinvoiceConfirmShown = this.counterinvoiceConfirmShown.bind(this)
    this.counterinvoiceConfirmToggle = this.counterinvoiceConfirmToggle.bind(this)

    this.sendEmailInvoice = this.sendEmailInvoice.bind(this)

    this.emailInvoiceConfirmShown = this.emailInvoiceConfirmShown.bind(this)
    this.emailInvoiceConfirmToggle = this.emailInvoiceConfirmToggle.bind(this)

    this.sendEmailCredentials = this.sendEmailCredentials.bind(this)

    this.emailCredentialsConfirmShown = this.emailCredentialsConfirmShown.bind(this)
    this.emailCredentialsConfirmToggle = this.emailCredentialsConfirmToggle.bind(this)

    this.updateNewRental = this.updateNewRental.bind(this)

    this.newrentalConfirmShown = this.newrentalConfirmShown.bind(this)
    this.newrentalConfirmToggle = this.newrentalConfirmToggle.bind(this)
  }

  componentDidMount() {
    this.props.fetchRental(this.props.match.params.id).then((rental) => {

      console.log("Rental: ", rental.response);

      // Assuming rental object is returned from fetchRental
      const { StartDate, FinishDate } = rental.response;

      // Update state with the fetched rental dates
      this.setState({
        startDate: new Date(StartDate),
        endDate: new Date(FinishDate),
      });
    });
  }

  calculateTotalPrice = () => {
    const { startDate, endDate, originalRental } = this.state;

    if (!startDate || !endDate || !originalRental.Details) return;

    let totalPrice = 0;
    let initPrice = 0;
    let priceDetails = [];

    const start = moment(startDate);
    const end = moment(endDate);

    // Iterate through rental.Details and sum up the prices for the selected date range
    originalRental.Details.forEach(detail => {
      const detailStart = moment(detail.StartDate);
      const detailEnd = moment(detail.EndDate);

      // Check if the rental detail date range overlaps with the selected date range
      if (start.isSameOrBefore(detailEnd) && end.isSameOrAfter(detailStart)) {
        const overlapStart = moment.max(start, detailStart);
        const overlapEnd = moment.min(end, detailEnd);

        // Calculate the total days within the overlap range
        const daysInRange = overlapEnd.diff(overlapStart, 'days') + 1;

        // Add the price for these days to the total
        totalPrice += daysInRange * detail.Price;
        initPrice += daysInRange * detail.Price;

        // Store the price detail for later use
        priceDetails.push({
          startDate: overlapStart.clone(), // Convert back to ISO format
          endDate: overlapEnd.clone(),     // Convert back to ISO format
          price: detail.Price         // Use the original price for these dates
        });
      }
    });

    // Check for business rules and apply discounts
    let discountApplied = false;
    let totalDiscount = 0;
    let BusinessRules = originalRental.Discounts;

    if (BusinessRules && BusinessRules.length > 0) {
      BusinessRules.forEach(rule => {
        totalDiscount += rule.DiscountPercentage
        discountApplied = true;
      });

      totalPrice -= totalPrice * (totalDiscount / 100);

    }

    console.log("Price Details: ", priceDetails);

    // Update state with the calculated total price and price details
    this.setState({
      totalPrice,
      priceDetails,
      initPrice,
      discountApplied
    });
  };

  handleStartDateChange = (date) => {
    const { rental } = this.props;
    const rentalEnd = moment(rental.FinishDate).toDate(); // Original rental end date

    if (date <= rentalEnd) {
      this.setState({ startDate: date }, this.calculateTotalPrice);
    }
  };

  handleEndDateChange = (date) => {
    const { rental } = this.props;
    const rentalStart = moment(rental.StartDate).toDate(); // Original rental start date

    if (date >= rentalStart) {
      this.setState({ endDate: date }, this.calculateTotalPrice);
    }
  };

  updateNewRental() {
    const { addRental } = this.props;
    const { startDate, endDate, totalPrice, initPrice, priceDetails, originalRental } = this.state;

    const rentalData = {
      ...originalRental, // Copy existing rental data
      StartDate: startDate, // New start date
      FinishDate: endDate,  // New end date
      InitAmount: initPrice, // New total price
      BaseAmount: totalPrice / 1.21,
      TaxAmount: totalPrice - (totalPrice / 1.21),
      TotalAmount: totalPrice,
      Tax: 21,
      OriginalInvoice: originalRental.Code
    };

    //DETAILS

    if (priceDetails && priceDetails.length > 0) {
      // Map discount information to the required format
      rentalData.Details = priceDetails.map(details => ({
        StartDate: details.startDate,
        EndDate: details.endDate,
        Price: details.price
      }));
    } else {
      // If no discounts, either omit or set an empty array
      rentalData.Details = [];
    }

    // Delete _id from rentalData if it exists
    delete rentalData._id;

    addRental(rentalData)
      .then(() => {
        this.newrentalConfirmToggle();
        history.push('/rentals');
      })
      .catch(error => {
        console.error("Failed to add rental", error);
      });
  }

  newrentalConfirmShown() {
    this.setState({
      newrentalConfirmShown: true
    })
  }

  newrentalConfirmToggle() {
    this.setState({ newrentalConfirmShown: !this.state.newrentalConfirmShown })
  }

  handleCounterInvoiceClick = (rental, event) => {
    event.stopPropagation();

    this.setState({ originalRental: this.props.rental }, () => {
      this.counterinvoiceConfirmShown(rental);
    });
  }

  handleEmailInvoiceClick = (rental, event) => {
    event.stopPropagation();
    this.emailInvoiceConfirmShown(rental);
  }

  handleEmailCredentialsClick = (rental, event) => {
    event.stopPropagation();
    this.emailCredentialsConfirmShown(rental);
  }

  updateCounterInvoice() {
    const { addCounterInvoiceRental, rental } = this.props

    addCounterInvoiceRental(this.state.rentalId)
      .then(() => {
        this.counterinvoiceConfirmToggle();

        if (rental.Type === 'RENTAL') {
          this.newrentalConfirmToggle();
        } else {
          history.push('/rentals');
        }

      })
      .catch(error => {
        // Handle any errors if needed
        console.error("Failed to add counter invoice rental", error);
      });
  }

  counterinvoiceConfirmShown(id) {
    this.setState({
      counterinvoiceConfirmShown: true,
      rentalId: id
    })
  }

  counterinvoiceConfirmToggle() {
    this.setState({ counterinvoiceConfirmShown: !this.state.counterinvoiceConfirmShown })
  }

  sendEmailInvoice() {
    const { sendRentalInvoiceEmail } = this.props

    sendRentalInvoiceEmail(this.state.rentalId)
      .then(() => {
        this.emailInvoiceConfirmToggle();
      })
      .catch(error => {
        // Handle any errors if needed
        console.error("Failed to send invoice email", error);
      });
  }

  emailInvoiceConfirmToggle() {
    this.setState({ emailInvoiceConfirmShown: !this.state.emailInvoiceConfirmShown })
  }

  emailInvoiceConfirmShown(id) {
    this.setState({
      emailInvoiceConfirmShown: true,
      rentalId: id
    })
  }

  sendEmailCredentials() {
    const { sendRentalCredentialsEmail } = this.props

    sendRentalCredentialsEmail(this.state.rentalId)
      .then(() => {
        this.emailCredentialsConfirmToggle();
      })
      .catch(error => {
        // Handle any errors if needed
        console.error("Failed to send credentials email", error);
      });
  }

  emailCredentialsConfirmToggle() {
    this.setState({ emailCredentialsConfirmShown: !this.state.emailCredentialsConfirmShown })
  }

  emailCredentialsConfirmShown(id) {
    this.setState({
      emailCredentialsConfirmShown: true,
      rentalId: id
    })
  }

  goToRule(id, e) {
    e.stopPropagation();
    history.push(`/rules`)
  }

  getType(type) {

    let name = "";

    if (type === 'RENTAL') {
      name = "ALQUILER"
    } else if (type === 'SEASON') {
      name = "TEMPORADA"
    } else if (type === 'COLLECT') {
      name = "CLICK & COLLECT"
    } else if (type === 'WHITELIST') {
      name = "LISTAS BLANCAS"
    } else if (type === 'COUNTERINVOICE') {
      name = "CONTRA FACTURA"
    }

    return name
  }

  getColorType(type) {

    let name = "";

    if (type === 'RENTAL') {
      name = "warning"
    } else if (type === 'SEASON') {
      name = "primary"
    } else if (type === 'COLLECT') {
      name = "success"
    } else if (type === 'WHITELIST') {
      name = "secondary"
    } else if (type === 'COUNTERINVOICE') {
      name = "danger"
    }

    return name
  }

  render() {
    const { rental, error, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!rental || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">RESERVA </span>
              <span className="text-vw-dark">{rental.Code}</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
            <div className="col d-flex justify-content-center">
              <Link to={`/rentals/${rental._id}/edit`}>
                <button className="btn bg-vw-light text-white">
                  <i className="fas fa-edit"></i>
                  <span className="d-none d-sm-inline ml-1">Editar Reserva</span>
                </button>
              </Link>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                className="btn btn-secondary"
                onClick={(event) => this.handleEmailInvoiceClick(rental._id, event)}
                disabled={rental.Status === 'PRERENT'}
              >
                <i className="fas fa-receipt"></i>
                <span className="d-none d-sm-inline ml-1">Email Factura</span>
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                className="btn btn-dark"
                onClick={(event) => this.handleEmailCredentialsClick(rental._id, event)}
                disabled={rental.Status === 'CANCELED' || rental.Status === 'PRERENT' || rental.Type === 'COUNTERINVOICE' || rental.Status === 'PENDING'}
              >
                <i className="fas fa-fingerprint"></i>
                <span className="d-none d-sm-inline ml-1">Email Credenciales</span>
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                className="btn btn-danger"
                onClick={(event) => this.handleCounterInvoiceClick(rental._id, event)}
                disabled={rental.Status === 'CANCELED' || rental.Status === 'PRERENT' || rental.Type === 'COUNTERINVOICE'}
              >
                <i className="fas fa-ban"></i>
                <span className="d-none d-sm-inline ml-1">Cancelar Reserva</span>
              </button>
            </div>
          </div>
        </div>
        <Box title="Fechas" icon="calendar">
          <div className="row justify-content-around">
            <PropertyLabel name="Fecha de inicio" ml
              value={(new Date(rental.StartDate)).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} />
            <PropertyLabel name="Fecha de fin" ml
              value={(new Date(rental.FinishDate)).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} />
            <PropertyLabel name="Fecha de pago" ml
              value={(new Date(rental.TimeStamp)).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} />
          </div>
        </Box>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <Box title="Categoria" bgcolor={this.getColorType(rental.Type)} textcolor="white" icon="calendar">
              <PropertyLabel name="Tipo"
                value={this.getType(rental.Type)} textcolor="white" />
              {rental.Type === 'COLLECT' &&
                <PropertyLabel name="Código Click&Collect"
                  value={rental.ClickCode} textcolor="white" />
              }
              {rental.Type === 'WHITELIST' &&
                <PropertyLabel name="Reserva de"
                  value={rental.WhitelistName} textcolor="white" />
              }
              {(rental.Type === 'COUNTERINVOICE' || rental.OriginalInvoice) &&
                <PropertyLabel name="Reserva original"
                  value={rental.OriginalInvoice} textcolor="white" />
              }
            </Box>
            <Box bgcolor="info" textcolor="white" title="Ficha" icon="file-signature">
              <Box title="Cliente" icon="user">
                <PropertyLabel name="Nombre"
                  value={rental.Name} />
                <PropertyLabel name="Teléfono"
                  value={rental.Phone} />
                <PropertyLabel name="E-Mail"
                  value={rental.Email} />
              </Box>
              <Box title="Agregador" icon="file-signature">
                <PropertyLabel name="Nombre"
                  value={rental.Operator.Name} />
              </Box>
              <Box title="Taquilla" icon="lock">
                <PropertyLabel
                  name="Número"
                  value={rental.LockerMatricula
                    .map((matricula) => (parseInt(matricula) + rental.Machine.FirstIndex - 1))
                    .join(' , ')} // Join with a comma and space
                  style={{ whiteSpace: 'pre-wrap' }} // Preserve whitespace and line breaks
                />
                <PropertyLabel
                  name="SN"
                  value={rental.LockerSN.join(' , ')} // Join with a newline character
                  style={{ whiteSpace: 'pre-wrap' }} // Preserve whitespace and line breaks
                />
                <PropertyLabel name="Máquina"
                  value={rental.Machine.Serial} />
              </Box>
            </Box>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6">
            <Box bgcolor="secondary" textcolor="white" title="Contabilidad" icon="file-signature">
              <Box title="Pago" icon="coins">
                <PropertyLabel name="Método de pago"
                  value={rental.PaymentMethod} />
                <PropertyLabel name="Impuestos"
                  value={`${rental.Tax} %`} />
              </Box>
              <Box title="Ticket" icon="receipt">
                <PropertyLabel name="Importe sin descuento"
                  value={`${(rental.InitAmount).toFixed(2)} €`} />
                <PropertyLabel name="Importe base"
                  value={`${(rental.BaseAmount).toFixed(2)} €`} />
                <PropertyLabel name="Importe impuesto"
                  value={`${(rental.TaxAmount).toFixed(2)} €`} />
                <PropertyLabel name="Importe cobrado"
                  value={`${(rental.TotalAmount).toFixed(2)} €`} />
              </Box>
              <Box title="Detalles" icon="calendar">
                <div className="form-group text-vw-dark">
                  <label>Importe por calendario:</label>
                  {rental.Details && rental.Details.map((detail, index) => {
                    const startDate = moment(detail.StartDate);  // Parse StartDate as a moment object
                    const endDate = moment(detail.EndDate);  // Parse EndDate as a moment object
                    return (
                      <div key={index} className="d-flex justify-content-between mt-3 align-items-center">
                        <span>
                          {startDate.isSame(endDate, 'day')
                            ? startDate.format("DD/MM/YYYY")
                            : `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`}
                        </span>
                        <span>{(detail.Price).toFixed(2)} €</span>
                      </div>
                    );
                  })}
                </div>
              </Box>
              <Box title="Descuentos" icon="tags">
                <div className="form-group text-vw-dark">
                  <label>Descuentos aplicados:</label>
                  {rental.Discounts && rental.Discounts.map((info, index) => (
                    <div key={index} className="d-flex justify-content-between mt-3 align-items-center">
                      <span>Descuento {index + 1}:</span>
                      <span>{`${info.DiscountPercentage} %`}</span>
                      <button
                        key={index}  // Add a key for each button in the array
                        className="btn bg-warning"
                        onClick={e => this.goToRule(info.RuleId, e)}
                      >
                        {info.Name}
                      </button>
                    </div>
                  ))}
                </div>
              </Box>
            </Box>
          </div>
        </div>
        <div>
          <Modal isOpen={this.state.emailInvoiceConfirmShown}
            toggle={this.emailInvoiceConfirmToggle}>
            <ModalHeader toggle={this.emailInvoiceConfirmToggle}>
              Reenvio de email de factura
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de que deseas reenviar el email de factura al cliente?</p>
              <br></br>
              <p>-Confirma el email del cliente presente en la ficha de la reserva.</p>
              <br></br>
              <p>-Puedes modificar el email antes de enviar editando los datos de la reserva.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.sendEmailInvoice}>
                Enviar
              </Button>
              <Button color="secondary" onClick={this.emailInvoiceConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <div>
          <Modal isOpen={this.state.emailCredentialsConfirmShown}
            toggle={this.emailCredentialsConfirmToggle}>
            <ModalHeader toggle={this.emailCredentialsConfirmToggle}>
              Reenvio de email de credenciales
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de que deseas reenviar el email de credenciales al cliente?</p>
              <br></br>
              <p>-Confirma el email del cliente presente en la ficha de la reserva.</p>
              <br></br>
              <p>-Puedes modificar el email antes de enviar editando los datos de la reserva.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.sendEmailCredentials}>
                Enviar
              </Button>
              <Button color="secondary" onClick={this.emailCredentialsConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <div>
          <Modal isOpen={this.state.counterinvoiceConfirmShown}
            toggle={this.counterinvoiceConfirmToggle}>
            <ModalHeader toggle={this.counterinvoiceConfirmToggle}>
              Cancelación de reserva
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de que deseas cancelar esta reserva?</p>
              <br></br>
              <p>-Se generará una reserva negativa equivalente a los días e importe de la reserva actual.</p>
              <br></br>
              <p>-El controlador interpretará esta reserva como una liberación de la taquilla.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.updateCounterInvoice}>
                Confirmar
              </Button>
              <Button color="secondary" onClick={this.counterinvoiceConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <div>
          <Modal isOpen={this.state.newrentalConfirmShown}
            toggle={this.newrentalConfirmToggle}>
            <ModalHeader toggle={this.newrentalConfirmToggle}>
              Generación de reserva
            </ModalHeader>
            <ModalBody>
              <p>¿Quieres generar una nueva reserva con los días modificados?</p>
              <br></br>
              <p>-Indica el nuevo rango de fechas.</p>
              <br></br>
              <p>-Esta nueva reserva tendrá efecto contable.</p>
              <div className="row mt-5">
                <div className="col">
                  <label>Nueva fecha de inicio</label>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleStartDateChange}
                    minDate={moment(rental.StartDate).toDate()}  // Limit to original rental start date
                    maxDate={moment(rental.FinishDate).toDate()} // Limit to original rental end date
                    locale="es"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </div>

                <div className="col">
                  <label>Nueva fecha de fin</label>
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={this.handleEndDateChange}
                    minDate={moment(rental.StartDate).toDate()}  // Limit to original rental start date
                    maxDate={moment(rental.FinishDate).toDate()} // Limit to original rental end date
                    locale="es"
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="mt-5">
                <label className="d-block">Desglose del precio</label>
                {this.state.priceDetails.map((detail, index) => (
                  <div key={index} className="d-flex align-items-center justify-content-between py-2" style={{ borderBottom: '1px solid #ddd' }}>
                    <span>
                      {detail.startDate && detail.endDate
                        ? detail.startDate.isSame(detail.endDate, 'day')
                          ? detail.startDate.format("DD/MM/YYYY")
                          : `${detail.startDate.format("DD/MM/YYYY")} - ${detail.endDate.format("DD/MM/YYYY")}`
                        : 'Invalid date'}
                    </span>
                    <span>{(detail.price).toFixed(2)}€</span>
                  </div>
                ))}
                {/* Display discount information if a discount was applied */}
                {this.state.discountApplied && (
                  <div>
                    <div className="d-flex justify-content-between mt-3">
                      <strong>Importe:</strong>
                      <strong>{`${(this.state.initPrice).toFixed(2)}€`}</strong>
                    </div>
                    {rental.Discounts.map((info, index) => (
                      <div key={index} className="d-flex justify-content-between mt-3">
                        <span>Descuento {index + 1}:</span>
                        <span>{`${info.DiscountPercentage}%`}</span> {/* Display only DiscountPercentage */}
                      </div>
                    ))}
                  </div>
                )}
                <div className="d-flex justify-content-between mt-3">
                  <span>Base:</span>
                  <span>{`${(this.state.totalPrice / 1.21).toFixed(2)}€`}</span> {/* Base Price */}
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <span>IVA (21%):</span>
                  <span>{`${(this.state.totalPrice - this.state.totalPrice / 1.21).toFixed(2)}€`}</span> {/* Tax */}
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <strong>TOTAL:</strong>
                  <strong>{`${(this.state.totalPrice).toFixed(2)}€`}</strong>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.updateNewRental} disabled={this.state.totalPrice === 0}>
                Confirmar
              </Button>
              <Button color="secondary" onClick={this.newrentalConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  rental: state.rentals.rental,
  loading: state.rentals.loading,
  error: state.rentals.error,
  calendar: state.calendars.calendar,
})

const mapDispatchToProps = dispatch => ({
  fetchRental: bindActionCreators(fetchRental, dispatch),
  sendRentalInvoiceEmail: bindActionCreators(sendRentalInvoiceEmail, dispatch),
  sendRentalCredentialsEmail: bindActionCreators(sendRentalCredentialsEmail, dispatch),
  addCounterInvoiceRental: bindActionCreators(addCounterInvoiceRental, dispatch),
  addRental: bindActionCreators(addRental, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RentalDetailsView)
