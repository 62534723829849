import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropertyLabel from '../components/PropertyLabel'
import { connect } from 'react-redux'
import Box from '../components/Box'
import { history } from '../store'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import RemoteActionSingleAddForm from '../forms/RemoteActionSingleAddForm'
import RemoteActionFaultAddForm from '../forms/RemoteActionFaultAddForm'
import RemoteActionFaultRepairForm from '../forms/RemoteActionFaultRepairForm'

class MatrixConstructorAdmin extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      matrixModalOpen: false,
      matrixMatricula: '',
      firstIndex: 1,
      lastIndex: 70,
      currentView: 'main',
      matrixSN: '',
      lockerType: '',
      blockType: null,
      blockedLockers: []
    }

    this.openMatrixDialog = this.openMatrixDialog.bind(this)
    this.closeMatrixDialog = this.closeMatrixDialog.bind(this)
    this.changeView = this.changeView.bind(this)
  }

  goTo(Code, e) {
    history.push(`/modules/${Code}`)
  }

  openMatrixDialog(matricula, sn, type, block, e) {
    this.setState({ matrixModalOpen: true, matrixMatricula: matricula, currentView: 'main', matrixSN: sn, lockerType: type, blockType: block })
  }

  closeMatrixDialog() {
    this.setState({ matrixModalOpen: false })
  }

  getErrorCode(error) {

    switch (error) {
      case '0x00': return 'OK'
      case '0xFF': return 'INITIALIZED'
      case '0x01': return 'CONNECTED'
      case '0x02': return 'ESP32 PROCESS SUCC'
      case '0x03': return 'USER PIN ASSIGNED'
      case '0x04': return 'CONN FAULT'
      case '0x05': return 'SCAN TIMEOUT'
      case '0x06': return 'GEN ERROR 1'
      case '0x07': return 'ESP32 PROCESS UNSUCC'
      case '0x08': return 'GEN ERROR 2'
      case '0x09': return 'GEN ERROR 3'
      case '0x0A': return 'GEN ERROR 4'
      case '0x0B': return 'GEN ERROR 5'
      case '0x0C': return 'USER PIN UNASSIGNED'
      default: return error
    }
  }

  getColorClass(rssi) {
    if (rssi >= -60 && rssi !== 0) {
      return 'HIGHTOP';
    } else if (rssi < -60 && rssi >= -65) {
      return 'HIGH';
    } else if (rssi < -65 && rssi >= -70) {
      return 'HIGHLOW';
    } else if (rssi < -70 && rssi >= -75) {
      return 'MIDHIGH';
    } else if (rssi < -75 && rssi >= -80) {
      return 'MID';
    } else if (rssi < -80 && rssi >= -85) {
      return 'MIDLOW';
    } else if (rssi < -85 && rssi >= -90) {
      return 'LOWHIGH';
    } else if (rssi < -90 && rssi >= -95) {
      return 'LOW';
    } else if (rssi < -95 && rssi >= -100) {
      return 'LOWTOP';
    } else {
      return 'Null';
    }
  }

  changeView(view) {
    this.setState({ currentView: view });
  }

  infoMatrix() {
    const { role, type, category, refreshMatrix } = this.props;
    const { currentView } = this.state;

    let checkMatricula = "";
    let checkBLEName = "";
    let checkHobbit = false;
    let checkBateria = "";
    let checkPin = "";
    let checkPhone = "";
    let checkModule = "";
    let checkPass = "";
    let checkAdmin = "";
    let checkType = "";
    let checkPosition = "";
    let checkStatus = "";
    let checkWide = "";
    let checkMode = "";
    let checkConfigured = false;
    let checkLastNightscan = "";
    let checkRssi = 0;
    let checkGender = '';
    let checkFaultCode = '';
    let checkErrorCode = '';
    let kerongVersion = '';
    let passOpens = 0;
    let blockType = 0;

    {
      this.props.matrixs.map(matrix => {
        {
          (matrix.Serial === this.props.machine.Serial) && matrix.Locker.map((lock, index) => {

            if (index + 1 == this.state.matrixMatricula) {
              checkMatricula = lock.Matricula || checkMatricula;
              checkType = lock.Type || checkType;
              checkBLEName = lock.BLEName || checkBLEName;
              checkBateria = lock.Bateria || checkBateria;
              checkPin = lock.Pin || checkPin;
              checkPhone = lock.Phone || checkPhone;
              checkModule = lock.ModuleSN || checkModule;
              checkPass = lock.Pass || checkPass;
              checkAdmin = lock.PasswordCash || checkAdmin;
              checkPosition = lock.Position || checkPosition;
              checkStatus = lock.Status || checkStatus;
              checkWide = lock.wide || checkWide;
              checkMode = lock.Mode || checkMode;
              checkConfigured = lock.Configured || checkConfigured;
              checkLastNightscan = lock.LastNightScanCompleted || checkLastNightscan;
              checkRssi = lock.Rssi || checkRssi;
              checkGender = lock.Gender || checkGender;
              checkFaultCode = lock.FaultCode || checkFaultCode;
              checkErrorCode = lock.ErrorBle || checkErrorCode;
              kerongVersion = lock.KerongVersion || kerongVersion;
              passOpens = lock.PassOpens || passOpens;
              blockType = lock.BlockType || blockType;
            }
          })
        }
      })
    }

    const year = parseInt(checkLastNightscan.slice(0, 4));
    const month = parseInt(checkLastNightscan.slice(4, 6)) - 1; // month is zero-indexed
    const day = parseInt(checkLastNightscan.slice(6, 8));
    const hour = parseInt(checkLastNightscan.slice(8, 10));
    const minute = parseInt(checkLastNightscan.slice(10, 12));
    const second = parseInt(checkLastNightscan.slice(12, 14));

    const nightscanDate = new Date(year, month, day, hour, minute, second);

    nightscanDate.setHours(nightscanDate.getHours());
    const formattedDate = `${nightscanDate.getDate()}/${nightscanDate.getMonth() + 1}/${nightscanDate.getFullYear()}`;
    const formattedTime = `${nightscanDate.getHours().toString().padStart(2, '0')}:${nightscanDate.getMinutes().toString().padStart(2, '0')}`;

    const totalBattery = 6500; // Change this to the maximum battery value
    const minBattery = 4400; // Change this to the maximum battery value

    // Calculate the battery percentage
    const batteryPercentage = ((checkBateria - minBattery) / (totalBattery - minBattery)) * 100;

    // Determine the number of squares based on battery level
    let squaresCount;
    let batteryColor;

    if (batteryPercentage >= 83.3) {
      squaresCount = 5;
      batteryColor = 'green';
    } else if (batteryPercentage >= 66.7) {
      squaresCount = 4;
      batteryColor = 'green';
    } else if (batteryPercentage >= 50) {
      squaresCount = 3;
      batteryColor = 'orange';
    } else if (batteryPercentage >= 33.3) {
      squaresCount = 2;
      batteryColor = 'orange';
    } else if (batteryPercentage >= 16.7) {
      squaresCount = 1;
      batteryColor = 'red';
    } else {
      squaresCount = 0;
      batteryColor = 'red';
    }

    if (currentView === 'main') {
      return (

        <CSSTransition key="main" timeout={300} classNames="fade">
          <div>
            <div className="text-break text-monospace">
              <div className="row">
                <div className="col-xs-12 col-md-4">
                  <Box title="Cliente" icon="user" bgcolor="info" textcolor="white">
                    <PropertyLabel name="Teléfono"
                      value={checkPhone} textcolor="white" />
                    <PropertyLabel name="Pin Cliente"
                      value={checkPass} textcolor="white" />
                    <PropertyLabel name="Pin Universal"
                      value={checkAdmin} textcolor="white" />
                  </Box>
                  <Box title="Taquilla" icon="door-closed">
                    <PropertyLabel name="Matrícula"
                      value={checkMatricula} />
                    <PropertyLabel name="BLEName"
                      value={checkBLEName} />
                    <PropertyLabel name="Pin de Pairing"
                      value={checkPin} />
                    <PropertyLabel name="Estado"
                      value={checkStatus} />
                    <PropertyLabel name="Tipo"
                      value={checkType} />
                    <PropertyLabel name="Género"
                      value={checkGender} />
                    <PropertyLabel name="Versión Kerong"
                      value={kerongVersion} />
                    <PropertyLabel name="Whitelist"
                      value={blockType} />
                    <PropertyLabel name="Módulo"
                      value={
                        <button
                          className="btn bg-primary text-white"
                          onClick={(e) => this.goTo(checkModule, e)}
                        >
                          <span className="ml-1">{checkModule}</span>
                        </button>
                      } />
                  </Box>
                </div>
                <div className="col-xs-12 col-md-4">
                  <Box title="Potencia (Mejor a Peor)" icon="wifi">
                    <div className="rssi-bar">
                      {['HIGHTOP', 'HIGH', 'HIGHLOW', 'MIDHIGH', 'MID', 'MIDLOW', 'LOWHIGH', 'LOW', 'LOWTOP'].map((color) => (
                        <div key={color} id={`${color}`} className={`rssi-square ${color === this.getColorClass(checkRssi) ? 'highlighted' : ''}`}>
                          {color === this.getColorClass(checkRssi) && <h5>{checkRssi}</h5>}
                        </div>
                      ))}
                    </div>
                  </Box>
                  <Box title="Bateria" icon="battery-full">
                    <div className="battery-indicator">
                      <div className="battery-info">
                        <div className="battery-percentage">{batteryPercentage.toFixed(1)} %</div>
                      </div>
                      <div className={`battery-shape`}>
                        {Array.from({ length: squaresCount }, (_, index) => (
                          <div key={index} className={`battery-square ${batteryColor}`}></div>
                        ))}
                      </div>
                      <div className="battery-info">
                        <div className="battery-number">{(checkBateria / 1000).toFixed(1)} V</div>
                      </div>
                    </div>
                  </Box>
                  <Box title="Nightscan" icon="cubes">
                    <PropertyLabel name="Last Night Scan Date"
                      value={formattedDate} />
                    <PropertyLabel name="Last Night Scan Hour"
                      value={formattedTime} />
                  </Box>
                  <Box title="Errores" icon="exclamation-triangle" bgcolor="danger" textcolor="white">
                    <PropertyLabel name="Avería"
                      value={checkFaultCode} textcolor="white" />
                    <PropertyLabel name="Código Error"
                      value={this.getErrorCode(checkErrorCode)} textcolor="white" />
                  </Box>
                </div>
                <div className="col-xs-12 col-md-4">
                  {(role === 'ADMIN' && (type === 'GOD' || type === 'PROGRAMMER')) &&
                    <Box title="Acciones Remotas" icon="hand-paper">
                      <RemoteActionSingleAddForm machine={this.props.machine} lockerMatricula={this.state.matrixMatricula} closeMatrixDialog={this.closeMatrixDialog} />
                      <Button
                        color={`${checkStatus !== 'FL' ? 'danger' : 'success'}`}
                        className={`mt-2 mb-2 d-block w-100`}
                        onClick={() => this.changeView(checkStatus !== 'FL' ? 'faultView' : 'repairFaultView')}
                      >
                        <i className="fas fa-times d-block ml-auto mr-auto mb-2"></i>
                        {checkStatus !== 'FL' ? 'MARCAR AVERÍA' : 'REPARAR AVERÍA'}
                      </Button>
                    </Box>
                  }
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      )
    }

    if (currentView === 'faultView') {
      return (
        <CSSTransition key="faultView" timeout={300} classNames="fade">
          <div className="row">
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
            <div className="col view-container slide-in">
              <Box title="Marcar avería en taquilla" icon="hand-paper">
                <RemoteActionFaultAddForm lockerType={this.state.lockerType} lockerSN={this.state.matrixSN} machine={this.props.machine} lockerMatricula={this.state.matrixMatricula} closeMatrixDialog={this.closeMatrixDialog} refreshMatrixData={refreshMatrix} />
              </Box>
            </div>
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
          </div>
        </CSSTransition>
      );
    }

    if (currentView === 'repairFaultView') {
      return (
        <CSSTransition key="repairFaultView" timeout={300} classNames="fade">
          <div className="row">
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
            <div className="col view-container slide-in">
              <Box title="Marcar reparación de avería en taquilla" icon="hand-paper">
              <RemoteActionFaultRepairForm lockerType={this.state.lockerType} lockerSN={this.state.matrixSN} machine={this.props.machine} lockerMatricula={this.state.matrixMatricula} closeMatrixDialog={this.closeMatrixDialog} refreshMatrixData={refreshMatrix} />
              </Box>
            </div>
            <Button color="secondary" className="mb-3" onClick={() => this.changeView('main')}>Atrás</Button>
          </div>
        </CSSTransition>
      );
    }
  }

  render() {

    const matrixRender = [];
    let lockerStatus = "";
    let matrixIndex = 0;
    let checkKiosc = 0;

    let columnHeight = 0;
    let matrixWidth = 0;

    {
      this.props.matrixs.map(matrix => {
        {
          (matrix.Serial === this.props.machine.Serial) && matrix.Locker.map((lock, index) => {

            if (matrixIndex < this.props.machine.LastIndex) {

              let lockerSelected = lock.Mode + lock.Wide + lock.Type

              lock.Configured ? lockerStatus = lock.Status : lockerStatus = "Null"

              switch (lock.Type) {

                case "S":
                  columnHeight += 45
                  break;
                case "M":
                  columnHeight += 90
                  break;
                case "L":
                  columnHeight += 135
                  break;
                case "XL":
                  columnHeight += 180
                  break;
                case "2XL":
                  columnHeight += 225
                  break;
                case "3XL":
                  columnHeight += 270
                  break;

              }

              if (columnHeight >= 270) {

                columnHeight = 0;

                if (lock.Wide === 'N') {
                  matrixWidth += 45;
                } else {
                  matrixWidth += 60;
                }
              }

              matrixRender.push(

                <div key={index} id={`locker${lockerSelected}`} onClick={e => this.openMatrixDialog(index + 1, lock.BLEName, lockerSelected, lock.BlockType, e)}>
                  <p>{index + this.props.machine.FirstIndex}</p>
                  <div id={lockerStatus}><p>{lockerStatus}</p></div>
                </div>
              );

              matrixIndex++

              if (lock.Position === "C" && checkKiosc === 0) {

                columnHeight += 135
      
                matrixRender.push(
      
                  <div key={matrixIndex} id="lockerKioskLeft">
                  </div>
      
                );
      
                checkKiosc = 1
              }else if (lock.Position === "E" && checkKiosc === 0) {
      
                columnHeight += 90
      
                matrixRender.push(
      
                  <div key={matrixIndex} id="lockerEcomKioskLeft">
                  </div>
      
                );
      
                checkKiosc = 1
              } else if (lock.Position === "C" && checkKiosc === 1) {
      
                columnHeight += 135
      
                matrixRender.push(
      
                  <div key={matrixIndex} id="lockerKioskRight">
                  </div>
      
                );
      
                checkKiosc = 0
              } else if (lock.Position === "E" && checkKiosc === 1) {
      
                columnHeight += 90
      
                matrixRender.push(
      
                  <div key={matrixIndex} id="lockerEcomKioskRight">
                  </div>
      
                );
      
                checkKiosc = 0
              }
            }
          })
        }
      })
    }

    return (
      <div id="matrixContainer" style={{ width: matrixWidth + 40 }}>
        <div class="row" id="lockerMatrix">
          {matrixRender}
        </div>
        <div>
          <Modal isOpen={this.state.matrixModalOpen} className="locker-modal-admin">
            <ModalHeader toggle={this.terminateConfirmToggle}>
              <div class="text-center">
                <p>Información de taquilla {Number(this.state.matrixMatricula) + this.props.machine.FirstIndex - 1}</p>
                <Button color="secondary"
                  onClick={(event) => this.closeMatrixDialog()}>
                  Cerrar
                </Button>
              </div>
            </ModalHeader>
            <ModalBody>
              {this.infoMatrix()}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary"
                onClick={(event) => this.closeMatrixDialog()}
                className="button-center">
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );

  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(MatrixConstructorAdmin)


