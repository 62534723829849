import React from 'react'
import { history } from '../store'

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        customer: '',
        group: '',
        country: '',
        type: '',
      },
    };
  }
  goTo(id, e) {
    history.push(`/customers/${id}`)
  }

  handleFilterChange(filterKey, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [filterKey]: value,
      },
    }));
  }

  render() {
    const { filters } = this.state;
    const { customers } = this.props;

    // Apply the filters to filter the customers array
    const filteredCustomers = customers.filter(customer => {
      const { customer: customerFilter, group: groupFilter,
        country: countryFilter, mode: modeFilter, type: typeFilter } = filters;

      if (
        customerFilter &&
        (!customer.Fullname ||
          !customer.Fullname.toLowerCase().includes(customerFilter.toLowerCase()))
      ) {
        return false;
      }

      if (
        groupFilter &&
        (!customer.Group ||
          !customer.Group.toLowerCase().includes(groupFilter.toLowerCase()))
      ) {
        return false;
      }

      if (
        countryFilter &&
        (!customer.Country || // Check if the Country property exists
          !customer.Country.toLowerCase().includes(countryFilter.toLowerCase()))
      ) {
        return false;
      }

      if (
        modeFilter &&
        (!customer.BusinessModel ||
          !customer.BusinessModel.toLowerCase().includes(modeFilter.toLowerCase()))
      ) {
        return false;
      }

      if (
        typeFilter &&
        (!customer.Type ||
          !customer.Type.toLowerCase().includes(typeFilter.toLowerCase()))
      ) {
        return false;
      }

      // If all conditions pass, include the customer in the filtered list
      return true;
    });


    if (!this.props.customers) {
      return null
    }

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Nombre completo</th>
              <th scope="col">Lockers</th>
              <th scope="col">Grupo</th>
              <th scope="col">País</th>
              <th scope="col">Teléfono</th>
              <th scope="col">E-Mail</th>
              <th scope="col">Modo</th>
              <th scope="col">Tipo</th>
            </tr>
            <tr>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Cliente"
                  value={filters.customer}
                  onChange={e => this.handleFilterChange('customer', e.target.value)}
                />
              </th>
              <th scope="col"></th>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Grupo"
                  value={filters.group}
                  onChange={e => this.handleFilterChange('group', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  placeholder="País"
                  value={filters.country}
                  onChange={e => this.handleFilterChange('country', e.target.value)}
                />
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">
                <select
                  value={filters.mode}
                  onChange={e => this.handleFilterChange('mode', e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="REVENUE SHARE">REVENUE SHARE</option>
                  <option value="SPACE RENTAL">SPACE RENTAL</option>
                  <option value="SALE">VENTA</option>
                </select>
              </th>
              <th scope="col">
                <select
                  value={filters.type}
                  onChange={e => this.handleFilterChange('type', e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="GENERAL">GENERAL</option>
                  <option value="GROUP">GROUP</option>
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers
              .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
              .map(customer => {
                if (customer.Type === "GENERAL" || customer.Type === "GROUP") {
                  return (
                    <tr key={customer._id}
                      onClick={e => this.goTo(customer._id, e)}>
                      <td>{customer.Fullname}</td>
                      <td>{customer.TotalLockers}</td>
                      <td>{customer.Group}</td>
                      <td>{customer.Country}</td>
                      <td>{customer.Phone}</td>
                      <td>{customer.Email}</td>
                      <td>{customer.BusinessModel}</td>
                      <td>{customer.Type}</td>
                    </tr>
                  )
                }
              })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default CustomerList