import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'
import { renderInputField, renderSelectField } from '../utils/forms'

import { blockMatrix } from '../actions/matrixs'
import { fetchArticles } from '../actions/articles'

class RemoteActionBaqueiraBlockLockerForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      actionCompleted: false,
      errorMessage: '',
      blockType: '',  // New state to track the selected BlockType
    }

    this.submit = this.submit.bind(this)
    this.handleBlockTypeSelect = this.handleBlockTypeSelect.bind(this)
  }

  componentDidMount() {
    this.props.fetchArticles();
  }

  handleBlockTypeSelect(blockType) {
    this.setState({ blockType })  // Set the selected BlockType in the state
  }

  // Modified submit function to include blockType
  submit = async (props) => {
    const { blockType } = this.state;

    if (blockType === '') {
      this.setState({ errorMessage: 'Por favor, selecciona un tipo de bloqueo.' });
      throw new SubmissionError({ _error: 'Por favor, selecciona un tipo de bloqueo.' });
    }

    // Add BlockType to the props for the API call
    props.BlockType = blockType;

    try {
      // Use blockMatrix function to send blockType to the server
      await this.props.blockMatrix(this.props.machine._id, blockType, this.props.lockerSN, this.props.lockerMatricula);

      // Handle other form submission logic here...

      this.setState({ loading: false, actionCompleted: true });

      if (this.props.updateBlockType) {
        this.props.updateBlockType(blockType);
      }
      
      // Trigger matrix data refresh
      if (this.props.refreshMatrixData) {
        this.props.refreshMatrixData();
      }

    } catch (error) {
      this.setState({ loading: false, errorMessage: error.message || 'Error processing action' });
      throw error;
    }
  }

  render() {
    const { error, pristine, submitting, handleSubmit, articles } = this.props;
    const { loading, actionCompleted, errorMessage, blockType } = this.state;

    if (!articles) {
      return (
        <div className="justify-content-center">
          <span>Cargando artículos...</span>
          <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="justify-content-center">
          <span>Ejecutando bloqueo...</span>
          <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
        </div>
      );
    }

    if (actionCompleted) {
      return (
        <div className="text-center">
          <div className="alert alert-success d-block">Taquilla bloqueada correctamente</div>
        </div>
      );
    }

    return (
      <div>
        <form onSubmit={handleSubmit(this.submit)} className="mb-3 mt-3 text-center">
          <label>Selecciona la lista blanca para la que quieres bloquear esta taquilla</label>
          <div className="flex justify-content-around align-items-center mt-5 mb-5">
            {articles.Whitelist.map(whitelist => (
              <button
                key={whitelist._id}
                type="button"
                className={`btn whitelist-btn ${this.state.blockType === whitelist._id ? 'btn-season-selected' : ''}`}
                onClick={() => this.handleBlockTypeSelect(whitelist._id)}
              >
                {whitelist.name}
                <div
                  style={{
                    height: 20,
                    backgroundColor: whitelist.color,
                    border: '1px solid #000', // Optional border for better visibility
                    marginTop: '1vw'
                  }}
                ></div>
              </button>
            ))}
            <button
              type="button"
              className={`btn whitelist-btn ${this.state.blockType === null ? 'btn-season-selected' : 'bg-danger text-white'}`}
              onClick={() => this.handleBlockTypeSelect(null)}
            >
              Desbloquear
              <div
                style={{
                  height: 20,
                  backgroundColor: '#000',
                  border: '1px solid #000', // Optional border for better visibility
                  marginTop: '1vw'
                }}
              ></div>
            </button>
          </div>

          {blockType === '' && <span className="text-danger d-block mt-2">Por favor selecciona un tipo de bloqueo.</span>}

          {/* Submit button */}
          <div className="form-row align-items-center flex justify-content-center mt-5">
            <div className="col-md-12 align-items-center flex justify-content-center">
              <button className="btn bg-vw-light text-white" type="submit" disabled={submitting}>
                Confirmar bloqueo
              </button>
            </div>
          </div>

          {/* Error message */}
          {error && <div className="form-row mt-5 justify-content-center">
            <div className="alert alert-danger">{error}</div>
          </div>}
        </form>
      </div>
    );
  }
}

RemoteActionBaqueiraBlockLockerForm = reduxForm({
  form: 'RemoteActionBaqueiraBlockLockerForm',
})(RemoteActionBaqueiraBlockLockerForm)

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
  articles: state.articles.articles,
})

const mapDispatchToProps = dispatch => ({
  blockMatrix: bindActionCreators(blockMatrix, dispatch),
  dispatch,
  fetchArticles: bindActionCreators(fetchArticles, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoteActionBaqueiraBlockLockerForm)
