import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import { fetchOperator, updateOperator } from '../actions/operators'
import { fetchCalendars } from '../actions/calendars'
import { renderInputField, renderSelectField, renderTextAreaField } from '../utils/forms'
import { fetchRules } from '../actions/rules'

class OperatorEditForm extends React.Component {
       constructor(props) {
              super(props)

              this.state = {
                     loaded: false
              }

              this.load = this.load.bind(this)
              this.submit = this.submit.bind(this)
       }

       componentDidMount() {
              const { fetchCalendars, fetchRules, fetchOperator, match } = this.props

              fetchOperator(match.params.id)
              fetchCalendars();
              fetchRules();

              this.load(this.props)
       }

       componentWillReceiveProps(nextProps) {
              this.load(nextProps)
       }

       load(props) {
              const { operator, calendars, rules, initialize } = props;

              if (!this.state.loaded && operator && calendars && rules) {
                     this.setState({
                            loaded: true
                     });
                     initialize(operator);  // Initialize form with operator data
              }
       }

       submit(props) {
              const { updateOperator, operator } = this.props

              return updateOperator(operator._id, props)
                     .then(response => {
                            if (response.error) {
                                   throw new SubmissionError({
                                          _error: response.error.message,
                                          ...response.error.fields
                                   })
                            }

                            return response
                     })
       }

       renderRules(props, rules) {
              const { fields } = props

              return (
                     <div>
                            <div className="table-responsive">
                                   <table className="table table-sm table-stripped">
                                          <tbody>
                                                 {fields.map((registerrule, index) => (
                                                        <tr key={index}>
                                                               <td>
                                                                      {index + 1}
                                                               </td>
                                                               <td>
                                                                      <Field name={`${registerrule}.BusinessRule`}
                                                                             component={renderSelectField}
                                                                      >
                                                                             {rules &&
                                                                                    rules
                                                                                           .sort((a, b) => a.Name.localeCompare(b.Name))
                                                                                           .map((rule) => (
                                                                                                  <option value={rule._id} key={rule._id}>
                                                                                                         {rule.Name}
                                                                                                  </option>
                                                                                           ))}
                                                                      </Field>
                                                               </td>
                                                               <td>
                                                                      <button className="btn btn-danger mr-1" type="button"
                                                                             onClick={() => fields.remove(index)}>
                                                                             <i className="fas fa-trash mr-1"></i>
                                                                      </button>
                                                               </td>
                                                        </tr>
                                                 ))}
                                          </tbody>
                                   </table>
                            </div>
                            <div className="text-right">
                                   <button className="btn bg-vw-light text-white" type="button"
                                          onClick={() => fields.push({})}>
                                          <i className="fas fa-plus mr-1"></i>
                                          Añadir
                                   </button>
                            </div>
                     </div>
              )
       }

       render() {
              const { operator, calendars, error, pristine, submitting, handleSubmit, rules } = this.props
              const { loaded } = this.state

              if (!loaded || !operator || !calendars || !rules) {
                     return (
                            <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
                     )
              }

              return (
                     <form onSubmit={handleSubmit(this.submit)}>
                            <h1>
                                   <span className="text-vw-dark">EDITAR </span>
                                   <span className="text-vw-light">AGREGADOR</span>
                            </h1>

                            <div className="row mb-5">
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Agregador" icon="cubes">
                                                 <Field name="Name"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Nombre del agregador" />
                                                 <Field name="InvoiceType"
                                                        component={renderSelectField}
                                                        label="Modelo de faturación">
                                                        <option value="">Selecciona un modo...</option>
                                                        <option value="Invoice">Factura</option>
                                                        <option value="Note">Albarán</option>
                                                        <option value="Free">No Contable</option>
                                                 </Field>
                                                 <Field name="Comission"
                                                        type="number"
                                                        component={renderInputField}
                                                        label="Comisión" />
                                                 <Field name="Nif"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="NIF/CIF" />
                                                 <Field name="Address"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Dirección" />
                                                 <Field name="Country"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="País" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Calendario de precios" icon="calendar">
                                                 <Field name="Calendar"
                                                        component={renderSelectField}
                                                        label="Calendario de precios"
                                                 >
                                                        {calendars &&
                                                               calendars
                                                                      .sort((a, b) => a.Name.localeCompare(b.Name))
                                                                      .map((calendar) => (
                                                                             <option value={calendar._id} key={calendar._id}>
                                                                                    {calendar.Name}
                                                                             </option>
                                                                      ))}
                                                 </Field>
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Reglas de negocio" icon="pencil-ruler">
                                                 <FieldArray name="BusinessRules" component={(fieldArrayProps) => this.renderRules(fieldArrayProps, rules)} />
                                          </Box>
                                   </div>
                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn bg-vw-light text-white mr-1 save" type="submit"
                                   disabled={pristine || submitting}>
                                   Guardar
                            </button>
                     </form>
              )
       }
}

OperatorEditForm = reduxForm({
       form: 'OperatorEditForm',
       keepDirtyOnReinitialize: true,
       enableReinitialize: true,
       updateUnregisteredFields: true,
       onSubmitSuccess: (result, dispatch, props) => {
              props.push(`/operators/${result.response._id}`)
       }
})(OperatorEditForm)

const mapStateToProps = state => {
       return {
              operator: state.operators.operator,
              calendars: state.calendars.calendars,
              rules: state.rules.rules,
              initialValues: state.operators.operator,
       }
}

const mapDispatchToProps = dispatch => ({
       updateOperator: bindActionCreators(updateOperator, dispatch),
       fetchCalendars: bindActionCreators(fetchCalendars, dispatch),
       fetchOperator: bindActionCreators(fetchOperator, dispatch),
       fetchRules: bindActionCreators(fetchRules, dispatch),
       push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatorEditForm)
