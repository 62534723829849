import {
  FAULTS_REQUEST, FAULTS_SUCCESS, FAULTS_FAILURE,
  FAULT_ADD_REQUEST, FAULT_ADD_SUCCESS, FAULT_ADD_FAILURE,
  FAULT_REPAIR_REQUEST, FAULT_REPAIR_SUCCESS, FAULT_REPAIR_FAILURE
} from '../actions/faults'

const INITIAL_STATE = {
  faults: null, fault: null, error: null, loading: false
}


const faultsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FAULTS_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case FAULTS_SUCCESS:
      return {
        ...state,
        faults: action.response, error: null, loading: false
      }
    case FAULTS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case FAULT_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case FAULT_ADD_SUCCESS:
      return {
        ...state,
        fault: action.response, error: null, loading: false
      }
    case FAULT_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case FAULT_REPAIR_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case FAULT_REPAIR_SUCCESS:
      return {
        ...state,
        fault: action.response, error: null, loading: false
      }
    case FAULT_REPAIR_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default faultsReducer

