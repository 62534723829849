import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError, change } from 'redux-form'
import { fetchCustomers } from '../actions/customers'
import ErrorMessage from '../components/ErrorMessage'

import Box from '../components/Box'
import { fetchQR, updateQR } from '../actions/qrs'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'
import QRZonesList from '../components/QRZonesList'

class QREditForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      customerSelected: "",
      showDynamicField: false
    };

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchQR, fetchCustomers, match } = this.props

    fetchQR(match.params.id)
    fetchCustomers()

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { qr, customers } = props

    if (!this.state.loaded &&
        qr && customers) {

      this.setState({
        loaded: true
      })
    }
  }

  submit(props) {
    const { updateQR, qr } = this.props

    return updateQR(qr._id, props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  handleShowDynamicField() {
    this.setState({
      showDynamicField: true
    });
  }

  render() {
    const { customers, error, pristine, submitting, handleSubmit } = this.props
    const { showDynamicField, loaded } = this.state;

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    if (!customers) {
      return (
        <ErrorMessage
          message="No hay clientes o máquinas disponibles para nuevos contratos" />
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">EDITAR </span>
          <span className="text-vw-light">QR</span>
        </h1>
        <hr className="bg-vw-light" />
        <div className="row">
          <div className="col col-12 col-xs-12 col-md-4">
          </div>
          <div className="col col-12 col-xs-12 col-md-4">
            <Box title="Configuración" icon="cubes">
              <Field name="Type"
                component={renderSelectField}
                label="Tipo">
                <option value="">Selecciona un tipo...</option>
                <option value="pay">Pago</option>
                <option value="free">Gratis</option>
                <option value="test">Test</option>
              </Field>
              <Field name="Mode"
                component={renderSelectField}
                label="Modo">
                <option value="">Selecciona un modo...</option>
                <option value="0">24H CON PIN</option>
                <option value="1">OTO SIN PIN</option>
                <option value="2">BAQUEIRA</option>
              </Field>
            </Box>
          </div>
          <div className="col col-12 col-xs-12 col-md-4">
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn bg-vw-light text-white mr-1 save" type="submit"
          disabled={pristine || submitting}>
          Guardar
        </button>
      </form>
    )
  }
}

QREditForm = reduxForm({
  form: 'QREditForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/qrs/${result.response._id}`)
  }
})(QREditForm)

const mapStateToProps = state => ({
  qr: state.qrs.qr,
  customers: state.customers.customers
})

const mapDispatchToProps = dispatch => ({
  fetchQR: bindActionCreators(fetchQR, dispatch),
  updateQR: bindActionCreators(updateQR, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(QREditForm)


