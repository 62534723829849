import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'
import { renderInputField, renderSelectField } from '../utils/forms'

import { repairFault } from '../actions/faults'

class RemoteActionFaultRepairForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      actionCompleted: false,
      errorMessage: '',
      repairType: '',
      materials: {
        LOCK_MAIN: false,
        LOCK_PINPAD: false,
        LOCK_ANCHORAGE: false,
        LOCK_PLATEN: false,
        LOCK_NUMBER: false,
        BATTERIES: false,
        HINGE: false,
        USB_WIRE: false,
        NO_MATERIAL: false,
        DOOR: false,
      }
    }

    this.submit = this.submit.bind(this)
    this.handleMaterialSelect = this.handleMaterialSelect.bind(this)
  }

  componentDidMount() {
    // Materials: LOCK_MAIN, LOCK_PINPAD, LOCK_ANCHORAGE, LOCK_PLATEN, LOCK_NUMBER, BATTERIES, HINGE, USB_WIRE, NO_MATERIAL, DOOR
  }

  handleMaterialSelect(material) {
    this.setState(prevState => {
      let updatedMaterials = { ...prevState.materials }

      if (material === 'NO_MATERIAL') {
        // If NO_MATERIAL is selected, deselect all other materials
        updatedMaterials = {
          LOCK_MAIN: false,
          LOCK_PINPAD: false,
          LOCK_ANCHORAGE: false,
          LOCK_PLATEN: false,
          LOCK_NUMBER: false,
          BATTERIES: false,
          HINGE: false,
          USB_WIRE: false,
          NO_MATERIAL: true,
          DOOR: false,
        }
      } else {
        // If any other material is selected, ensure NO_MATERIAL is deselected
        updatedMaterials.NO_MATERIAL = false
        updatedMaterials[material] = !prevState.materials[material]
      }

      return { materials: updatedMaterials }
    })
  }

  submit = async (props) => {
    const { materials } = this.state
    const { reset } = this.props;
    
    if (!Object.values(materials).some(material => material)) {
      this.setState({ errorMessage: 'Por favor, selecciona al menos un material.' });
      throw new SubmissionError({ _error: 'Por favor, selecciona al menos un material.' });
    }

    this.setState({ loading: true, actionCompleted: false })

    // Prepare replacementsUsed object
    let replacementsUsed = {
      LOCK_MAIN: materials.LOCK_MAIN,
      LOCK_PINPAD: materials.LOCK_PINPAD,
      LOCK_ANCHORAGE: materials.LOCK_ANCHORAGE,
      LOCK_PLATEN: materials.LOCK_PLATEN,
      LOCK_NUMBER: materials.LOCK_NUMBER,
      BATTERIES: materials.BATTERIES,
      HINGE: materials.HINGE,
      USB_WIRE: materials.USB_WIRE,
      NO_MATERIAL: materials.NO_MATERIAL,
      DOOR: materials.DOOR,
    }

    props.Locker = this.props.lockerSN
    props.Replacements = replacementsUsed

    try {
      const response = await this.props.repairFault(props)

      if (response.error) {
        throw new SubmissionError({
          _error: response.error.message,
          ...response.error.fields
        })
      }

      this.setState({ loading: false, actionCompleted: true })

      // Trigger matrix data refresh
      if (this.props.refreshMatrixData) {
        this.props.refreshMatrixData();
      }
      reset();
      return response
    } catch (error) {
      this.setState({ loading: false, errorMessage: error.message || 'Error processing action' })
      throw error
    }
  }

  getMaterialName(MaterialCode) {
    switch (MaterialCode) {
      case 'LOCK_MAIN':
        return "Centralita"
      case 'LOCK_PINPAD':
        return "Teclado"
      case 'LOCK_ANCHORAGE':
        return "Pincho"
      case 'LOCK_PLATEN':
        return "Pletina"
      case 'LOCK_NUMBER':
        return "Número"
      case 'USB_WIRE':
        return "Cable USB"
      case 'BATTERIES':
        return "Pilas (pack 4)"
      case 'HINGE':
        return "Bisagra"
      case 'DOOR_BNS':
        return "Puerta Básica"
      case 'DOOR_BWS':
        return "Puerta Básica XL"
      case 'DOOR_BNM':
        return "Puerta Grande"
      case 'DOOR_BWM':
        return "Puerta Grande XL"
      case 'DOOR_BNL':
        return "Puerta Jumbo"
      case 'DOOR_BWL':
        return "Puerta Jumbo XL"
      default:
        return "Ajuste sin material"
    }
  }

  render() {
    const { error, pristine, submitting, handleSubmit } = this.props
    const { loading, actionCompleted, materials } = this.state

    if (loading) {
      return (
        <div className="justify-content-center">
          <span>Ejecutando reparación...</span>
          <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
        </div>
      )
    }

    if (actionCompleted) {
      return (
        <div className="text-center">
          <div className="alert alert-success d-block">Reparación registrada correctamente</div>
        </div>
      )
    }

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-md-12 mt-2">
            <form onSubmit={handleSubmit(this.submit)} className="mb-3 mt-3">
              <div className="row justify-content-around">
                {['LOCK_MAIN', 'LOCK_PINPAD', 'LOCK_ANCHORAGE', 'LOCK_PLATEN', 'LOCK_NUMBER', 'BATTERIES', 'HINGE', 'USB_WIRE', 'DOOR'].map(material => (
                  <div className="col-xs-12 col-md-2 row align-items-center justify-content-center mt-2 mb-2" key={material}>
                    <button
                      type="button"
                      className={`btn btn-fault ${materials[material] ? 'btn-repair-selected' : ''}`}
                      onClick={() => this.handleMaterialSelect(material)}
                    >
                      <img src={`/images/repairs/${material}.png`} alt={material} />
                      <p>{this.getMaterialName(material)}</p>
                    </button>
                  </div>
                ))}

                <div className="col-xs-12 col-md-2 row align-items-center justify-content-center mt-2 mb-2">
                  <button
                    type="button"
                    className={`btn btn-fault ${materials.NO_MATERIAL ? 'btn-repair-selected' : ''}`}
                    onClick={() => this.handleMaterialSelect('NO_MATERIAL')}
                  >
                    <img src="/images/repairs/cross.png" alt="NO_MATERIAL" />
                    <p>Ajuste sin material</p>
                  </button>
                </div>
              </div>
              <div className="form-row align-items-center flex justify-content-center mt-5">
                <div className="col-md-12 align-items-center flex justify-content-center">
                  <button className="btn bg-vw-light text-white" type="submit" disabled={submitting || Object.values(materials).every(v => v === false)}>
                    <i className="fas fa-bolt mr-1"></i>Confirmar reparación
                  </button>
                </div>
              </div>
              <div className="form-row mt-5 justify-content-center">
                {error && <div className="alert alert-danger">{error}</div>}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

RemoteActionFaultRepairForm = reduxForm({
  form: 'RemoteActionFaultRepairForm',
})(RemoteActionFaultRepairForm)

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
  formValues: state.form.RemoteActionFaultRepairForm && state.form.RemoteActionFaultRepairForm.values,
})

const mapDispatchToProps = dispatch => ({
  repairFault: bindActionCreators(repairFault, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoteActionFaultRepairForm)
